import React from 'react'
import { Helmet } from 'react-helmet-async'
import withJWTSession from 'src/enhancers/jwt_session'
import Dashboard from 'src/components/drugstore/dashboard'

class DashboardPage extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Dashboard</title>
          <meta name="description" content="dashboard" />
          <link rel="icon" href="/favicon.ico" />
        </Helmet>
        <Dashboard />
      </>
    )
  }
}
export default withJWTSession({ invalid: '/drugstore/login' })(DashboardPage)
