import React from 'react'

const year = new Date().getFullYear()

class Footer extends React.Component {
  static defaultProps = {
    containerSize: 'xxl',
  }

  render() {
    let { containerSize } = this.props

    return (
      <footer className="layout py-1" style={{ background: '#005d7f' }}>
        <div className={`container-${containerSize} text-end`} style={{ color: 'white' }}>
          Copyright &copy;{year} Go s.r.l.s - The right direction - All rights reserved
        </div>
      </footer>
    )
  }
}
export default Footer
