import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'src/lib/router/with_router'
import { Button, Form, Row, Col, Alert } from 'react-bootstrap'
import isEmail from 'validator/lib/isEmail'
import ResponsiveScroller from 'src/components/system_wide/responsive_scroller'
import ManagerEmployees from 'src/components/drugstore/tenant_management/manager/manage_employees'
import { getManager, updateManager, resetManager } from 'src/store/manager'

const mstp = state => {
  let { name: tenant_name } = state.storeManager.current_tenant
  let { id, business_name, description, tax_code, pec, email, address } = state.manager
  return {
    tenant_name,
    id,
    business_name,
    description,
    tax_code,
    pec,
    email,
    address,
  }
}

class ManagePointOfSaleData extends React.Component {
  state = {
    business_name: '',
    description: '',
    tax_code: '',
    email: '',
    pec: '',
    address: '',
  }

  async componentDidMount() {
    if (this.props.tenant_name) {
      await this.props.dispatch(getManager(this.props.tenant_name))
      let { business_name, description, tax_code, email, pec, address } = this.props
      this.setState({ business_name, description, tax_code, email, pec, address })
    }
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.tenant_name !== this.props.tenant_name) {
      await this.props.dispatch(getManager(this.props.tenant_name))
      let { business_name, description, tax_code, email, pec, address } = this.props
      this.setState({ business_name, description, tax_code, email, pec, address })
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetManager())
  }

  onChange = e => {
    let { name, value } = e.target
    if (!['business_name', 'description', 'address'].includes(name)) {
      value = value.trim()
    }
    this.setState({ [name]: value })
  }

  isChanged = () => {
    let { business_name, description, tax_code, email, pec, address } = this.state
    return (
      this.props.business_name !== business_name ||
      this.props.description !== description ||
      this.props.tax_code !== tax_code ||
      this.props.pec !== pec ||
      this.props.email !== email ||
      this.props.address !== address
    )
  }

  applyChanges = () => {
    this.props.dispatch(
      updateManager({
        tenant: this.props.tenant_name,
        ...this.state,
      })
    )
  }

  isFormValid = () => {
    let { pec, email } = this.state
    return (!email || isEmail(email)) && (!pec || isEmail(pec))
  }

  isEmailInvalid = prop => {
    let current_value = ''
    current_value = this.state?.[prop] ?? ''
    return current_value !== '' && !isEmail(current_value)
  }

  render() {
    let { tenant_name } = this.props
    let { business_name, description, tax_code, pec, email, address } = this.state
    let is_pec_invalid = this.isEmailInvalid('pec')
    let is_email_invalid = this.isEmailInvalid('email')

    if (!tenant_name) {
      return (
        <div className="container-fluid flex-fill d-flex flex-column px-0 ps-lg-3">
          <Alert variant="info">Seleziona punto vendita</Alert>
        </div>
      )
    }
    return (
      <div className="container-fluid flex-fill d-flex flex-column px-0 ps-lg-3">
        <div className="d-lg-none border-bottom mb-3">
          <h4 className="text-primary">Impostazioni generali</h4>
        </div>
        <ResponsiveScroller>
          <Form className="mb-2 p-2 pt-0" noValidate>
            <Row className="g-2">
              <Col lg="4">
                <Form.Group>
                  <Form.Label as="h6">Ragione sociale</Form.Label>
                  <Form.Control name="business_name" size="sm" value={business_name} onChange={this.onChange} />
                </Form.Group>
              </Col>
              <Col lg="4">
                <Form.Group>
                  <Form.Label as="h6">P.IVA</Form.Label>
                  <Form.Control
                    name="tax_code"
                    size="sm"
                    placeholder="Partita IVA"
                    value={tax_code}
                    onChange={this.onChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3 g-2">
              <Col lg="8">
                <Form.Group>
                  <Form.Label as="h6">Descrizione</Form.Label>
                  <Form.Control
                    name="description"
                    placeholder="Descrizione"
                    size="sm"
                    value={description}
                    onChange={this.onChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mt-3 g-2">
              <Col lg="6">
                <Form.Group>
                  <Form.Label as="h6">Pec</Form.Label>
                  <Form.Control
                    name="pec"
                    size="sm"
                    placeholder="PEC"
                    value={pec}
                    onChange={this.onChange}
                    isInvalid={is_pec_invalid}
                  />
                  <Form.Control.Feedback type="invalid">Indirizzo e-mail non valida</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg="6">
                <Form.Group>
                  <Form.Label as="h6">Email</Form.Label>
                  <Form.Control
                    name="email"
                    size="sm"
                    placeholder="Email"
                    value={email}
                    onChange={this.onChange}
                    isInvalid={is_email_invalid}
                  />
                  <Form.Control.Feedback type="invalid">Indirizzo e-mail non valida</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mt-3 g-2">
              <Col lg="8">
                <Form.Group>
                  <Form.Label as="h6">Indirizzo</Form.Label>
                  <Form.Control
                    name="address"
                    size="sm"
                    placeholder="Indirizzo"
                    value={address}
                    onChange={this.onChange}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <div>
            <ManagerEmployees manager_id={this.props.id} />
          </div>
        </ResponsiveScroller>
        <div className="text-end pt-2 border-top">
          <Button variant="primary" disabled={!this.isChanged() || !this.isFormValid()} onClick={this.applyChanges}>
            Salva
          </Button>
        </div>
      </div>
    )
  }
}
export default connect(mstp)(withRouter(ManagePointOfSaleData))
