import React from 'react'
import { Dropdown, DropdownButton, Form } from 'react-bootstrap'

class CheckboxFilter extends React.PureComponent {
  static defaultProps = {
    label: '',
    items: [],
    filteredItems: [],
    onChangeFilter: () => null,
    disabled: false,
    className: '',
  }

  handleChange = id => e => {
    let filtered_items = [...this.props.filteredItems]
    if (id === 0) {
      filtered_items = filtered_items.length !== this.props.items.length ? this.props.items.map(r => r.id) : []
    } else {
      if (filtered_items.includes(id)) {
        filtered_items = filtered_items.filter(_id => _id !== id)
      } else {
        filtered_items.push(id)
      }
    }
    this.props.onChangeFilter(filtered_items)
    e.stopPropagation()
  }

  nullHandler = () => null

  render() {
    // eslint-disable-next-line
    let { label, items, filteredItems, disabled, className, onChangeFilter, ...rest } = this.props
    return (
      <DropdownButton
        className={className}
        title={
          <>
            <i className="fas fa-filter fa-fw text-start" />
            {label}
          </>
        }
        disabled={disabled}
        {...rest}>
        <div className="items" style={{ maxHeight: '24rem', overflow: 'auto' }}>
          {items.map(item => {
            return (
              <Dropdown.Item
                key={`role-${item.id}`}
                className="d-flex gap-3 align-items-center"
                onClick={this.handleChange(item.id)}>
                <Form.Check
                  role="button"
                  id={item.id}
                  value={item.id}
                  name={item.name}
                  checked={filteredItems.includes(item.id)}
                  onChange={this.nullHandler}
                  className="pe-none"
                />
                <label htmlFor={item.id} role="button">
                  {item.name}
                </label>
              </Dropdown.Item>
            )
          })}
        </div>
        <Dropdown.Divider />
        <Dropdown.Item key={`all`} className="d-flex gap-3 align-items-center" onClick={this.handleChange(0)}>
          <Form.Check
            role="button"
            id={0}
            value={0}
            name={'all'}
            checked={this.props.items.length === filteredItems.length}
            onChange={this.nullHandler}
            className="pe-none"
          />
          <label htmlFor={'all'} role="button">
            {this.props.items.length === filteredItems.length && 'Deseleziona tutti'}
            {this.props.items.length > filteredItems.length && 'Seleziona tutti'}
          </label>
        </Dropdown.Item>
      </DropdownButton>
    )
  }
}

export default CheckboxFilter
