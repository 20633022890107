export const getConstants = () => ({
  bo_caps: {
    bo_create_tenant: 'Crea punto vendita',
    bo_manage_tenant: 'Gestisci punto vendita',
    bo_manage_users: 'Gestisci utenti',
    bo_show_point_of_sale_data: 'Visualizza anagrafica',
    bo_show_manager_point_of_sale_data: 'Visualizza gestore',
    bo_show_setup_costs: 'Visualizza spese allestimento',
    bo_show_maintenances: 'Vistualizza lista manutenzioni',
    bo_show_deadlines: 'Visualizza le scadenze',
    bo_show_sales: 'Visualizza i fatturati',
    bo_delete_tenant: 'Elimina punti vendita',
  },
  t_caps: {
    manage_user_all: 'Gestisci utenti',
    view_user: 'Visualizza utenti',
    reset_user_password: 'Reset password utenti',
    delete_user: 'Elimina utenti',
  },
})

export const getConstantsArray = () => {
  let data = getConstants()
  return Object.keys(data).reduce((acc, k) => ({ ...acc, [k]: Object.keys(data[k]) }), {})
}

export const bo_caps = getConstantsArray().bo_caps.reduce((bo_caps, c) => ({ ...bo_caps, [c]: false }), {})
export const t_caps = getConstantsArray().t_caps.reduce((t_caps, c) => ({ ...t_caps, [c]: false }), {})
