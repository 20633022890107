import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  id: null,
  username: '',
  email: '',
  first_name: '',
  last_name: '',
  tenant: '',
  is_management_account: false,
  caps: {},
  business_role_id: null,
}

export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,

  reducers: {
    updateUserinfo: (state, action) => action.payload,
    resetUserinfo: () => initialState,
  },
})

export const { updateUserinfo, resetUserinfo } = userInfoSlice.actions
export default userInfoSlice.reducer
