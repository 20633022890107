import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { error, success } from 'src/components/system_wide/notification'
import axios from 'src/lib/axios'

const initialState = {
  total: 0,
  documents: [],
  show_form: false,
}

export const getDocuments = createAsyncThunk(
  'documents/getDocuments',
  async ({ tenant, offset, limit, sortData, filter, type, type_id }, thunkApi) => {
    try {
      let { total: currentTotal } = thunkApi.getState().documents
      if (offset > currentTotal) return

      let { sort_column, sort_direction } = sortData ?? { sort_column: 'id', sort_direction: 'DESC' }
      let response = await axios({
        url: `/api/documents/${tenant}`,
        params: { offset, limit, sort_column, sort_direction, filter, type, type_id },
      })
      let { total, documents } = response.data
      return { total, documents, reset: offset === 0 }
    } catch (err) {
      console.log(err)
      error('Impossibile recuperare i documenti')
      return thunkApi.rejectWithValue()
    }
  }
)

export const createDocument = createAsyncThunk('documents/createDocument', async ({ tenant, document }, thunkApi) => {
  try {
    let { description, recipients, expiry_date, recurring, recurring_type } = document
    await axios({
      url: `/api/document/${tenant}`,
      method: 'post',
      data: { description, recipients, expiry_date, recurring, recurring_type },
    })
    success('Documento creato con successo')
    thunkApi.dispatch(resetCurrentDocument())
    thunkApi.dispatch(toggleShowForm())
  } catch (err) {
    error("Errore nell'upload del documento")
    return thunkApi.rejectWithValue()
  }
})

export const deleteDocument = createAsyncThunk('documents/deleteDocument', async ({ tenant, id }, thunkApi) => {
  try {
    await axios({ url: `/api/document/${tenant}/${id}`, method: 'delete' })
    success('Documento eliminato con successo')
    thunkApi.dispatch(resetCurrentDocument())
  } catch (err) {
    error('Impossibile eliminare documento')
    return thunkApi.rejectWithValue()
  }
})

export const documentsSlice = createSlice({
  name: 'documents',
  initialState,

  reducers: {
    toggleShowForm: state => {
      state.show_form = !state.show_form
    },
    resetDocuments: () => initialState,
  },

  extraReducers: {
    [getDocuments.fulfilled]: (state, action) => {
      if (!action?.payload) return
      let { total, documents } = action.payload
      state.total = total
      state.documents = action.payload.reset ? [...documents] : [...state.documents, ...documents]
    },
    [getDocuments.rejected]: state => {
      state.total = initialState.total
      state.documents = initialState.documents
    },
  },
})

export const { setCurrentDocument, resetCurrentDocument, toggleShowForm, resetDocuments } = documentsSlice.actions
export default documentsSlice.reducer
