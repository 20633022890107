import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { success, error } from 'src/components/system_wide/notification'
import axios from 'src/lib/axios'

const initialState = {
  total: 0,
  employees: [],
  show_form: false,
  current_employee: {
    id: null,
    description: '',
    name: '',
    surname: '',
    email: '',
    phone_1: '',
    phone_2: '',
    phone_3: '',
  },
}

export const getEmpleyees = createAsyncThunk(
  'employees/getEmpleyees',
  async ({ tenant, offset, limit, sortData, filter }, thunkApi) => {
    try {
      let { total: currentTotal } = thunkApi.getState().setupCosts
      if (offset > currentTotal) return

      let { sort_column, sort_direction } = sortData ?? { sort_column: 'id', sort_direction: 'DESC' }
      let response = await axios({
        url: `/api/employees/${tenant}`,
        params: { offset, limit, sort_column, sort_direction, filter },
      })
      let { total, employees } = response.data
      return { total, employees, reset: offset === 0 }
    } catch (err) {
      error('Impossible recuperare dipendenti')
      return thunkApi.rejectWithValue()
    }
  }
)

export const createEmployee = createAsyncThunk(
  'employees/createEmployee',
  async ({ tenant, employee, manager_id }, thunkApi) => {
    try {
      let { description, name, surname, email, phone_1, phone_2, phone_3 } = employee
      await axios({
        url: `/api/employee/${tenant}`,
        method: 'post',
        data: { manager_id, description, name, surname, email, phone_1, phone_2, phone_3 },
      })
      success('Dipendente creata con successo')
      thunkApi.dispatch(resetCurrentEmployee())
      thunkApi.dispatch(toggleShowForm())
    } catch (err) {
      //error(data.message === 'setup_costname_or_email_already_exists_error' ? msg1 : msg2)
      error('Impossibile creare dipendente')
      return thunkApi.rejectWithValue()
    }
  }
)

export const updateEmployee = createAsyncThunk(
  'employees/updateEmployee',
  async ({ tenant, employee, manager_id }, thunkApi) => {
    try {
      let { id, description, name, surname, email, phone_1, phone_2, phone_3 } = employee
      await axios({
        url: `/api/employee/${tenant}/${id}`,
        method: 'patch',
        data: { manager_id, description, name, surname, email, phone_1, phone_2, phone_3 },
      })
      success('Dipendente aggiornata con successo')
      thunkApi.dispatch(resetCurrentEmployee())
      thunkApi.dispatch(toggleShowForm())
    } catch (err) {
      //error(data.message === 'setup_costname_or_email_already_exists_error' ? msg1 : msg2)
      error('Impossibile aggiornare dipendente')
      return thunkApi.rejectWithValue()
    }
  }
)

export const deleteEmployee = createAsyncThunk('employees/deleteEmployee', async ({ tenant, id }, thunkApi) => {
  try {
    await axios({ url: `/api/employee/${tenant}/${id}`, method: 'delete' })
    success('Dipendente eliminata con successo')
    thunkApi.dispatch(resetCurrentEmployee())
  } catch (err) {
    error('Impossibile eliminare dipendente')
    return thunkApi.rejectWithValue()
  }
})

export const employeesSlice = createSlice({
  name: 'employees',
  initialState,

  reducers: {
    setCurrentEmployee: (state, action) => {
      let { id, description, name, surname, email, phone_1, phone_2, phone_3 } = action.payload
      state.current_employee.id = id
      state.current_employee.description = description
      state.current_employee.name = name
      state.current_employee.surname = surname
      state.current_employee.email = email
      state.current_employee.phone_1 = phone_1
      state.current_employee.phone_2 = phone_2
      state.current_employee.phone_3 = phone_3
    },
    toggleShowForm: state => {
      state.show_form = !state.show_form
    },
    resetCurrentEmployee: state => {
      state.current_employee = initialState.current_employee
    },
    resetEmployees: state => {
      state.total = initialState.total
      state.employees = initialState.employees
    },
  },
  extraReducers: {
    [getEmpleyees.fulfilled]: (state, action) => {
      if (!action?.payload) return
      let { total, employees } = action.payload
      state.total = total
      state.employees = action.payload.reset ? [...employees] : [...state.employees, ...employees]
    },
    [getEmpleyees.rejected]: state => {
      state.total = initialState.total
      state.employees = initialState.employees
    },
  },
})

export const { resetManager, setCurrentEmployee, toggleShowForm, resetCurrentEmployee, resetEmployees } =
  employeesSlice.actions
export default employeesSlice.reducer
