import React from 'react'
import { connect } from 'react-redux'

import withJWTSession from 'src/enhancers/jwt_session'
import SetupCost from 'src/components/drugstore/tenant_management/setup_costs'
import withCapabilities from 'src/enhancers/with_capabilities'

const mstp = state => ({ userInfo: state.userInfo })

class SetupCostsPage extends React.Component {
  render() {
    return <SetupCost />
  }
}
export default withJWTSession({ invalid: '/drugstore/login' })(
  connect(mstp)(
    withCapabilities({
      some: ['bo_show_sales'],
    })(SetupCostsPage)
  )
)
