import React from 'react'

import styles from 'src/components/system_wide/responsive_scroller.module.css'

class ResponsiveScroller extends React.PureComponent {
  static defaultProps = {
    isDimmed: false,
    className: '',
    style: {},
    disableMediaQuery: false,
  }

  state = { scrollBarWidth: 0, scrollTop: 0, scrollLeft: 0, isDimmed: false }

  divRef = React.createRef()

  componentDidUpdate(prevProps) {
    let { isDimmed } = this.props
    if (isDimmed && !prevProps.isDimmed) {
      const scrollBarWidth = this.divRef.current.offsetWidth - this.divRef.current.clientWidth
      const { scrollLeft, scrollTop } = this.divRef.current
      this.setState({ scrollBarWidth, isDimmed: true, scrollTop, scrollLeft })
    }
    if (!isDimmed && prevProps.isDimmed) {
      this.setState({ isDimmed: false, scrollTop: 0, scrollLeft: 0 })
    }
  }

  render() {
    let { style, className, children, disableMediaQuery } = this.props
    let { isDimmed } = this.state

    return (
      <div
        className={`${styles.responsive_container} ${
          disableMediaQuery ? '' : styles.with_media_query
        } flex-fill ${className} ${isDimmed ? styles.dimmed : ''}`}
        style={style}
        ref={this.divRef}>
        {isDimmed === true && (
          <div
            className={`${styles.dimmer} d-flex justify-content-center align-items-center`}
            style={{ top: this.state.scrollTop, left: this.state.scrollLeft }}>
            <div
              className={`${styles.spinner} ${
                disableMediaQuery ? '' : styles.with_media_query
              } spinner-border text-white`}
              role="status"></div>
          </div>
        )}
        <div
          style={{ paddingRight: isDimmed ? this.state.scrollBarWidth + 'px' : 0 }}
          className={`${styles.responsive_content} ${disableMediaQuery ? '' : styles.with_media_query}`}>
          {children}
        </div>
      </div>
    )
  }
}

export default React.memo(ResponsiveScroller)
