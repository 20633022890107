import React from 'react'
import { Col, Button, Form } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { format, parseISO, isValid } from 'date-fns'
import hash from 'object-hash'
import isEmail from 'validator/lib/isEmail'
import 'react-datepicker/dist/react-datepicker.css'

class DeadlineForm extends React.Component {
  state = {
    id: null,
    description: '',
    recipients: '',
    expiry_date: null,
    recurring: false,
    recurring_type: 'monthly',

    deadline_hash: '',
    error_emails: false,
  }

  initialHash = ''

  constructor(props) {
    super(props)

    if (props.deadline.id) {
      let { id, description, recipients, expiry_date, recurring, recurring_type } = props.deadline
      this.state.id = id
      this.state.description = description || ''
      this.state.recipients = recipients || ''
      this.state.expiry_date = expiry_date || null
      this.state.recurring = recurring
      this.state.recurring_type = recurring_type
    }

    // eslint-disable-next-line no-unused-vars
    let { deadline_hash, ...rest } = this.state
    this.initialHash = hash(rest)
    this.state.deadline_hash = this.initialHash
  }

  updateHash = () => {
    // eslint-disable-next-line no-unused-vars
    let { deadline_hash, ...rest } = this.state
    this.setState({ deadline_hash: hash(rest) })
  }

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value }, () => this.updateHash())
  }

  onChangeRecipientsEmails = e => {
    let { value } = e.target
    let recipients = { ...this.state.recipients }
    let error_emails = value !== '' ? value.split(',').some(e => !isEmail(e.trim())) : true
    recipients = value.replace(' ', '')
    this.setState({ recipients, error_emails }, () => this.updateHash())
  }

  onChangeTypesRecurring = e => {
    let { value } = e.target
    this.setState({ recurring_type: value }, () => this.updateHash())
  }

  handleChangeEnabled = ({ target: { checked } }) => {
    this.setState({ recurring: checked }, () => this.updateHash())
  }

  handleDateChange = name => date => {
    this.setState({ [name]: date !== null && isValid(date) ? format(date, 'yyyy-MM-dd') : null }, () =>
      this.updateHash()
    )
  }

  handleConfirm = () => {
    // eslint-disable-next-line no-unused-vars
    let { deadline_hash, ...rest } = this.state
    this.props.onSave(rest)
  }

  isFormValid = () => {
    let { description, error_emails } = this.state
    return description !== '' && error_emails === false
  }

  render() {
    let title = this.state.id ? 'Modifica scadenza' : 'Crea scadenza'
    let { description, recipients, expiry_date, recurring, recurring_type, error_emails } = this.state
    let parsedExpiryDate = expiry_date !== null ? parseISO(expiry_date) : null

    return (
      <>
        <h4 className="border-bottom text-primary">{title}</h4>
        <div className="flex-fill position-relative overflow-auto">
          <div className="w-100 ps-1 pe-4 position-absolute">
            <Form.Group className="mt-3 mb-3">
              <Form.Label>Descrizione</Form.Label>
              <span> *</span>
              <Form.Control name="description" value={description} onChange={this.handleChange} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>recipients</Form.Label>
              <span> *</span>
              <Form.Control
                name="recipients"
                placeholder={''}
                value={recipients}
                onChange={this.onChangeRecipientsEmails}
                isInvalid={error_emails}
              />
              <Form.Control.Feedback type="invalid">indirizzo email non valido</Form.Control.Feedback>
              <Form.Text className="text-muted">Inserire indirizzi email separati da virgola</Form.Text>
            </Form.Group>
            <Form.Group className="mb-3 mt-3">
              <Form.Check
                type="switch"
                id="recurring"
                name="recurring"
                checked={recurring === true}
                label="Scadenza ricorrente"
                onChange={this.handleChangeEnabled}
              />
            </Form.Group>
            {recurring === true && (
              <Form.Group className="mb-3 mt-3">
                <Form.Label as="h5">Tipologia</Form.Label>
                <Form.Check
                  type="radio"
                  id="recurring.monthly"
                  name={`recurring_type`}
                  value="monthly"
                  checked={recurring_type === 'monthly'}
                  label="Mensile"
                  onChange={this.onChangeTypesRecurring}
                />
                <Form.Check
                  type="radio"
                  id="recurring.yearly"
                  name={`recurring_type`}
                  value="yearly"
                  checked={recurring_type === 'yearly'}
                  label="Annuale"
                  onChange={this.onChangeTypesRecurring}
                />
              </Form.Group>
            )}
            <Form.Group as={Col} className="mb-3 mb-md-0 col-12 col-md-4">
              <Form.Label>Periodo</Form.Label>
              <div className="form-control p-0">
                <DatePicker
                  style={{ lineHeight: 1.7 }}
                  className="form-control"
                  selected={parsedExpiryDate}
                  placeholderText="Scadenza"
                  dateFormat="dd-MM-yyyy"
                  onChange={this.handleDateChange('expiry_date')}
                  isClearable
                  showYearDropdown
                  yearDropdownItemNumber={90}
                  scrollableYearDropdown
                />
              </div>
            </Form.Group>
          </div>
        </div>
        <div className="border-top pt-2">
          <Button
            className="float-end"
            variant="primary"
            disabled={!this.isFormValid() || this.initialHash === this.state.deadline_hash}
            onClick={this.handleConfirm}>
            Conferma
          </Button>
          <Button className="float-end me-2" variant="secondary" onClick={this.props.closeForm}>
            Annulla
          </Button>
        </div>
      </>
    )
  }
}
export default DeadlineForm
