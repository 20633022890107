import React from 'react'
import { connect } from 'react-redux'

const mstp = state => {
  let { business_name } = state.storeManager.current_tenant
  return { business_name }
}
class Header extends React.Component {
  render() {
    let { business_name } = this.props
    return <h3 style={{ color: business_name ? '' : 'transparent' }}>{business_name || 'Punto vendita'}</h3>
  }
}
export default connect(mstp)(Header)
