import React from 'react'
import { Button } from 'react-bootstrap'
import ActionsDropdown from 'src/components/system_wide/actions_dropdown'
import SearchButton from 'src/components/system_wide/search_button'
import { format, parseISO } from 'date-fns'
import ResponsiveTable from 'src/components/system_wide/responsive_table'
import UpoloadForm from 'src/components/system_wide/upload_form'
import { getDocuments, resetDocuments, deleteDocument } from 'src/store/documents'
import 'react-datepicker/dist/react-datepicker.css'
import { downloadFile } from 'src/components/system_wide/file_downloader_button'
import { showConfirmDialog } from 'src/components/system_wide/confirm_dialog'

class DocumentsForm extends React.Component {
  static defaultProps = {
    tenant: '',
    documents: [],
    total: 0,
    dispatch: () => {},
    type: '',
    type_id: 0,
  }
  state = {
    show_upload_form: false,
    filter: '',
  }

  tableRef = null
  constructor(props) {
    super(props)
    this.tableRef = React.createRef()
  }

  componentDidMount() {
    this.props.dispatch(resetDocuments())
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.tenant !== this.props.tenant) {
      if (this.props.tenant) {
        this.props.dispatch(resetDocuments())
        await this.getData()
      }
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetDocuments())
  }

  toggleUploadForm = () => this.setState({ show_upload_form: !this.state.show_upload_form })

  handleUploadConfirm = () => {
    this.toggleUploadForm()
    this.tableRef?.current?.refreshData()
  }

  actions = [
    {
      icon: 'fa-download',
      text: 'Scarica',
      onClick: async rowData => {
        let { id, name } = rowData
        this.handleDownloadFile(id, name)
      },
    },
    {
      icon: 'fa-trash-alt',
      text: 'Elimina',
      onClick: rowData => {
        let { id, name } = rowData
        showConfirmDialog(
          'Elimina documento',
          <div>
            Per procedere alla cancellazione di <b>{name}</b> click su Elimina
          </div>,
          async () => {
            await this.props.dispatch(deleteDocument({ tenant: this.props.tenant, id }))
            this.tableRef?.current?.refreshData()
          },
          () => {},
          'primary',
          'Elimina'
        )
      },
    },
  ]

  actionsRenderer = ({ rowData }) => {
    return <ActionsDropdown rowData={rowData} actions={this.actions} />
  }

  columns = [
    {
      title: '',
      className: 'd-lg-block text-center',
      style: { width: '6%', minWidth: '3rem' },
      data: this.actionsRenderer,
    },
    {
      title: 'Name',
      className: 'd-lg-block flex-fill',
      style: { width: '32%' },
      sortable: true,
      data: 'name',
    },
    {
      title: 'Descrizione',
      className: 'd-lg-block',
      style: { width: '32%' },
      data: 'description',
    },
    {
      title: 'Creato il',
      className: 'd-lg-block',
      style: { width: '32%' },
      data: ({ rowData: { created_at } }) => {
        return created_at ? format(parseISO(created_at), 'dd-MM-yyyy HH:mm') : ''
      },
    },
  ]

  handleDownloadFile = async (id, name) => {
    try {
      await downloadFile({
        url: `api/document/${this.props.tenant}/download/${id}`,
        filename: name,
      })
    } catch (err) {
      console.log(err)
    }
  }

  onFilterChange = filter => this.setState({ filter })

  getData = async (index = 0, chunkSize, sortData, filter) => {
    let { tenant, type, type_id } = this.props
    await this.props.dispatch(
      getDocuments({
        tenant,
        offset: index,
        limit: chunkSize,
        sortData,
        filter,
        type,
        type_id,
      })
    )
  }

  render() {
    return (
      <>
        <h4 className="border-bottom text-primary">Documenti</h4>
        <div className={`flex-fill overflow-auto ${this.props.type !== 'store' ? 'position-relative' : ''}`}>
          <div
            className={`w-100 ps-1 ${this.props.type !== 'store' ? 'position-absolute' : ''}`}
            style={{ height: '100%' }}>
            <div className="d-flex flex-wrap gap-2 mb-3">
              <div className="flex-fill flex-sm-grow-0 flex-sm-shrink-0">
                <button className="btn btn-primary btn-block w-100" onClick={this.toggleUploadForm}>
                  <i className="fas fa-plus fa-fw text-start" />
                  Aggiungi documento
                </button>
              </div>
              <div className="flex-fill">
                <SearchButton
                  value={this.state.filter}
                  onChange={this.onFilterChange}
                  disabled={this.state.show_upload_form}
                  className="float-md-end"
                />
              </div>
            </div>
            <div className="d-flex flex-row" style={{ height: '90%' }}>
              <style jsx="true" global="true">{`
                .responsive_table_header,
                .responsive_table_footer {
                  background-color: white !important;
                }
              `}</style>
              <ResponsiveTable
                ref={this.tableRef}
                className="flex-fill"
                data={this.props.documents}
                getData={this.getData}
                totalRecords={this.props.total}
                columns={this.columns}
                menuWidth="40rem"
                rowClasses={rowData => (rowData.enabled ? '' : `text-muted`)}
                filter={this.state.filter}
              />
            </div>
          </div>
        </div>
        {this.props.type !== 'store' && (
          <div className="border-top pt-2">
            <Button className="float-end me-2" variant="secondary" onClick={this.props.closeForm}>
              Annulla
            </Button>
          </div>
        )}
        {this.state.show_upload_form === true && (
          <UpoloadForm
            tenant={this.props.tenant}
            show={this.state.show_upload_form}
            type={this.props.type}
            type_id={this.props.type_id}
            onCloseModal={this.toggleUploadForm}
            onConfirm={this.handleUploadConfirm}
          />
        )}
      </>
    )
  }
}
export default DocumentsForm
