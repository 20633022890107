import React from 'react'
import { Button, Form } from 'react-bootstrap'
import hash from 'object-hash'

class MaintenanceTypeForm extends React.Component {
  static defaultProps = {
    maintenance_type: {},
    isPending: false,
    onSave: () => {},
    closeForm: () => {},
  }
  state = {
    id: null,
    name: '',

    maintenance_type_hash: '',
  }

  initialHash = ''

  constructor(props) {
    super(props)
    this.state.id = props.maintenance_type.id
    this.state.name = props.maintenance_type.name
    // eslint-disable-next-line no-unused-vars
    let { maintenance_type_hash, ...rest } = this.state
    this.initialHash = hash(rest)
    this.state.maintenance_type_hash = this.initialHash
  }

  updateHash = () => {
    // eslint-disable-next-line no-unused-vars
    let { maintenance_type_hash, ...rest } = this.state
    this.setState({ maintenance_type_hash: hash(rest) })
  }
  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value }, () => this.updateHash())
  }
  handleConfirm = () => {
    let { maintenance_type_hash, ...rest } = this.state
    this.props.onSave(rest)
  }

  render() {
    let { name } = this.state
    return (
      <>
        <h4 className="border-bottom text-primary">Tipo di manutenzione</h4>
        <div className="flex-fill position-relative">
          <div className="ps-1 position-absolute row">
            <Form.Group className="mt-3 mb-3 col-12">
              <Form.Label>Tipo di manutenzione</Form.Label>
              <span> *</span>
              <Form.Control name="name" placeholder="Tipo di manutenzione" value={name} onChange={this.handleChange} />
            </Form.Group>
          </div>
        </div>
        <div className="border-top pt-2">
          <Button
            className="float-end"
            variant="primary"
            disabled={this.initialHash === this.state.maintenance_type_hash}
            onClick={this.handleConfirm}>
            Conferma
          </Button>
          <Button className="float-end me-2" variant="secondary" onClick={this.props.closeForm}>
            Annulla
          </Button>
        </div>
      </>
    )
  }
}
export default MaintenanceTypeForm
