import React from 'react'
import { Routes, Route } from 'react-router-dom'
import NLApp from './nl_app'
import ErrorPage from './error-page'
import PointofSaleDataPage from 'src/pages/drugstore/store_name/point_of_sale'
import ManagePointofSaleDataPage from 'src/pages/drugstore/store_name/manage_point_of_sale'
import UsersPage from 'src/pages/drugstore/users'
import DashboardPage from 'src/pages/drugstore/dashboard'
import MaintenanceTypesPage from 'src/pages/drugstore/maintenance_types'
import LoginPage from 'src/pages/drugstore/login'
import MaintenancePage from 'src/pages/drugstore/store_name/maintenances'
import RemoveTenantPage from 'src/pages/drugstore/store_name/remove_tenant'
import SalePage from 'src/pages/drugstore/store_name/sales'
import DeadlinePage from 'src/pages/drugstore/store_name/deadlines'
import SetupCostPage from 'src/pages/drugstore/store_name/setup_cost'
import AppLayout from 'src/components/app_layout'
import IndexPage from 'src/pages/drugstore/index'
export default function NLRoute() {
  return (
    <>
      <Routes>
        <Route path="/" element={<NLApp />} />
        <Route path="/drugstore/*" element={<AdminRoute />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  )
}

const AdminRoute = () => {
  return (
    <Routes>
      <Route element={<NLApp />}>
        <Route path="/" element={<IndexPage />} />
        <Route path="/users" element={<UsersPage />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/maintenance_types" element={<MaintenanceTypesPage />} />
        <Route path="/:store_name" element={<AppLayout />}>
          <Route path="point_of_sale_data" element={<PointofSaleDataPage />} />
          <Route path="manage_point_of_sale_data" element={<ManagePointofSaleDataPage />} />
          <Route path="setup_costs" element={<SetupCostPage />} />
          <Route path="maintenances" element={<MaintenancePage />} />
          <Route path="deadlines" element={<DeadlinePage />} />
          <Route path="sales" element={<SalePage />} />
          <Route path="remove_tenant" element={<RemoveTenantPage />} />
        </Route>
        <Route path="login" element={<LoginPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Route>
    </Routes>
  )
}
