import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { error, success } from 'src/components/system_wide/notification'
import axios from 'src/lib/axios'

const initialState = {
  total: 0,
  acls: [],
  roles: [],
  show_form: false,
  current_role: {
    id: null,
    name: '',
    acl_id: null,
  },
}

export const getAllAcls = createAsyncThunk('rolesManager/getAllAcls', async (tenant, thunkApi) => {
  try {
    let response = await axios({
      url: `/api/acls`,
      params: { sort_column: 'name' },
    })
    return { acls: response.data.acls }
  } catch (err) {
    error('Impossibile recuperare i livelli di accesso')
    return thunkApi.rejectWithValue()
  }
})

export const getRoles = createAsyncThunk(
  'rolesManager/getRoles',
  async ({ offset, limit, sortData, filter }, thunkApi) => {
    try {
      let { total: currentTotal } = thunkApi.getState().rolesManager
      if (offset > currentTotal) return

      let { sort_column, sort_direction } = sortData
      let response = await axios({
        url: `/api/roles`,
        params: { offset, limit, sort_column, sort_direction, filter },
      })
      let { total, roles } = response.data
      return { total, roles, reset: offset === 0 }
    } catch (err) {
      error('Impossible recuperare i ruoli')
      return thunkApi.rejectWithValue()
    }
  }
)

export const createRole = createAsyncThunk('rolesManager/createRole', async ({ tenant, role }, thunkApi) => {
  try {
    let { name, acl_id } = role
    await axios({
      url: `/api/role`,
      method: 'post',
      data: { name, acl_id },
    })
    success('Ruolo creato con successo')
    thunkApi.dispatch(resetCurrentRole())
    thunkApi.dispatch(toggleShowForm())
  } catch (err) {
    error('Impossibile creare ruolo')
    return thunkApi.rejectWithValue()
  }
})

export const updateRole = createAsyncThunk('rolesManager/updateRole', async ({ tenant, role }, thunkApi) => {
  try {
    let { id, name, acl_id } = role
    await axios({
      url: `/api/role/${id}`,
      method: 'patch',
      data: { name, acl_id },
    })
    success('Ruolo aggiornato con successo')
    thunkApi.dispatch(resetCurrentRole())
    thunkApi.dispatch(toggleShowForm())
  } catch (err) {
    error('Impossibile aggiornare ruolo')
    return thunkApi.rejectWithValue()
  }
})

export const deleteRole = createAsyncThunk('rolesManager/deleteRole', async ({ tenant, id }, thunkApi) => {
  try {
    await axios({ url: `/api/role/${id}`, method: 'delete' })
    success('Ruolo eliminato con successo')
    thunkApi.dispatch(resetCurrentRole())
  } catch (err) {
    error('Impossibile eliminare ruolo')
    return thunkApi.rejectWithValue()
  }
})

export const rolesManagerSlice = createSlice({
  name: 'rolesManager',
  initialState,

  reducers: {
    setCurrentRole: (state, action) => {
      let { id, name, acl_id } = action.payload
      state.current_role.id = id
      state.current_role.name = name
      state.current_role.acl_id = acl_id
    },
    toggleShowForm: state => {
      state.show_form = !state.show_form
    },
    resetCurrentRole: state => {
      state.current_role = initialState.current_role
    },
    resetRoleManager: () => initialState,
  },

  extraReducers: {
    [getAllAcls.fulfilled]: (state, action) => {
      state.acls = action.payload.acls
    },
    [getRoles.fulfilled]: (state, action) => {
      if (!action?.payload) return
      let { total, roles, reset } = action.payload
      state.total = total
      state.roles = reset ? [...roles] : [...state.roles, ...roles]
    },
    [getRoles.rejected]: state => {
      state.total = initialState.total
      state.roles = initialState.roles
    },
  },
})

export const { setCurrentRole, resetCurrentRole, toggleShowForm, resetRoleManager } = rolesManagerSlice.actions
export default rolesManagerSlice.reducer
