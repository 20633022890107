import React from 'react'
import { Dropdown } from 'react-bootstrap'
import withCapabilities from 'src/enhancers/with_capabilities'
import styles from 'src/components/system_wide/actions_dropdown.module.css'

class ActionsDropdown extends React.PureComponent {
  render() {
    let { rowData, actions } = this.props
    return (
      <Dropdown>
        <Dropdown.Toggle className={`${styles.dropdown_toggle}`} variant="secondary">
          <i className={`fas fa-cog`} />
        </Dropdown.Toggle>
        <Dropdown.Menu className="shadow">
          {actions.map((action, i) => {
            let { disabled = false } = action
            if (typeof disabled === 'function') {
              disabled = disabled(rowData)
            }
            return <ActionItem key={i} disabled={disabled} action={action} rowData={rowData} caps={action.caps} />
          })}
        </Dropdown.Menu>
      </Dropdown>
    )
  }
}

const ActionItem = withCapabilities({})(props => {
  let { disabled, action, rowData } = props
  let { onClick = false, href = false } = action
  let itemProps = { ...(onClick ? { onClick: () => onClick(rowData) } : {}), ...(href ? { href: href(rowData) } : {}) }

  if (action.text === '---') {
    return <Dropdown.Divider />
  } else {
    return (
      <Dropdown.Item disabled={disabled} {...itemProps}>
        <i className={`fas ${action.icon}`} />
        {action.text}
      </Dropdown.Item>
    )
  }
})
export default ActionsDropdown
