import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { error, success } from 'src/components/system_wide/notification'
import axios from 'src/lib/axios'

const initialState = {
  total: 0,
  maintenances: [],
  total_opened: 0,
  maintenances_opened: [],
  total_pending: 0,
  maintenances_pending: [],
  total_closed: 0,
  maintenances_closed: [],
  show_form: false,
  show_form_opened: false,
  show_form_pending: false,
  current_maintenance: {
    tenant: '',
    id: null,
    status: '',
    description: '',
    description_pending: '',
    description_closed: '',
    maintenance_type_id: '',
    costs: 0,
  },
}

export const getAllMaintenancesClosed = createAsyncThunk('maintenances/getAllMaintenancesClosed', async thunkApi => {
  try {
    let response = await axios({
      url: `/api/maintenances/all/closed`,
    })
    return response.data
  } catch (err) {
    error('Impossibile recuperare manutenzioni')
    return thunkApi.rejectWithValue()
  }
})

export const getAllMaintenances = createAsyncThunk(
  'maintenances/getAllMaintenances',
  async ({ offset, limit, sortData, filter, status, filtered_types }, thunkApi) => {
    try {
      let { total: currentTotal } = thunkApi.getState().maintenances
      if (offset > currentTotal) return

      let { sort_column, sort_direction } = sortData ?? { sort_column: 'id', sort_direction: 'DESC' }
      let response = await axios({
        url: `/api/maintenances/all`,
        params: {
          offset,
          limit,
          sort_column,
          sort_direction,
          filter,
          status,
          filtered_types: filtered_types.join(','),
        },
      })
      let { total, maintenances } = response.data
      return { status, total, maintenances, reset: offset === 0 }
    } catch (err) {
      error('Impossibile recuperare manutenzioni')
      return thunkApi.rejectWithValue()
    }
  }
)

export const getMaintenances = createAsyncThunk(
  'maintenances/getMaintenances',
  async ({ tenant, offset, limit, sortData, filter, filtered_statuses, filtered_types }, thunkApi) => {
    try {
      let { total: currentTotal } = thunkApi.getState().maintenances
      if (offset > currentTotal) return

      let { sort_column, sort_direction } = sortData ?? { sort_column: 'id', sort_direction: 'DESC' }
      let response = await axios({
        url: `/api/maintenances/${tenant}`,
        params: {
          offset,
          limit,
          sort_column,
          sort_direction,
          filter,
          filtered_statuses: filtered_statuses.join(','),
          filtered_types: filtered_types.join(','),
        },
      })
      let { total, maintenances } = response.data
      return { total, maintenances, reset: offset === 0 }
    } catch (err) {
      error('Impossibile recuperare manutenzioni')
      return thunkApi.rejectWithValue()
    }
  }
)

export const createMaintenance = createAsyncThunk(
  'maintenances/createMaintenance',
  async ({ tenant, maintenance, section, toggle = true }, thunkApi) => {
    try {
      let { status, description, description_pending, description_closed, costs, maintenance_type_id } = maintenance
      await axios({
        url: `/api/maintenance/${tenant}`,
        method: 'post',
        data: { status, description, description_pending, description_closed, costs, maintenance_type_id },
      })
      success('Manutenzione creata con successo')
      thunkApi.dispatch(resetCurrentMaintenance())
      if (toggle) {
        if (section === 'global') thunkApi.dispatch(toggleShowForm())
        if (section === 'opened') thunkApi.dispatch(toggleShowFormOpened())
        if (section === 'pending') thunkApi.dispatch(toggleShowFormPending())
      }
    } catch (err) {
      console.log(err)
      error('Impossibile creare manutenzione')
      return thunkApi.rejectWithValue()
    }
  }
)

export const updateMaintenance = createAsyncThunk(
  'maintenances/updateMaintenance',
  async ({ tenant, maintenance, section, toggle = true }, thunkApi) => {
    try {
      let { id, status, description, description_pending, description_closed, costs, maintenance_type_id } = maintenance
      await axios({
        url: `/api/maintenance/${tenant}/${id}`,
        method: 'patch',
        data: { status, description, description_pending, description_closed, costs, maintenance_type_id },
      })
      success('Manutenzione aggiornata con successo')
      thunkApi.dispatch(resetCurrentMaintenance())
      if (toggle) {
        if (section === 'global') thunkApi.dispatch(toggleShowForm())
        if (section === 'opened') thunkApi.dispatch(toggleShowFormOpened())
        if (section === 'pending') thunkApi.dispatch(toggleShowFormPending())
      }
    } catch (err) {
      console.log(err)
      error('Impossibile aggiornare manutenzione')
      return thunkApi.rejectWithValue()
    }
  }
)

export const deleteMaintenance = createAsyncThunk(
  'maintenances/deleteMaintenance',
  async ({ tenant, id }, thunkApi) => {
    try {
      await axios({ url: `/api/maintenance/${tenant}/${id}`, method: 'delete' })
      success('Manutenzione aggiornata con successo')
      thunkApi.dispatch(resetCurrentMaintenance())
    } catch (err) {
      error('Impossibile eliminare la manutenzione')
      return thunkApi.rejectWithValue()
    }
  }
)

export const maintenancesSlice = createSlice({
  name: 'maintenances',
  initialState,

  reducers: {
    setCurrentMaintenance: (state, action) => {
      let { tenant, id, status, description, description_pending, description_closed, maintenance_type_id, costs } =
        action.payload
      state.current_maintenance.tenant = tenant ?? ''
      state.current_maintenance.id = id
      state.current_maintenance.status = status
      state.current_maintenance.description = description ?? ''
      state.current_maintenance.description_pending = description_pending ?? ''
      state.current_maintenance.description_closed = description_closed ?? ''
      state.current_maintenance.maintenance_type_id = maintenance_type_id
      state.current_maintenance.costs = costs
    },
    toggleShowForm: state => {
      state.show_form = !state.show_form
    },
    toggleShowFormOpened: state => {
      state.show_form_opened = !state.show_form_opened
    },
    toggleShowFormPending: state => {
      state.show_form_pending = !state.show_form_pending
    },
    resetCurrentMaintenance: state => {
      state.current_maintenance = initialState.current_maintenance
    },
    resetMaintenances: () => initialState,
  },

  extraReducers: {
    [getAllMaintenancesClosed.fulfilled]: (state, action) => {
      if (!action?.payload) return
      state.total_closed = action.payload?.total ?? 0
    },
    [getAllMaintenances.fulfilled]: (state, action) => {
      if (!action?.payload) return
      let { status, total, maintenances } = action.payload
      state[`total_${status}`] = total
      state[`maintenances_${status}`] = action.payload.reset
        ? [...maintenances]
        : [...state.maintenances, ...maintenances]
    },

    [getAllMaintenances.rejected]: state => {
      state.total_opened = 0
      state.maintenances_opened = []
      state.total_pending = 0
      state.maintenances_pending = []
      state.total_closed = 0
      state.maintenances_closed = []
    },
    [getMaintenances.fulfilled]: (state, action) => {
      if (!action?.payload) return
      let { total, maintenances } = action.payload
      state.total = total
      state.maintenances = action.payload.reset ? [...maintenances] : [...state.maintenances, ...maintenances]
    },
    [getMaintenances.rejected]: state => {
      state.total = initialState.total
      state.maintenances = initialState.maintenances
    },
  },
})

export const {
  setCurrentMaintenance,
  resetCurrentMaintenance,
  toggleShowForm,
  toggleShowFormOpened,
  toggleShowFormPending,
  resetMaintenances,
} = maintenancesSlice.actions
export default maintenancesSlice.reducer
