import React from 'react'
import { Modal, Button, Form, Alert } from 'react-bootstrap'
import FileDropzone from 'src/components/system_wide/file_dropzone'
import fileUploader from 'src/components/system_wide/file_uploader'

class UpoloadForm extends React.Component {
  static defaultProps = {
    tenant: '',
    show: false,
    type_id: 0,
    type: '',
    onConfirm: () => {},
    onCloseModal: () => null,
    dispatch: () => null,
  }

  state = {
    description: '',
    filename: '',
    uploading: false,
    failed: false,

    show_error_message: false,
    show_success_message: false,
  }

  file = null
  uploader = null

  handleDrop = file => {
    const { name } = file
    this.file = file
    this.setState({
      errored: [],
      filename: name,
      uploading: false,
      show_success_message: false,
      show_error_message: false,
    })
  }

  handleCancel = () => {
    this.file = null
    this.setState({
      msg: '',
      filename: '',
      uploading: false,
      show_success_message: false,
      show_error_message: false,
    })
  }

  onConfirmModal = async () => {
    let { tenant, type, type_id } = this.props
    let { description } = this.state
    this.setState({ uploading: true })
    if (await fileUploader(`/api/document/${tenant}`, this.file, { type, type_id, description }, () => {})) {
      //success upload restore
      this.onRestoreSuccess()
    } else {
      //error upload restore
      this.onRestoreError()
    }
  }

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value })
  }

  onRestoreError = () => {
    //error(t('tenant_manager.settings.restore_backup_error', 'Unable to restore provided backup file'))
    this.setState({ show_success_message: true })
    setTimeout(() => {
      this.setState({ show_success_message: false })
      this.handleCancel()
    }, 2000)
  }

  onRestoreSuccess = () => {
    // success(
    //   t('tenant_manager.settings.restore_backup_success', 'Tenant succesfully restored from provided backup file')
    this.setState({ show_success_message: true })
    setTimeout(() => {
      this.setState({ show_success_message: false })
      this.props.onConfirm()
    }, 2000)
  }

  render() {
    let { uploading, description, filename, show_success_message, show_error_message } = this.state
    return (
      <Modal backdrop="static" centered show={this.props.show} onExited={this.props.onCloseModal}>
        <Modal.Header>
          <Modal.Title>Carica documento</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mb-3">
          <div>Trascina o clicca sull'area sottostante per caricare un file</div>
          <Form.Group className="mt-3 mb-3">
            <Form.Label>Descrizione</Form.Label>
            <Form.Control name="description" value={description} onChange={this.handleChange} />
          </Form.Group>
          <Form.Group>
            <FileDropzone onDrop={this.handleDrop} dimmed={uploading} />
            {filename !== '' && (
              <div className="d-flex flex-row align-items-center">
                <b>Filename</b>: {filename}
                <i className="fa fa-trash mt-1 ms-auto" onClick={this.handleCancel} style={{ cursor: 'pointer' }}></i>
              </div>
            )}
          </Form.Group>
          {show_success_message === true && (
            <Alert className="mt-3" variant="success">
              Upload avvenuto con successo
            </Alert>
          )}
          {show_error_message === true && (
            <Alert className="mt-3" variant="danger">
              Errore durante l'upload del file
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onCloseModal} variant="secondary">
            Annulla
          </Button>
          <Button onClick={this.onConfirmModal} disabled={this.file === null}>
            {uploading === true && (
              <div
                className="spinner-border spinner-border-sm me-2"
                role="status"
                style={{ color: 'var(--contrast-primary)' }}>
                <span className="sr-only">Loading...</span>
              </div>
            )}
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default UpoloadForm
