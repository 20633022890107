import { configureStore } from '@reduxjs/toolkit'

import loadingReduce from 'src/store/loading'
import userInfoReducer from 'src/store/userinfo'
import loginReducer from 'src/store/login'
import storeManagerReducer from 'src/store/store_manager'
import usersReducer from 'src/store/users'
import deadlinesReducer from 'src/store/deadlines'
import documentsReducer from 'src/store/documents'
import setupCostsReducer from 'src/store/setup_costs'
import maintenancesReducer from 'src/store/maintenances'
import salesReducer from 'src/store/sales'
import aclsManagerReducer from 'src/store/acls_manager'
import rolesManagerReducer from 'src/store/roles_manager'
import managerReducer from 'src/store/manager'
import employeesReducer from 'src/store/employees'
import maintenanceTypesReducer from 'src/store/maintenance_types'

const addGlobalResetEnhancer = createStore => (reducer, initialState, enhancer) => {
  const resettableReducer = (state, action) => {
    if (action.type === 'RESET_STORE') {
      return reducer(undefined, action)
    } else {
      return reducer(state, action)
    }
  }
  return createStore(resettableReducer, initialState, enhancer)
}

export function makeStore() {
  return configureStore({
    reducer: {
      loading: loadingReduce,
      userInfo: userInfoReducer,
      loginData: loginReducer,
      storeManager: storeManagerReducer,
      users: usersReducer,
      deadlines: deadlinesReducer,
      setupCosts: setupCostsReducer,
      maintenances: maintenancesReducer,
      sales: salesReducer,
      aclsManager: aclsManagerReducer,
      rolesManager: rolesManagerReducer,
      documents: documentsReducer,
      manager: managerReducer,
      employees: employeesReducer,
      maintenanceTypes: maintenanceTypesReducer,
    },

    devTools: process.env.NODE_ENV !== 'production',
    enhancers: [addGlobalResetEnhancer],
  })
}

const store = makeStore()
export default store

export const resetStore = () => store.dispatch({ type: 'RESET_STORE' })
