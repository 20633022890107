import React from 'react'
import { Button, Form } from 'react-bootstrap'
import NumericInput from 'react-numeric-input'
import hash from 'object-hash'

class SetupCostForm extends React.Component {
  state = {
    id: null,
    description: '',
    costs: 0,

    setup_cost_hash: '',
  }

  initialHash = ''

  constructor(props) {
    super(props)

    if (props.setup_cost.id) {
      let { id, description, costs } = props.setup_cost
      this.state.id = id
      this.state.description = description
      this.state.costs = costs
    }

    // eslint-disable-next-line no-unused-vars
    let { setup_cost_hash, ...rest } = this.state
    this.initialHash = hash(rest)
    this.state.setup_cost_hash = this.initialHash
  }

  updateHash = () => {
    // eslint-disable-next-line no-unused-vars
    let { setup_cost_hash, ...rest } = this.state
    this.setState({ setup_cost_hash: hash(rest) })
  }

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value }, () => this.updateHash())
  }

  handleConfirm = () => {
    // eslint-disable-next-line no-unused-vars
    let { setup_cost_hash, ...rest } = this.state
    this.props.onSave(rest)
  }

  onChangeNumericInput = (value, rawValue, { name }) => {
    this.setState({ [name]: value }, () => this.updateHash())
  }

  isFormValid = () => {
    let { description } = this.state
    return description !== ''
  }

  render() {
    let title = this.state.id ? 'Modifica spesa allestimento' : 'Crea spesa allestimento'
    let { description, costs } = this.state
    return (
      <>
        <h4 className="border-bottom text-primary">{title}</h4>
        <div className="flex-fill position-relative overflow-auto">
          <div className="w-100 ps-1 pe-4 position-absolute">
            <Form.Group className="mt-3 mb-3">
              <Form.Label>Descrizione</Form.Label>
              <span> *</span>
              <Form.Control
                name="description"
                as="textarea"
                rows={3}
                value={description}
                onChange={this.handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Costo</Form.Label>
              <div style={{ width: '10em' }}>
                <NumericInput
                  className="form-control form-control-sm"
                  precision={2}
                  min={0}
                  step={0.5}
                  snap
                  inputMode="decimal"
                  strict
                  name="costs"
                  value={costs}
                  onChange={this.onChangeNumericInput}
                />
              </div>
            </Form.Group>
          </div>
        </div>
        <div className="border-top pt-2">
          <Button
            className="float-end"
            variant="primary"
            disabled={!this.isFormValid() || this.initialHash === this.state.setup_cost_hash}
            onClick={this.handleConfirm}>
            Conferma
          </Button>
          <Button className="float-end me-2" variant="secondary" onClick={this.props.closeForm}>
            Annulla
          </Button>
        </div>
      </>
    )
  }
}
export default SetupCostForm
