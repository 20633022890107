import axios from 'src/lib/axios'

const fileUploader = async (url, file, data = null, onUploadProgress) => {
  const formData = new FormData()
  formData.append('file', file)
  if (data) {
    for (let key in data) {
      formData.append(key, data[key])
    }
  }
  try {
    await axios.post(url, formData, { onUploadProgress })
    return true
  } catch (error) {
    console.log(error)
    return false
  }
}

export default fileUploader
