import React from 'react'
import { connect } from 'react-redux'
import { Modal, Button, Alert } from 'react-bootstrap'

import ActionsDropdown from 'src/components/system_wide/actions_dropdown'
import SearchButton from 'src/components/system_wide/search_button'
import ResponsiveTable from 'src/components/system_wide/responsive_table'
import CheckboxFilter from 'src/components/system_wide/checkbox_filter'
import DocumentsForm from 'src/components/system_wide/documents_form'
import MaintenanceForm from 'src/components/drugstore/tenant_management/maintenances/maintenance_form'
import {
  setCurrentMaintenance,
  createMaintenance,
  updateMaintenance,
  deleteMaintenance,
  toggleShowForm,
  getMaintenances,
  resetCurrentMaintenance,
  resetMaintenances,
} from 'src/store/maintenances'

import { getAllMaintenanceTypes } from 'src/store/maintenance_types'

const mstp = state => {
  let { name: tenant_name } = state.storeManager.current_tenant
  let { documents, total: total_documents } = state.documents
  let { maintenance_types } = state.maintenanceTypes
  let { total, maintenances, show_form, current_maintenance } = state.maintenances
  return {
    tenant_name,
    total,
    maintenances,
    maintenance_types,
    show_form,
    current_maintenance,
    documents,
    total_documents,
  }
}

class Maintenances extends React.Component {
  state = {
    ready: false,
    show_modal: false,
    filter: '',
    filtered_statuses: [],
    filtered_types: [],
    mode: 'edit',
  }

  tableRef = null
  maintenances_status = [
    { id: 'opened', name: 'Aperto' },
    { id: 'pending', name: 'Lavorazione' },
    { id: 'closed', name: 'Chiuso' },
  ]

  constructor(props) {
    super(props)
    this.tableRef = React.createRef()
    this.maintenances_status.forEach(s => {
      this.state.filtered_statuses.push(s.id)
    })
  }

  actions = [
    {
      icon: 'fa-edit',
      text: 'Modifica',
      onClick: rowData => {
        let { id, description, description_pending, description_closed, status, costs, maintenance_type_id } = rowData
        this.setState({ mode: 'edit' })
        this.props.dispatch(
          setCurrentMaintenance({
            id,
            description,
            description_pending,
            description_closed,
            status,
            costs,
            maintenance_type_id,
          })
        )
        this.props.dispatch(toggleShowForm())
      },
    },
    {
      icon: 'fa-person-digging',
      text: 'In Lavorazione',
      onClick: async rowData => {
        let { id, description, description_pending, description_closed, status, costs, maintenance_type_id } = rowData
        if (id) {
          status = 'pending'
          let maintenance = {
            id,
            description,
            description_pending,
            description_closed,
            status,
            costs,
            maintenance_type_id,
          }
          await this.props.dispatch(
            updateMaintenance({ tenant: this.props.tenant_name, maintenance, section: 'global', toggle: false })
          )
          this.tableRef.current.refreshData()
        }
      },
    },
    {
      icon: 'fa-check',
      text: 'Chiudi',
      onClick: async rowData => {
        let { id, description, description_pending, description_closed, status, costs, maintenance_type_id } = rowData
        if (id) {
          status = 'closed'
          let maintenance = {
            id,
            description,
            description_pending,
            description_closed,
            status,
            costs,
            maintenance_type_id,
          }
          await this.props.dispatch(
            updateMaintenance({ tenant: this.props.tenant_name, maintenance, section: 'global', toggle: false })
          )
          this.tableRef.current.refreshData()
        }
      },
    },
    {
      icon: 'fa-folder-open',
      text: 'Documenti',
      onClick: rowData => {
        let { id, description, description_pending, description_closed, status, costs, maintenance_type_id } = rowData
        this.props.dispatch(
          setCurrentMaintenance({
            id,
            description,
            description_pending,
            description_closed,
            status,
            costs,
            maintenance_type_id,
          })
        )
        this.setState({ mode: 'document' })
        this.props.dispatch(toggleShowForm())
      },
    },
    {
      icon: 'fa-trash-alt',
      text: 'Elimina',
      onClick: rowData => {
        let { id, description, description_pending, description_closed, status, costs, maintenance_type_id } = rowData
        this.props.dispatch(
          setCurrentMaintenance({
            id,
            description,
            description_pending,
            description_closed,
            status,
            costs,
            maintenance_type_id,
          })
        )
        this.setState({ show_modal: true })
      },
    },
  ]

  actionsRenderer = ({ rowData }) => {
    return <ActionsDropdown rowData={rowData} actions={this.actions} />
  }

  columns = [
    {
      title: '',
      className: 'd-lg-block text-center',
      style: { width: '6%', minWidth: '3rem' },
      data: this.actionsRenderer,
    },
    {
      title: 'Data apertura',
      className: 'd-lg-block flex-fill',
      style: { width: '15%' },
      sortable: true,
      data: 'opened_at',
    },
    {
      title: 'Descrizione',
      className: 'd-lg-block flex-fill',
      style: { width: '34%' },
      sortable: true,
      data: ({ rowData: { description, description_pending, description_closed } }) => {
        return (
          <>
            <div>
              <b>Apertura: </b>
              {description}
            </div>
            <div>
              <b>Lavorazione: </b>
              {description_pending}
            </div>
            <div>
              <b>Chiusura: </b>
              {description_closed}
            </div>
          </>
        )
      },
    },
    {
      title: 'Stato',
      className: 'd-lg-block flex-fill',
      style: { width: '10%' },
      sortable: true,
      data: ({ rowData: { status } }) => this.maintenances_status.filter(s => s.id === status)?.[0]?.name ?? '',
    },
    {
      title: 'Tipologia',
      className: 'd-lg-block flex-fill',
      style: { width: '10%' },
      sortable: true,
      data: ({ rowData: { maintenance_type_id } }) =>
        this.props.maintenance_types.filter(s => s.id === maintenance_type_id)?.[0]?.name ?? '',
    },
    {
      title: 'Costo',
      className: 'd-none d-lg-block flex-fill',
      style: { width: '10%' },
      sortable: true,
      data: 'costs',
    },
    {
      title: 'Creato/Aggiornato da',
      className: 'd-none d-lg-block text-center',
      style: { width: '15%' },
      data: ({ rowData }) => `${rowData?.created_by ?? '-'}/${rowData?.updated_by ?? '-'}`,
    },
  ]

  onFilterChange = filter => this.setState({ filter })

  onChangeFilter = key => values =>
    this.setState({ [key]: values }, () => {
      this.tableRef.current.refreshData()
    })

  getData = async (index = 0, chunkSize, sortData, filter) => {
    let { filtered_statuses, filtered_types } = this.state
    await this.props.dispatch(
      getMaintenances({
        tenant: this.props.tenant_name,
        offset: index,
        limit: chunkSize,
        sortData,
        filter,
        filtered_statuses,
        filtered_types,
      })
    )
  }

  saveMaintenance = async maintenance => {
    maintenance.id
      ? await this.props.dispatch(updateMaintenance({ tenant: this.props.tenant_name, maintenance, section: 'global' }))
      : await this.props.dispatch(createMaintenance({ tenant: this.props.tenant_name, maintenance, section: 'global' }))
    this.tableRef.current.refreshData()
  }

  onToggleForm = () => {
    if (this.props.show_form === true) {
      this.props.dispatch(resetCurrentMaintenance())
    }
    this.props.dispatch(toggleShowForm())
  }

  onCancel = () => {
    this.props.dispatch(resetCurrentMaintenance())
    this.setState({ show_modal: false })
  }

  onConfirmDelete = async () => {
    await this.props.dispatch(
      deleteMaintenance({ tenant: this.props.tenant_name, id: this.props.current_maintenance.id })
    )
    this.tableRef.current.refreshData()
    this.setState({ show_modal: false })
  }

  async componentDidMount() {
    await this.props.dispatch(getAllMaintenanceTypes())
    let filtered_types = this.props.maintenance_types.map(t => t.id)
    this.setState({ filtered_types, ready: true })
  }
  async componentDidUpdate(prevProps) {
    if (prevProps.tenant_name !== this.props.tenant_name) {
      if (this.props.tenant_name) {
        this.props.dispatch(resetMaintenances())
        await this.getData()
      }
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetMaintenances())
  }

  render() {
    let { ready, mode, filter, show_modal, filtered_statuses, filtered_types } = this.state
    let {
      tenant_name,
      documents,
      total_documents,
      maintenances,
      maintenance_types,
      total,
      current_maintenance,
      show_form,
    } = this.props
    if (!ready) return null
    let menuContent = MaintenanceForm
    let menuContentProps = {
      maintenanceTypes: maintenance_types,
      maintenance: current_maintenance,
      onSave: this.saveMaintenance,
      closeForm: this.onToggleForm,
    }
    if (mode === 'document') {
      menuContent = DocumentsForm
      menuContentProps = {
        tenant: tenant_name,
        documents: documents,
        total: total_documents,
        type: 'maintenance',
        type_id: current_maintenance?.id,
        closeForm: this.onToggleForm,
        dispatch: this.props.dispatch,
      }
    }
    if (!tenant_name) {
      return (
        <div className="container-fluid flex-fill d-flex flex-column px-0 ps-lg-3">
          <Alert variant="info">Seleziona punto vendita</Alert>
        </div>
      )
    }
    return (
      <div className="container-fluid flex-fill d-flex flex-column px-0 ps-lg-3">
        <div className="d-flex flex-column flex-fill">
          <div className="container-fluid px-0 mb-2">
            <div className="d-flex flex-wrap gap-2">
              <div className="flex-fill flex-sm-grow-0 flex-sm-shrink-0">
                <button
                  className="btn btn-primary btn-block w-100"
                  onClick={this.onToggleForm}
                  disabled={this.props.show_form}>
                  <i className="fas fa-plus fa-fw text-start" />
                  Aggiungi manutenzione
                </button>
              </div>
              <div className="ms-sm-4 flex-fill flex-sm-grow-0 flex-sm-shrink-0 d-flex flex-wrap gap-2 filter-group">
                <style jsx="true">
                  {`
                    .filter-group :global(button) {
                      width: 100% !important;
                    }
                  `}
                </style>
                <div className="flex-fill">
                  <CheckboxFilter
                    label="Filtra per stato"
                    items={this.maintenances_status}
                    filteredItems={filtered_statuses}
                    onChangeFilter={this.onChangeFilter('filtered_statuses')}
                    disabled={this.props.show_form}
                  />
                </div>
                <div className="flex-fill">
                  <CheckboxFilter
                    label="Filtra per tipo"
                    items={maintenance_types}
                    filteredItems={filtered_types}
                    onChangeFilter={this.onChangeFilter('filtered_types')}
                    disabled={this.props.show_form}
                  />
                </div>
              </div>
              <div className="flex-fill">
                <SearchButton
                  value={filter}
                  onChange={this.onFilterChange}
                  disabled={show_form}
                  className="float-md-end"
                />
              </div>
            </div>
          </div>

          <ResponsiveTable
            ref={this.tableRef}
            className="flex-fill"
            data={maintenances}
            getData={this.getData}
            totalRecords={total}
            columns={this.columns}
            showMenu={show_form}
            menuWidth="40rem"
            menuContent={menuContent}
            menuContentProps={menuContentProps}
            rowClasses={rowData => (rowData.enabled ? '' : `text-muted`)}
            filter={filter}
          />
        </div>

        <Modal backdrop="static" centered show={show_modal} onHide={this.onCancel}>
          <Modal.Header>
            <Modal.Title>Elimina manutenzione</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Per procedere all'eliminazione della manutenzione, cliccare sul bottone "Elimina"</p>
            <p>
              <b>Attenzione</b>: tutti i documenti associati verrano eliminati
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.onCancel} variant="secondary">
              Annulla
            </Button>
            <Button onClick={this.onConfirmDelete} variant="danger">
              Elimina
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}
export default connect(mstp)(Maintenances)
