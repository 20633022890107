import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { bo_caps } from 'src/lib/constants'
import { error, success } from 'src/components/system_wide/notification'
import axios from 'src/lib/axios'

const initialState = {
  total: 0,
  users: [],
  show_form: false,
  current_user: {
    id: null,
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    enabled: true,
    caps: bo_caps,
    is_management_account: false,
    stores: [],
  },
}

export const getUsers = createAsyncThunk('users/getUsers', async ({ offset, limit, sortData, filter }, thunkApi) => {
  try {
    let { total: currentTotal } = thunkApi.getState().users
    if (offset > currentTotal) return

    let { sort_column, sort_direction } = sortData
    let response = await axios({
      url: `/api/users`,
      params: { offset, limit, sort_column, sort_direction, filter },
    })
    let { total, users } = response.data
    return { total, users, reset: offset === 0 }
  } catch (err) {
    error('Impossibile recuperare lista utenti')
    return thunkApi.rejectWithValue()
  }
})

export const createUser = createAsyncThunk('users/createUser', async ({ user }, thunkApi) => {
  try {
    let { username, first_name, last_name, email, enabled, caps, is_management_account } = user
    await axios({
      url: `/api/user`,
      method: 'post',
      data: { username, first_name, last_name, email, enabled, caps, is_management_account },
    })
    success('Utente creato con successo')
    thunkApi.dispatch(resetCurrentUser())
    thunkApi.dispatch(toggleShowForm())
  } catch (err) {
    error('Impossibile creare utente')
    return thunkApi.rejectWithValue()
  }
})

export const updateUser = createAsyncThunk('users/updateUser', async ({ user }, thunkApi) => {
  try {
    let { id, username, first_name, last_name, email, enabled, caps, is_management_account } = user
    await axios({
      url: `/api/user/${id}`,
      method: 'patch',
      data: { username, first_name, last_name, email, enabled, caps, is_management_account },
    })
    success('Utente aggiornato con successo')
    thunkApi.dispatch(resetCurrentUser())
    thunkApi.dispatch(toggleShowForm())
  } catch (err) {
    error('Impossibile aggiornare utente')
    return thunkApi.rejectWithValue()
  }
})

export const saveAssociatedStores = createAsyncThunk('users/saveAssociatedStores', async ({ user }, thunkApi) => {
  try {
    let { id: user_id, stores } = user
    await axios({
      url: `/api/user/associate`,
      method: 'patch',
      data: { user_id, stores },
    })
    success('Utente associato ai punti vendita con successo')
    thunkApi.dispatch(resetCurrentUser())
    thunkApi.dispatch(toggleShowForm())
  } catch (err) {
    error('Impossibile associare utente ai punti vendita')
    return thunkApi.rejectWithValue()
  }
})

export const deleteUser = createAsyncThunk('users/deleteUser', async ({ id }, thunkApi) => {
  try {
    await axios({ url: `/api/user/${id}`, method: 'delete' })
    success('Utente eliminato con successo')
    thunkApi.dispatch(resetCurrentUser())
  } catch (err) {
    error('Impossibile eliminare utente')
    return thunkApi.rejectWithValue()
  }
})

export const usersSlice = createSlice({
  name: 'users',
  initialState,

  reducers: {
    setCurrentUser: (state, action) => {
      let { id, username, first_name, last_name, email, enabled, caps, is_management_account, stores } = action.payload
      state.current_user.id = id
      state.current_user.username = username
      state.current_user.email = email
      state.current_user.first_name = first_name
      state.current_user.last_name = last_name
      state.current_user.enabled = enabled
      state.current_user.caps = caps
      state.current_user.is_management_account = is_management_account
      state.current_user.stores = stores
    },
    toggleShowForm: state => {
      state.show_form = !state.show_form
    },
    resetCurrentUser: state => {
      state.current_user = initialState.current_user
    },
    resetUsers: () => initialState,
  },

  extraReducers: {
    [getUsers.fulfilled]: (state, action) => {
      if (!action?.payload) return
      let { total, users } = action.payload
      state.total = total
      state.users = action.payload.reset ? [...users] : [...state.users, ...users]
    },
    [getUsers.rejected]: state => {
      state.total = initialState.total
      state.users = initialState.users
    },
  },
})

export const { setCurrentUser, resetCurrentUser, toggleShowForm, resetUsers } = usersSlice.actions
export default usersSlice.reducer
