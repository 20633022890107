import * as React from 'react'
import { Button, Form, Alert } from 'react-bootstrap'
import PoweredBy from './powered_by'
import { sendVerifyEmail } from 'src/store/login'
import styles from 'src/components/system_wide/login_form/login_form.module.css'

class VerifyEmailVariant extends React.Component {
  handleSendVerifyEmail = () => {
    let { username } = this.props.loginData
    this.props.dispatch(sendVerifyEmail({ tenant: this.props.tenant, username }))
  }

  render() {
    let { message_type, message, loading } = this.props.loginData

    return (
      <div className={`card ${styles.content}`}>
        <div className="card-body">
          <div className="d-flex flex-row gap-2 align-items-center mb-2 text-center">
            {/* {<img src={logo} alt="Tenant logo" className={styles.tenant_logo} />} */}
          </div>
          <div className="mb-5">
            <h4 className="mb-3 text-center">Conferma la tua email</h4>
          </div>
          <Alert variant={message_type} show={message !== ''} onClose={this.resetMessage}>
            {message}
          </Alert>
          <Form>
            <div className="text-muted mt-2">
              To continue you have to confirm that your email is working correctly.
              <br />
              <br />
              Please check your mailbox and follow reported instructions.
            </div>
            <div className="mt-5 mb-2">
              <Button
                style={{ minWidth: '6rem' }}
                color="primary"
                size="md"
                disabled={loading === true}
                onClick={this.handleSendVerifyEmail}>
                {loading === true && (
                  <>
                    <span className="spinner-border spinner-border-sm"></span> Send a confirmation email
                  </>
                )}
                {loading === false && 'send_button'}
              </Button>
              <PoweredBy />
            </div>
          </Form>
        </div>
      </div>
    )
  }
}

export default VerifyEmailVariant
