import React from 'react'
import { connect } from 'react-redux'
import { Modal, Button, Alert } from 'react-bootstrap'

import ActionsDropdown from 'src/components/system_wide/actions_dropdown'
import SearchButton from 'src/components/system_wide/search_button'
import ResponsiveTable from 'src/components/system_wide/responsive_table'
import DocumentsForm from 'src/components/system_wide/documents_form'
import SetupCostForm from 'src/components/drugstore/tenant_management/setup_costs/setup_cost_form'
import {
  setCurrentSetupCost,
  createSetupCost,
  updateSetupCost,
  deleteSetupCost,
  toggleShowForm,
  getSetupCosts,
  resetCurrentSetupCost,
  resetSetupCosts,
} from 'src/store/setup_costs'

const mstp = state => {
  let { name: tenant_name } = state.storeManager.current_tenant
  let { documents, total: total_documents } = state.documents
  let { total, setup_costs, show_form, current_setup_cost } = state.setupCosts
  return { tenant_name, total, setup_costs, show_form, current_setup_cost, documents, total_documents }
}

class SetupCosts extends React.Component {
  state = {
    show_modal: false,
    filter: '',
    mode: 'edit',
  }

  tableRef = null

  constructor(props) {
    super(props)
    this.tableRef = React.createRef()
  }

  actions = [
    {
      icon: 'fa-edit',
      text: 'Modifica',
      onClick: rowData => {
        let { id, description, costs } = rowData
        this.setState({ mode: 'edit' })
        this.props.dispatch(setCurrentSetupCost({ id, description, costs }))
        this.props.dispatch(toggleShowForm())
      },
    },
    {
      icon: 'fa-folder-open',
      text: 'Documenti',
      onClick: rowData => {
        let { id, description, costs } = rowData
        this.setState({ mode: 'document' })
        this.props.dispatch(setCurrentSetupCost({ id, description, costs }))
        this.props.dispatch(toggleShowForm())
      },
    },
    {
      icon: 'fa-trash-alt',
      text: 'Elimina',
      onClick: rowData => {
        let { id, description, costs } = rowData
        this.props.dispatch(setCurrentSetupCost({ id, description, costs }))
        this.setState({ show_modal: true })
      },
    },
  ]

  actionsRenderer = ({ rowData }) => {
    return <ActionsDropdown rowData={rowData} actions={this.actions} />
  }

  columns = [
    {
      title: '',
      className: 'd-lg-block text-center',
      style: { width: '6%', minWidth: '3rem' },
      data: this.actionsRenderer,
    },
    {
      title: 'Descrizione',
      className: 'd-lg-block flex-fill',
      style: { width: '60%' },
      sortable: true,
      data: 'description',
    },
    {
      title: 'Costo',
      className: 'd-none d-lg-block flex-fill',
      style: { width: '10%' },
      sortable: true,
      data: 'costs',
    },
    {
      title: 'Creato/Aggiornato da',
      className: 'd-none d-lg-block text-center',
      style: { width: '15%' },
      data: ({ rowData }) => `${rowData?.created_by ?? '-'}/${rowData?.updated_by ?? '-'}`,
    },
  ]

  onFilterChange = filter => this.setState({ filter })

  getData = async (index = 0, chunkSize, sortData, filter) => {
    await this.props.dispatch(
      getSetupCosts({ tenant: this.props.tenant_name, offset: index, limit: chunkSize, sortData, filter })
    )
  }

  saveSetupCost = async setup_cost => {
    setup_cost.id
      ? await this.props.dispatch(updateSetupCost({ tenant: this.props.tenant_name, setup_cost }))
      : await this.props.dispatch(createSetupCost({ tenant: this.props.tenant_name, setup_cost }))
    this.tableRef.current.refreshData()
  }

  onToggleForm = () => {
    if (this.props.show_form === true) {
      this.props.dispatch(resetCurrentSetupCost())
    }
    this.props.dispatch(toggleShowForm())
  }

  onCancel = () => {
    this.props.dispatch(resetCurrentSetupCost())
    this.setState({ show_modal: false })
  }

  onConfirmDelete = async () => {
    await this.props.dispatch(deleteSetupCost({ tenant: this.props.tenant_name, id: this.props.current_setup_cost.id }))
    this.tableRef.current.refreshData()
    this.setState({ show_modal: false })
  }

  componentDidMount() {}

  async componentDidUpdate(prevProps) {
    if (prevProps.tenant_name !== this.props.tenant_name) {
      if (this.props.tenant_name) {
        this.props.dispatch(resetSetupCosts())
        await this.getData()
      }
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetSetupCosts())
  }

  render() {
    let { mode, filter, show_modal } = this.state
    let { tenant_name, documents, total_documents, setup_costs, total, current_setup_cost, show_form } = this.props
    let menuContent = SetupCostForm
    let menuContentProps = {
      setup_cost: current_setup_cost,
      onSave: this.saveSetupCost,
      closeForm: this.onToggleForm,
    }
    if (mode === 'document') {
      menuContent = DocumentsForm
      menuContentProps = {
        tenant: tenant_name,
        documents: documents,
        total: total_documents,
        type: 'setup_cost',
        type_id: current_setup_cost?.id,
        closeForm: this.onToggleForm,
        dispatch: this.props.dispatch,
      }
    }
    if (!tenant_name) {
      return (
        <div className="container-fluid flex-fill d-flex flex-column px-0 ps-lg-3">
          <Alert variant="info">Seleziona punto vendita</Alert>
        </div>
      )
    }
    return (
      <div className="container-fluid flex-fill d-flex flex-column px-0 ps-lg-3">
        <div className="d-flex flex-column flex-fill">
          <div className="container-fluid px-0 mb-2">
            <div className="d-flex flex-wrap gap-2">
              <div className="flex-fill flex-sm-grow-0 flex-sm-shrink-0">
                <button className="btn btn-primary btn-block w-100" onClick={this.onToggleForm} disabled={show_form}>
                  <i className="fas fa-plus fa-fw text-start" />
                  Aggiungi costo allestimento
                </button>
              </div>
              <div className="flex-fill">
                <SearchButton
                  value={filter}
                  onChange={this.onFilterChange}
                  disabled={show_form}
                  className="float-md-end"
                />
              </div>
            </div>
          </div>
          <ResponsiveTable
            ref={this.tableRef}
            className="flex-fill"
            data={setup_costs}
            getData={this.getData}
            totalRecords={total}
            columns={this.columns}
            showMenu={show_form}
            menuWidth="40rem"
            menuContent={menuContent}
            menuContentProps={menuContentProps}
            rowClasses={rowData => (rowData.enabled ? '' : `text-muted`)}
            filter={this.state.filter}
          />
        </div>

        <Modal backdrop="static" centered show={show_modal} onHide={this.onCancel}>
          <Modal.Header>
            <Modal.Title>Elimina costo allestimento</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Per procedere all'eliminazione del costo dell'allestimento, cliccare sul bottone "Elimina"</p>
            <p>
              <b>Attenzione</b>: tutti i documenti associati verrano eliminati
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.onCancel} variant="secondary">
              Annulla
            </Button>
            <Button onClick={this.onConfirmDelete} variant="danger">
              Elimina
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}
export default connect(mstp)(SetupCosts)
