import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import isStrongPassword from 'validator/lib/isStrongPassword'

import axios from '../lib/axios'
import { updateUserinfo } from './userinfo'
import { success } from 'src/components/system_wide/notification'

// Initial state
export const initialState = {
  username: '',
  password: '',
  loading: false,

  show_verify_email: false,
  show_update_password: false,
  show_forgot_password: false,

  new_password: '',
  confirm_password: '',
  is_weak_password: false,

  username_or_email: '',

  message_type: 'success',
  message: '',
}

export const logout = createAsyncThunk('login/logout', async thunkApi => {
  try {
    await axios({ url: `/api/logout` })
    thunkApi.dispatch(loginSlice.actions.resetMessage())
  } catch (err) {
    return thunkApi.rejectWithValue()
  }
})

export const sendVerifyEmail = createAsyncThunk('login/sendVerifyEmail', async ({ tenant, username }, thunkApi) => {
  try {
    thunkApi.dispatch(loginSlice.actions.setLoading(true))
    await axios({ url: `/api/send_verify_email`, method: 'post', data: { tenant, username } })
    success('Email inviata con successo')
    return { message: '', message_type: 'success', loading: false }
  } catch (err) {
    return thunkApi.rejectWithValue({
      message: 'Impossibile inviare un email di verifica',
      message_type: 'danger',
      loading: false,
    })
  }
})

//loginData: {username, password, tenant}
export const tryLogin = createAsyncThunk('login/tryLogin', async (data, thunkApi) => {
  try {
    thunkApi.dispatch(loginSlice.actions.resetMessage())
    thunkApi.dispatch(loginSlice.actions.setLoading(true))

    let response = await axios({ url: '/api/login', method: 'post', data })
    let {
      id,
      required_actions,
      username,
      email,
      first_name,
      last_name,
      tenant,
      is_management_account,
      caps,
      business_role_id,
    } = response.data
    let hasRequiredActions = required_actions.length > 0

    if (!hasRequiredActions) {
      thunkApi.dispatch(
        updateUserinfo({
          id,
          username,
          email,
          first_name,
          last_name,
          tenant,
          is_management_account,
          caps,
          business_role_id,
        })
      )
    }
    return {
      show_verify_email: required_actions.includes('verify_email'),
      show_update_password: required_actions.includes('update_pwd'),
      loading: false,
    }
  } catch (err) {
    return thunkApi.rejectWithValue({
      loading: false,
      message_type: 'danger',
      message: 'Nome utente o password errati',
    })
  }
})

export const tryUpdatePasswordUsername = createAsyncThunk(
  'login/tryUpdatePasswordUsername',
  async ({ new_password, confirm_password, username }, thunkApi) => {
    try {
      thunkApi.dispatch(loginSlice.actions.resetMessage())
      if (!isStrongPassword(new_password, { minSymbols: 0 })) {
        return thunkApi.rejectWithValue({
          is_weak_password: true,
          message_type: 'danger',
          message: 'Password troppo debole',
        })
      }

      username = username || thunkApi.getState().loginData.username
      thunkApi.dispatch(loginSlice.actions.setLoading(true))
      let response = await axios({
        url: `/api/${username}/update_pwd`,
        method: 'patch',
        data: { new_password, confirm_password, username },
      })
      success('Password aggiornata con successo')
      let { id, required_actions, email, first_name, last_name, caps, business_role_id, is_management_account } =
        response.data
      let hasRequiredActions = required_actions.length > 0
      if (!hasRequiredActions) {
        thunkApi.dispatch(
          updateUserinfo({
            id,
            username,
            email,
            first_name,
            last_name,
            caps,
            business_role_id,
            is_management_account,
          })
        )
      }
      return {
        show_verify_email: required_actions.includes('verify_email'),
        show_update_password: required_actions.includes('update_pwd'),
        loading: false,
        status: 'success',
      }
    } catch (err) {
      console.log(err)
      return thunkApi.rejectWithValue({
        loading: false,
        message_type: 'danger',
        message: 'Fallito aggiornamento password',
        status: 'rejected',
      })
    }
  }
)

export const tryUpdatePassword = createAsyncThunk(
  'login/tryUpdatePassword',
  async ({ new_password, confirm_password, username }, thunkApi) => {
    try {
      thunkApi.dispatch(loginSlice.actions.resetMessage())
      if (!isStrongPassword(new_password, { minSymbols: 0 })) {
        return thunkApi.rejectWithValue({
          is_weak_password: true,
          message_type: 'danger',
          message: 'Password troppo debole',
        })
      }

      username = username || thunkApi.getState().loginData.username
      thunkApi.dispatch(loginSlice.actions.setLoading(true))
      let response = await axios({
        url: `/api/update_pwd`,
        method: 'patch',
        data: { new_password, confirm_password, username },
      })
      success('Password aggiornata con successo')
      let { id, required_actions, email, first_name, last_name, caps, business_role_id, is_management_account } =
        response.data
      let hasRequiredActions = required_actions.length > 0
      if (!hasRequiredActions) {
        thunkApi.dispatch(
          updateUserinfo({
            id,
            username,
            email,
            first_name,
            last_name,
            caps,
            business_role_id,
            is_management_account,
          })
        )
      }
      return {
        show_verify_email: required_actions.includes('verify_email'),
        show_update_password: required_actions.includes('update_pwd'),
        loading: false,
        status: 'success',
      }
    } catch (err) {
      console.log(err)
      return thunkApi.rejectWithValue({
        loading: false,
        message_type: 'danger',
        message: 'Fallito aggiornamento password',
        status: 'rejected',
      })
    }
  }
)

export const submitForgotPassword = createAsyncThunk(
  'login/submitForgotPassword',
  async ({ username_or_email }, thunkApi) => {
    try {
      thunkApi.dispatch(loginSlice.actions.resetMessage())
      thunkApi.dispatch(loginSlice.actions.setLoading(true))
      await axios({ url: `/api/forgot_password`, method: 'post', data: { username_or_email } })
      return {
        username_or_email: '',
        loading: false,
        message_type: 'success',
        message: "Riceverete a breve un'e-mail con le istruzioni per la creazione di una nuova password.",
      }
    } catch (err) {
      return thunkApi.rejectWithValue()
    }
  }
)

export const loginSlice = createSlice({
  name: 'login',
  initialState,

  reducers: {
    // payload = <Object> {name, value}
    updateFormData: (state, action) => {
      let { name, value } = action.payload
      state[name] = value
      state.is_weak_password = false
      state.message = ''
      state.message_type = 'success'
    },

    // payload = <Boolean>
    setLoading: (state, action) => {
      state.loading = action.payload
    },

    toggleShowForgotPassword: state => {
      state.show_forgot_password = !state.show_forgot_password
      state.message = ''
      state.message_type = 'success'
    },

    resetMessage: state => {
      state.message = ''
      state.message_type = 'success'
    },

    resetLoginData: () => {
      return initialState
    },
  },

  extraReducers: {
    [sendVerifyEmail.fulfilled]: (state, action) => {
      let { message, message_type, loading, send_button_disabled } = action.payload
      state.message = message
      state.message_type = message_type
      state.loading = loading
      state.send_button_disabled = send_button_disabled
    },
    [sendVerifyEmail.rejected]: (state, action) => {
      let { message, message_type, loading, send_button_disabled } = action.payload
      state.message = message
      state.message_type = message_type
      state.loading = loading
      state.send_button_disabled = send_button_disabled
    },
    [tryLogin.fulfilled]: (state, action) => {
      let { show_verify_email, show_update_password, loading } = action.payload
      state.show_verify_email = show_verify_email
      state.show_update_password = show_update_password
      state.loading = loading
    },
    [tryLogin.rejected]: (state, action) => {
      let { message, message_type, loading } = action.payload
      state.message = message
      state.message_type = message_type
      state.loading = loading
    },
    [tryUpdatePasswordUsername.fulfilled]: (state, action) => {
      let { show_verify_email, show_update_password, loading } = action.payload
      state.show_verify_email = show_verify_email
      state.show_update_password = show_update_password
      state.loading = loading
      state.new_password = ''
      state.confirm_password = ''
      state.is_weak_password = false
    },
    [tryUpdatePasswordUsername.rejected]: (state, action) => {
      let { message, message_type, loading = false, is_weak_password = false } = action.payload
      state.loading = loading
      state.message = message
      state.message_type = message_type
      state.is_weak_password = is_weak_password
    },
    [tryUpdatePassword.fulfilled]: (state, action) => {
      let { show_verify_email, show_update_password, loading } = action.payload
      state.show_verify_email = show_verify_email
      state.show_update_password = show_update_password
      state.loading = loading
      state.new_password = ''
      state.confirm_password = ''
      state.is_weak_password = false
    },
    [tryUpdatePassword.rejected]: (state, action) => {
      let { message, message_type, loading = false, is_weak_password = false } = action.payload
      state.loading = loading
      state.message = message
      state.message_type = message_type
      state.is_weak_password = is_weak_password
    },
    [submitForgotPassword.fulfilled]: (state, action) => {
      let { message, message_type, loading, username_or_email } = action.payload
      state.loading = loading
      state.message = message
      state.message_type = message_type
      state.username_or_email = username_or_email
    },
  },
})

export const { updateFormData, setLoading, toggleShowForgotPassword, resetMessage, resetLoginData } = loginSlice.actions
export default loginSlice.reducer
