import React from 'react'

import HorizontalSidebar from 'src/components/system_wide/horizontal_sidebar'

class TenantManagerSidebar extends React.Component {
  sidebarItems = [
    {
      name: 'tenant_list',
      icon: 'fas fa-store',
    },
    {
      name: 'point_of_sale_data',
      text: 'Dati anagrafici',
      icon: 'fas fa-address-card',
      pathname: '/drugstore/[store_name]/point_of_sale_data',
      caps: { has: ['bo_show_point_of_sale_data'] },
    },
    {
      name: 'manage_point_of_sale_data',
      text: 'Gestore',
      icon: 'fas fa-address-card',
      pathname: '/drugstore/[store_name]/manage_point_of_sale_data',
      caps: { has: ['bo_show_manager_point_of_sale_data'] },
    },
    {
      name: 'setup_costs',
      text: 'Spese allestimento',
      icon: 'fas fa-person-digging',
      pathname: '/drugstore/[store_name]/setup_costs',
      caps: { has: ['bo_show_setup_costs'] },
    },
    {
      name: 'maintenance',
      text: 'Manutenzioni',
      icon: 'fas  fa-helmet-safety',
      pathname: '/drugstore/[store_name]/maintenances',
      caps: { has: ['bo_show_maintenances'] },
    },
    {
      name: 'deadline',
      text: 'Scadenze',
      icon: 'fas fa-clock',
      pathname: '/drugstore/[store_name]/deadlines',
      caps: { has: ['bo_show_deadlines'] },
    },
    {
      name: 'sales',
      text: 'Fatturato',
      icon: 'fas fa-money-bill-trend-up',
      pathname: '/drugstore/[store_name]/sales',
      caps: { has: ['bo_show_sales'] },
    },
    // {
    //   name: 'backup_tenant',
    //   text: 'Backup e ripristino',
    //   icon: 'fas fa-save',
    //   pathname: '/drugstore/[store_name]/backup_tenant',
    // },
    {
      name: 'remove',
      text: 'Elimina',
      icon: 'fas fa-trash',
      pathname: '/drugstore/[store_name]/remove_tenant',
      caps: { has: ['bo_delete_tenant'] },
    },
  ]

  render() {
    return <HorizontalSidebar sidebarItems={this.sidebarItems} {...this.props} />
  }
}
export default TenantManagerSidebar
