import React from 'react'
import { Button, Form } from 'react-bootstrap'
import hash from 'object-hash'
import isEmail from 'validator/lib/isEmail'

import { bo_caps, getConstants } from 'src/lib/constants'

const usernameRegExp = /^[a-z0-9_-]*$/

class UserForm extends React.Component {
  state = {
    id: null,
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    enabled: true,
    caps: bo_caps,
    is_management_account: false,

    user_hash: '',
  }

  initialHash = ''
  boCaps = getConstants().bo_caps

  constructor(props) {
    super(props)
    if (props.user.id) {
      let { id, username, first_name, last_name, email, enabled, caps, is_management_account } = props.user
      this.state.id = id
      this.state.username = username
      this.state.first_name = first_name
      this.state.last_name = last_name
      this.state.email = email
      this.state.enabled = enabled
      this.state.caps = caps
      this.state.is_management_account = is_management_account
    }
    // eslint-disable-next-line no-unused-vars
    let { user_hash, ...rest } = this.state
    this.initialHash = hash(rest)
    this.state.user_hash = this.initialHash
  }

  updateHash = () => {
    // eslint-disable-next-line no-unused-vars
    let { user_hash, ...rest } = this.state
    this.setState({ user_hash: hash(rest) })
  }

  handleChange = ({ target: { name, value } }) => {
    if (name === 'username' && !usernameRegExp.test(value)) return
    this.setState({ [name]: value }, () => this.updateHash())
  }

  handleChangeEnabled = ({ target: { checked } }) => {
    this.setState({ enabled: !checked }, () => this.updateHash())
  }

  handleChangeIsManagementAccount = ({ target: { checked } }) => {
    this.setState({ is_management_account: checked }, () => this.updateHash())
  }
  handleChangeCaps = ({ target: { name, checked } }) => {
    this.setState({ caps: { ...this.state.caps, [name]: checked } }, () => this.updateHash())
  }

  handleConfirm = () => {
    let { user_hash, is_management_account, caps, ...rest } = this.state
    if (is_management_account === true) {
      caps = Object.keys(this.boCaps).reduce((acc, c) => ({ ...acc, [c]: true }), {})
    }

    this.props.onSave({ ...rest, is_management_account, caps })
  }

  isFormValid = () => {
    let { username, email } = this.state
    return username !== '' && email !== '' && isEmail(email)
  }

  renderCaps = () => {
    let items = []
    for (const c in this.boCaps) {
      items.push(
        <Form.Check
          key={c}
          type="checkbox"
          id={c}
          name={c}
          checked={this.state.caps[c] === true}
          label={this.boCaps[c]}
          onChange={this.handleChangeCaps}
        />
      )
    }
    return items
  }

  render() {
    let title = this.state.id ? 'Modifica utente' : 'Crea utente'
    let { username, first_name, last_name, email, is_management_account, enabled } = this.state
    return (
      <>
        <h4 className="border-bottom text-primary">{title}</h4>
        <div className="flex-fill position-relative">
          <div className="ps-1 position-absolute row">
            <Form.Group className="mt-3 mb-3 col-12 col-md-6">
              <Form.Label>Username</Form.Label>
              <span> *</span>
              <Form.Control name="username" placeholder="Username" value={username} onChange={this.handleChange} />
              <Form.Text id="usernameHelp" muted>
                Caratteri permessi: a-z,,0-9,_,-
              </Form.Text>
            </Form.Group>
            <Form.Group className="mt-3 mb-3 col-12 col-md-6">
              <Form.Label>Email</Form.Label>
              <span> *</span>
              <Form.Control name="email" placeholder="Email" value={email} onChange={this.handleChange} />
            </Form.Group>
            <Form.Group className="mb-3 col-12 col-md-6">
              <Form.Label>Nome</Form.Label>
              <Form.Control name="first_name" placeholder="Nome" value={first_name} onChange={this.handleChange} />
            </Form.Group>
            <Form.Group className="mb-3 col-12 col-md-6">
              <Form.Label>Cognome</Form.Label>
              <Form.Control name="last_name" placeholder="Cognome" value={last_name} onChange={this.handleChange} />
            </Form.Group>
            {/* <Form.Group className="mb-3">
              <Form.Label as="h5">Permessi</Form.Label>
              {this.renderCaps()}
            </Form.Group> */}
            {this.props.is_management_account === true && (
              <Form.Group className="mb-3">
                <Form.Label as="h5">Tipo di utente</Form.Label>
                <Form.Check
                  type="checkbox"
                  id="is_management_account"
                  name="is_management_account"
                  checked={is_management_account === true}
                  label="Utente amministratore"
                  onChange={this.handleChangeIsManagementAccount}
                />
              </Form.Group>
            )}
            <Form.Group className="mb-3">
              <Form.Label as="h5">Blocca login utente</Form.Label>
              <Form.Check
                type="switch"
                id="enabled"
                name="enabled"
                checked={enabled === false}
                label="Blocca accesso"
                onChange={this.handleChangeEnabled}
              />
              <Form.Text id="enabledHelp" muted>
                Abilitando il flag si blocca il login dell'utente
              </Form.Text>
            </Form.Group>
            {is_management_account === false && (
              <Form.Group className="mb-3">
                <Form.Label as="h5">Permessi</Form.Label>
                {this.renderCaps()}
              </Form.Group>
            )}
          </div>
        </div>
        <div className="border-top pt-2">
          <Button
            className="float-end"
            variant="primary"
            disabled={!this.isFormValid() || this.initialHash === this.state.user_hash}
            onClick={this.handleConfirm}>
            Conferma
          </Button>
          <Button className="float-end me-2" variant="secondary" onClick={this.props.closeForm}>
            Annulla
          </Button>
        </div>
      </>
    )
  }
}
export default UserForm
