import { Store as notificationStore } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
export const success = message => {
  notificationStore.addNotification({
    title: 'Success',
    message,
    type: 'success',
    container: 'top-right',
    dismiss: {
      duration: 2500,
      onScreen: true,
      pauseOnHover: true,
    },
  })
}
export const error = message => {
  // if (store.getState().setting.tenant !== '') {
  notificationStore.addNotification({
    title: 'Error',
    message,
    type: 'danger',
    container: 'top-right',
    dismiss: {
      duration: 2500,
      onScreen: true,
      pauseOnHover: true,
    },
  })
  // }
}

const Notification = { success, error }
export default Notification
