import React from 'react'
import { Button } from 'react-bootstrap'
import { format, parseISO } from 'date-fns'
import ResponsiveTable from 'src/components/system_wide/responsive_table'
import { getDeadlinesProcessed } from 'src/store/deadlines'
import 'react-datepicker/dist/react-datepicker.css'

class DeadlineProcessed extends React.Component {
  static defaultProps = {
    tenant: '',
    deadlinesProcessed: [],
    deadlineTotal: 0,
    dispatch: () => {},
  }

  columns = [
    {
      title: '#',
      className: 'd-lg-block text-center',
      style: { width: '6%', minWidth: '3rem' },
      data: 'id',
    },
    {
      title: 'Destinatari',
      className: 'd-none d-lg-block flex-fill',
      style: { width: '40%' },
      sortable: true,
      data: ({ rowData: { recipient } }) => {
        return <div className="text-truncate">{recipient}</div>
      },
    },
    {
      title: 'Notificato',
      className: 'd-none d-lg-block',
      style: { width: '40%' },
      data: ({ rowData: { notified_at } }) => {
        return format(parseISO(notified_at), 'dd-MM-yyyy')
      },
    },
  ]

  getData = async (index = 0, chunkSize, sortData, filter) => {
    await this.props.dispatch(
      getDeadlinesProcessed({
        id: this.props.deadline.id,
        tenant: this.props.tenant,
        offset: index,
        limit: chunkSize,
        sortData,
        filter,
      })
    )
  }

  render() {
    return (
      <>
        <h4 className="border-bottom text-primary">Scadenze processate</h4>
        <div className="flex-fill position-relative overflow-auto">
          <div className="w-100 ps-1 position-absolute" style={{ height: '100%' }}>
            <div className="d-flex flex-row" style={{ height: '100%' }}>
              <style jsx="true" global="true">{`
                .responsive_table_header,
                .responsive_table_footer {
                  background-color: white !important;
                }
              `}</style>
              <ResponsiveTable
                className="flex-fill"
                data={this.props.deadlinesProcessed}
                getData={this.getData}
                totalRecords={this.props.deadlineTotal}
                columns={this.columns}
                menuWidth="40rem"
                rowClasses={rowData => (rowData.enabled ? '' : `text-muted`)}
              />
            </div>
          </div>
        </div>
        <div className="border-top pt-2">
          <Button className="float-end me-2" variant="secondary" onClick={this.props.closeForm}>
            Annulla
          </Button>
        </div>
      </>
    )
  }
}
export default DeadlineProcessed
