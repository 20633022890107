import React from 'react'
import { Outlet } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { Helmet } from 'react-helmet-async'
import { withRouter } from 'src/lib/router/with_router'
import AdminHeader from 'src/components/drugstore/header'
import { ReactNotifications } from 'react-notifications-component'
import Footer from 'src/components/system_wide/footer'
import ThemeContext from 'src/components/system_wide/theme_context'
import { resetCurrentTenant, setCurrentTenant } from 'src/store/store_manager'
import store from 'src/store'
import AppLoading from 'src/components/app_loading'

class NLApp extends React.Component {
  state = { ready: false, tenant: 'strugstore' }

  componentDidMount() {
    let { location, navigate, params } = this.props.router
    let tenant = params?.store_name ?? 'drugstore'
    //location.pathname.split('/')?.[1] || 'drugstore'
    if (tenant === 'drugstore') {
      store.dispatch(resetCurrentTenant())
    } else {
      store.dispatch(setCurrentTenant(tenant))
    }
    this.setState({ ready: true })
    if (!location.pathname.split('/')?.[1]) return setTimeout(() => navigate(`/${tenant}`, { replace: true }))
  }

  componentDidCatch(error, info) {
    console.log('error: ', error)
    console.log('info: ', info)
  }

  render() {
    let { location } = this.props.router
    let is_login = location?.pathname?.includes('login') ?? false

    return (
      <>
        <HelmetProvider>
          <Helmet>
            <link rel="icon" href="/favicon.png" />
            <link rel="SHORTCUT ICON" href="/favicon.png" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="theme-color" content="#000000" />
            <meta name="description" content="Vitulano servizi" />
            <link rel="apple-touch-icon" href="/favicon.png" />

            <link rel="manifest" href="/manifest.json" />
          </Helmet>
          <ThemeContext.Provider value={this.theme}>
            {this.state.ready === true && (
              <>
                <ReactNotifications />
                <AppLoading />
                {is_login === false && <AdminHeader containerSize={'fluid'} />}

                <main className={`layout pt-4 pb-2`}>
                  <Outlet {...this.props} />
                </main>
                <Footer containerSize={'fluid'} />
              </>
            )}
          </ThemeContext.Provider>
        </HelmetProvider>
      </>
    )
  }
}

export default withRouter(NLApp)
