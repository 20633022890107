import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'src/lib/router/with_router'
import { Button, Modal, Alert } from 'react-bootstrap'
import ActionsDropdown from 'src/components/system_wide/actions_dropdown'
import SearchButton from 'src/components/system_wide/search_button'
import ResponsiveTable from 'src/components/system_wide/responsive_table'
import ManagerEmployeeForm from 'src/components/drugstore/tenant_management/manager/manage_employee_form'

import {
  toggleShowForm,
  setCurrentEmployee,
  getEmpleyees,
  resetCurrentEmployee,
  createEmployee,
  updateEmployee,
  deleteEmployee,
  resetEmployees,
} from 'src/store/employees'

const mstp = state => {
  let { name: tenant_name } = state.storeManager.current_tenant
  let { employees, total, current_employee, show_form } = state.employees
  return { tenant_name, show_form, employees, total, current_employee }
}

class ManageEmployees extends React.Component {
  static defaultProps = {
    manager_id: null,
  }

  state = {
    show_modal: false,
    filter: '',
    mode: 'edit',
  }

  tableRef = null

  constructor(props) {
    super(props)
    this.tableRef = React.createRef()
  }

  actions = [
    {
      icon: 'fa-edit',
      text: 'Modifica',
      onClick: rowData => {
        let { id, name, surname, description, email, phone_1, phone_2, phone_3 } = rowData
        this.setState({ mode: 'edit' })
        this.props.dispatch(setCurrentEmployee({ id, name, surname, description, email, phone_1, phone_2, phone_3 }))
        this.props.dispatch(toggleShowForm())
      },
    },
    {
      icon: 'fa-trash-alt',
      text: 'Elimina',
      onClick: rowData => {
        let { id, name, surname, description, email, phone_1, phone_2, phone_3 } = rowData
        this.props.dispatch(setCurrentEmployee({ id, name, surname, description, email, phone_1, phone_2, phone_3 }))
        this.setState({ show_modal: true })
      },
    },
  ]

  actionsRenderer = ({ rowData }) => {
    return <ActionsDropdown rowData={rowData} actions={this.actions} />
  }

  columns = [
    {
      title: '',
      className: 'd-lg-block text-center',
      style: { width: '6%', minWidth: '3rem' },
      data: this.actionsRenderer,
    },
    {
      title: 'Nome',
      className: 'd-none d-lg-block flex-fill',
      style: { width: '15%' },
      sortable: true,
      data: ({ rowData: { name, surname } }) => `${surname} ${name}`,
    },
    {
      title: 'Descrizione',
      className: 'd-lg-block flex-fill',
      style: { width: '30%' },
      sortable: true,
      data: 'description',
    },
    {
      title: 'Email',
      className: 'd-lg-block flex-fill',
      style: { width: '14%' },
      sortable: true,
      data: 'email',
    },
    {
      title: 'Telefono',
      className: 'd-lg-block flex-fill',
      style: { width: '15%' },
      sortable: true,
      data: ({ rowData: { phone_1, phone_2, phone_3 } }) => {
        return (
          <>
            <div>{phone_1}</div>
            <div>{phone_2}</div>
            <div>{phone_3}</div>
          </>
        )
      },
    },
    {
      title: 'Creato/Aggiornato da',
      className: 'd-none d-lg-block text-center',
      style: { width: '20%' },
      data: ({ rowData }) => `${rowData?.created_by ?? '-'}/${rowData?.updated_by ?? '-'}`,
    },
  ]

  onFilterChange = filter => this.setState({ filter })

  getData = async (index = 0, chunkSize, sortData, filter) => {
    await this.props.dispatch(
      getEmpleyees({ tenant: this.props.tenant_name, offset: index, limit: chunkSize, sortData, filter })
    )
  }

  saveEmployee = async employee => {
    let { tenant_name: tenant, manager_id } = this.props
    employee.id
      ? await this.props.dispatch(updateEmployee({ tenant, employee, manager_id }))
      : await this.props.dispatch(createEmployee({ tenant, employee, manager_id }))
    this.tableRef.current.refreshData()
  }

  onToggleForm = () => {
    if (this.props.show_form === true) {
      this.props.dispatch(resetCurrentEmployee())
    }
    this.props.dispatch(toggleShowForm())
  }

  onCancel = () => {
    this.props.dispatch(resetCurrentEmployee())
    this.setState({ show_modal: false })
  }

  onConfirmDelete = async () => {
    await this.props.dispatch(deleteEmployee({ tenant: this.props.tenant_name, id: this.props.current_employee.id }))
    this.tableRef.current.refreshData()
    this.setState({ show_modal: false })
  }

  async componentDidMount() {
    await this.getData()
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.tenant_name !== this.props.tenant_name) {
      if (this.props.tenant_name) {
        this.props.dispatch(resetEmployees())
        await this.getData()
      }
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetEmployees())
  }

  render() {
    let { show_modal } = this.state
    let { tenant_name, employees, total, current_employee, show_form } = this.props

    if (!tenant_name) {
      return (
        <div className="container-fluid flex-fill d-flex flex-column px-0 ps-lg-3">
          <Alert variant="info">Seleziona punto vendita</Alert>
        </div>
      )
    }
    return (
      <div className="container-fluid flex-fill d-flex flex-column px-0 ps-lg-3">
        <div className="d-flex flex-column flex-fill">
          <div className="container-fluid px-0 mb-2">
            <div className="d-flex flex-wrap gap-2">
              <div className="flex-fill flex-sm-grow-0 flex-sm-shrink-0">
                <button className="btn btn-primary btn-block w-100" onClick={this.onToggleForm} disabled={show_form}>
                  <i className="fas fa-plus fa-fw text-start" />
                  Aggiungi contatto
                </button>
              </div>
            </div>
          </div>
          <ResponsiveTable
            ref={this.tableRef}
            className="flex-fill"
            data={employees}
            getData={this.getData}
            totalRecords={total}
            columns={this.columns}
            showMenu={show_form}
            menuWidth="40rem"
            menuContent={ManagerEmployeeForm}
            menuContentProps={{
              employee: current_employee,
              onSave: this.saveEmployee,
              closeForm: this.onToggleForm,
            }}
            rowClasses={rowData => (rowData.enabled ? '' : `text-muted`)}
          />
        </div>

        <Modal backdrop="static" centered show={show_modal} onHide={this.onCancel}>
          <Modal.Header>
            <Modal.Title>Elimina contatto</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Per procedere all'eliminazione di un dipendente, cliccare sul bottone "Elimina"</p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.onCancel} variant="secondary">
              Annulla
            </Button>
            <Button onClick={this.onConfirmDelete} variant="danger">
              Elimina
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}
export default connect(mstp)(withRouter(ManageEmployees))
