import React from 'react'
import withJWTSession from 'src/enhancers/jwt_session'
import ManagePointOfSaleData from 'src/components/drugstore/tenant_management/manager'

class ManagePointOfSaleDataPage extends React.Component {
  render() {
    return <ManagePointOfSaleData />
  }
}
export default withJWTSession({ invalid: '/drugstore/login' })(ManagePointOfSaleDataPage)
