import * as React from 'react'
import { connect } from 'react-redux'
import memoizeOne from 'memoize-one'
import store from 'src/store'

const mstp = (state, ownProps) => ({
  userInfo: state.userInfo,
  enabled_modules: [
    // ...state.tenantManager.current_tenant.configs.settings.modules,
    ...(ownProps?.enabled_modules ?? []),
  ],
})

const _checkCapabilities = memoizeOne(
  (caps = [], has = [], hasnt = [], some = [], propsHas = [], propsHasnt = [], propsSome = []) => {
    let _has = [...has, ...propsHas]
    let _hasnt = [...hasnt, ...propsHasnt]
    let _some = [...some, ...propsSome]

    if (_has.length + _hasnt.length + _some.length === 0) {
      return true
    } else {
      let canMountHas = _has.length ? _has.reduce((acc, c) => acc && caps[c] === true, true) : false
      let canMountHasnt = _hasnt.length ? _hasnt.reduce((acc, c) => acc && caps[c] === false, true) : false
      let canMountSome = _some.length ? _some.some(c => caps[c] === true) : false
      return canMountHas || canMountHasnt || canMountSome
    }
  }
)

const _checkModules = memoizeOne((enabled_modules, required_modules) => {
  if (required_modules.length === 0) {
    return true
  } else {
    return required_modules.reduce((hasSome, m) => enabled_modules.includes(m) || hasSome, false)
  }
})

export const checkModules = required_modules => {
  let { modules: enabled_modules } = store.getState().tenantManager.current_tenant.configs.settings
  return _checkModules(enabled_modules, required_modules)
}

export const checkCapabilities = (has = [], hasnt = [], some = [], propsHas = [], propsHasnt = [], propsSome = []) => {
  let { caps } = store.getState().userInfo
  return _checkCapabilities(caps, has, hasnt, some, propsHas, propsHasnt, propsSome)
}

// Dichiarative API (Component)
export const WithCapabilitiesWrapper = connect(mstp, null, null, { forwardRef: true })(props => {
  let {
    children,
    userInfo: { caps },
    has,
    hasnt,
    some,
    ignoreCaps = false,
    enabled_modules = [],
    required_modules = [],
  } = props
  let canMount = _checkCapabilities(caps, has, hasnt, some) || ignoreCaps === true
  let areModulesActive = _checkModules(enabled_modules, required_modules)
  return canMount && areModulesActive ? children : null
})

// HOC
export const withCapabilities =
  ({ has = [], hasnt = [], some = [], required_modules = [] }) =>
  Component => {
    return connect(mstp, null, null, { forwardRef: true })(props => {
      let canMount = _checkCapabilities(
        props.userInfo.caps,
        has,
        hasnt,
        some,
        props?.caps?.has,
        props?.caps?.hasnt,
        props?.caps?.some
      )
      let areModulesActive = _checkModules(props.enabled_modules, [
        ...required_modules,
        ...(props?.required_modules ?? []),
      ])
      return canMount && areModulesActive ? <Component {...props} /> : null
    })
  }

export default withCapabilities
