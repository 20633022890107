import React from 'react'
import { Button, Form } from 'react-bootstrap'
import hash from 'object-hash'

class AssociatedStoresForm extends React.Component {
  state = {
    id: null,
    stores: {},

    user_hash: '',
  }

  initialHash = ''

  constructor(props) {
    super(props)
    this.state.id = props.user.id
    this.state.stores = props.storesList.reduce(
      (acc, store) => ({ ...acc, [store.id]: { ...store, checked: props.user.stores.includes(store.id) } }),
      {}
    )
    // eslint-disable-next-line no-unused-vars
    let { user_hash, ...rest } = this.state
    this.initialHash = hash(rest)
    this.state.user_hash = this.initialHash
  }

  updateHash = () => {
    // eslint-disable-next-line no-unused-vars
    let { user_hash, ...rest } = this.state
    this.setState({ user_hash: hash(rest) })
  }

  handleChangeStores = ({ target: { id, checked } }) => {
    let stores = { ...this.state.stores }
    stores[id].checked = checked
    this.setState({ stores }, () => this.updateHash())
  }

  handleConfirm = () => {
    let { user_hash, ...rest } = this.state
    this.props.onSave(rest)
  }

  renderListAssignedStores = () => {
    let { stores } = this.state
    let items = []
    for (let id in stores) {
      items.push(
        <Form.Check
          key={id}
          type="checkbox"
          id={id}
          name={id}
          checked={stores[id].checked === true}
          label={stores[id].business_name}
          onChange={this.handleChangeStores}
        />
      )
    }
    return items
  }

  render() {
    return (
      <>
        <h4 className="border-bottom text-primary">Punti vendita</h4>
        <div className="flex-fill position-relative overflow-auto">
          <div className="position-absolute w-100 bg-white px-3 w-100" style={{ height: '100%' }}>
            <div className="d-flex flex-column" style={{ height: '100%' }}>
              <div className="d-flex">Lista dei punti vendita che l'utente può gestire.</div>
              <Form.Group className="mt-3">{this.renderListAssignedStores()}</Form.Group>
            </div>
          </div>
        </div>
        <div className="border-top pt-2">
          <Button
            className="float-end"
            variant="primary"
            disabled={this.initialHash === this.state.user_hash}
            onClick={this.handleConfirm}>
            Conferma
          </Button>
          <Button className="float-end me-2" variant="secondary" onClick={this.props.closeForm}>
            Annulla
          </Button>
        </div>
      </>
    )
  }
}
export default AssociatedStoresForm
