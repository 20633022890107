import React from 'react'
import { connect } from 'react-redux'

import withJWTSession from 'src/enhancers/jwt_session'
import Deadlines from 'src/components/drugstore/tenant_management/deadlines'
import withCapabilities from 'src/enhancers/with_capabilities'

const mstp = state => ({ userInfo: state.userInfo })

class DeadlinePage extends React.Component {
  render() {
    return <Deadlines />
  }
}
export default withJWTSession({ invalid: '/drugstore/login' })(
  connect(mstp)(
    withCapabilities({
      some: ['bo_show_deadlines'],
    })(DeadlinePage)
  )
)
