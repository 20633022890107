import React from 'react'
import { connect } from 'react-redux'
import { Alert, Form, Modal, Button, Card } from 'react-bootstrap'
import ListItemMobile from 'src/components/drugstore/tenants_list/list_item_mobile'
import withCapabilities from 'src/enhancers/with_capabilities'
import ResponsiveScroller from 'src/components/system_wide/responsive_scroller'
import SearchButton from 'src/components/system_wide/search_button'
import {
  setCurrentTenant,
  retrieveTenants,
  toggleShowDialog,
  updateCreateFormData,
  createTenant,
} from 'src/store/store_manager'

const mstp = state => {
  let { show_dialog, business_name, name, description, message, message_type, is_pending } =
    state.storeManager.create_tenant_form
  return {
    tenants: state.storeManager.tenants,
    business_name,
    show_dialog,
    name,
    description,
    message,
    message_type,
    is_pending,
  }
}

class TenantsList extends React.Component {
  static defaultProps = {
    mountedOnMenuPage: false,
    mountedOnMenu: false,
  }

  state = {
    filter: '',
  }

  componentDidMount() {
    if (!this.props.tenants.length) {
      this.props.dispatch(retrieveTenants())
    }
  }
  componentDidUpdate() {
    let tenant_name = this.props?.router?.params?.store_name ?? ''

    if (!this.props.name && tenant_name) {
      this.props.dispatch(setCurrentTenant(tenant_name))
    }
  }

  toggleDialog = () => {
    this.props.dispatch(toggleShowDialog())
  }

  handleChange = ({ target }) => {
    let { name, value } = target
    this.props.dispatch(updateCreateFormData({ name, value }))
  }

  handleCreateTenant = () => {
    let { business_name, description } = this.props
    this.props.dispatch(createTenant({ business_name, description }))
  }

  isFormValid = () => {
    let { business_name, description } = this.props
    return business_name !== '' && description !== ''
  }

  handleSetTenant = tenant => () => {
    this.props.dispatch(setCurrentTenant(tenant.name))
  }

  onFilterChange = filter => this.setState({ filter })

  render() {
    let { business_name, description, message_type, message, is_pending, tenants, mountedOnMenuPage } = this.props
    let { filter } = this.state
    let filterRegexp = new RegExp(filter, 'i')
    let filteredTenants = tenants?.filter(({ description }) => filterRegexp.test(description))

    return (
      <>
        <div className="container-xxl flex-fill d-flex flex-column">
          {mountedOnMenuPage === true && (
            <Card className="flex-fill">
              <Card.Body className="d-flex flex-column">
                <Card.Title className="border-bottom">
                  <div className="d-flex flex-column py-1 pb-3">
                    <h4 className="mb-3">Lista punti vendita</h4>
                    <SearchButton value={this.state.filter} onChange={this.onFilterChange} />
                  </div>
                </Card.Title>
                <Card.Text as="div" className="flex-fill d-flex flex-column">
                  <ResponsiveScroller>
                    <div className="list-group">
                      {filteredTenants?.map(tenant => (
                        <ListItemMobile key={tenant.name} tenant={tenant} setTenant={this.handleSetTenant(tenant)} />
                      ))}
                    </div>
                  </ResponsiveScroller>
                </Card.Text>
              </Card.Body>
              <CardFooter createTenantAction={this.toggleDialog} />
            </Card>
          )}
          {mountedOnMenuPage === false && (
            <div className="d-flex flex-fill flex-column">
              <div className="d-flex flex-column flex-fill">
                <div className="mb-3">
                  <SearchButton value={this.state.filter} onChange={this.onFilterChange} />
                </div>
                <div className="flex-fill d-flex flex-column">
                  <ResponsiveScroller>
                    <div className="list-group" style={{ boxShadow: 'var(--hr-shadow)', margin: '0px 8px' }}>
                      {filteredTenants?.map(tenant => (
                        <ListItemMobile key={tenant.name} tenant={tenant} onClick={this.handleSetTenant(tenant)} />
                      ))}
                    </div>
                  </ResponsiveScroller>
                </div>
                <div className="d-flex align-self-end mt-auto">
                  <Button onClick={this.toggleDialog}> aggiungi</Button>
                </div>
              </div>
            </div>
          )}
        </div>
        <Modal backdrop="static" show={this.props.show_dialog} centered>
          <Modal.Header>
            <Modal.Title>Aggiungi cliente</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3 text-muted">
              Inserisci un nome per il punto vendita.
              <br />
              Il nome inserito non potra essere successivamente modificato
            </div>
            <Alert variant={message_type} show={message !== ''} onClose={this.resetMessage} transition={false}>
              {message}
            </Alert>
            <Form.Group className="mb-3" disabled={this.is_pending}>
              <Form.Label>Ragione sociale/identificativo univoco</Form.Label>
              <Form.Control
                name="business_name"
                placeholder="Ragione sociale"
                value={business_name}
                onChange={this.handleChange}
                disabled={is_pending}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Descrizione</Form.Label>
              <Form.Control
                name="description"
                placeholder={''}
                value={description}
                onChange={this.handleChange}
                disabled={is_pending}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.toggleDialog} disabled={is_pending}>
              Annulla
            </Button>
            <Button variant="primary" disabled={!this.isFormValid() || is_pending} onClick={this.handleCreateTenant}>
              {is_pending && (
                <>
                  <span className="spinner-border spinner-border-sm"></span>{' '}
                </>
              )}
              Conferma
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

const CardFooter = withCapabilities({ has: ['bo_create_tenant'] })(props => {
  return (
    <Card.Footer className="text-end bg-white py-3">
      <Button onClick={props.createTenantAction}>Aggiungi cliente</Button>
    </Card.Footer>
  )
})

export default connect(mstp)(TenantsList)
