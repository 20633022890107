import React from 'react'
import { connect } from 'react-redux'
import { Modal, Button, Card } from 'react-bootstrap'
import MaintenancesOpened from 'src/components/drugstore/dashboard/maintenance_opened'
import MaintenancesPending from 'src/components/drugstore/dashboard/maintenance_pending'
import { getAllMaintenancesClosed } from 'src/store/maintenances'
const mstp = state => {
  let { name: tenant_name } = state.storeManager.current_tenant
  let { total_opened, total_pending, total_closed, maintenances_opened, maintenances_pending } = state.maintenances
  return {
    tenant_name,
    total_opened,
    total_pending,
    total_closed,
    maintenances_opened,
    maintenances_pending,
  }
}

class Dashboard extends React.Component {
  state = {
    show_modal: false,
    filter: '',
  }

  tableRef = null
  constructor(props) {
    super(props)
    this.state.email = props.email ?? ''
    this.tableRef = React.createRef()
  }

  async componentDidMount() {
    await this.props.dispatch(getAllMaintenancesClosed())
  }

  render() {
    return (
      <div className="container-fluid flex-fill d-flex flex-column">
        <Card className="flex-fill">
          <Card.Body className="d-flex flex-column">
            <Card.Title className="border-bottom">
              <h3>Dashboard</h3>
            </Card.Title>
            <Card.Text as="div" className="flex-fill d-flex flex-column">
              <div className="d-flex flex-row gap-3 mb-4">
                <Card className="flex-fill">
                  <Card.Body className="p-4 bg-danger bg-opacity-10">
                    <h4>Manutenzioni aperte</h4>
                    <h1>{this.props?.total_opened}</h1>
                  </Card.Body>
                </Card>
                <Card className="flex-fill">
                  <Card.Body className="p-4 bg-warning bg-opacity-10">
                    <h4>Manutenzioni in lavorazione</h4>
                    <h1>{this.props.total_pending}</h1>
                  </Card.Body>
                </Card>
                <Card className="flex-fill">
                  <Card.Body className="p-4 bg-success bg-opacity-10">
                    <h4>Manutenzioni chiuse</h4>
                    <h1>{this.props.total_closed}</h1>
                  </Card.Body>
                </Card>
              </div>

              <div className="d-flex flex-row flex-fill gap-3 mb-4">
                <MaintenancesOpened />
                <MaintenancesPending />
              </div>

              <Modal backdrop="static" centered show={this.state.show_modal} onHide={this.onCancel}>
                <Modal.Header>
                  <Modal.Title>Elimina tipo di manutenzione</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>
                    Per procedere all'eliminazione del tipo di manutenzione{' '}
                    <b>{this.props.current_maintenance_type?.name}</b>, cliccare sul bottone "Elimina"
                  </p>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={this.onCancel} variant="secondary">
                    Annulla
                  </Button>
                  <Button onClick={this.onConfirmDelete} variant="danger">
                    Elimina
                  </Button>
                </Modal.Footer>
              </Modal>
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    )
  }
}
export default connect(mstp)(Dashboard)
