import React from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'src/lib/router/with_router'
import { Nav, Badge, Offcanvas } from 'react-bootstrap'
import TenantsList from 'src/components/drugstore/tenants_list'
import { WithCapabilitiesWrapper, withCapabilities } from 'src/enhancers/with_capabilities'
import ThemeContext from 'src/components/system_wide/theme_context'
import styles from './horizontal_sidebar.module.css'

class HorizontalSidebar extends React.Component {
  static contextType = ThemeContext

  static defaultProps = {
    sidebarItems: [],
    mountedOnMobileMenu: false,
    mountedOnHome: false,
    showInPage: true,
  }
  state = {
    show_tenant_list: false,
  }

  toggleTenantMenu = () => {
    this.setState({ show_tenant_list: !this.state.show_tenant_list })
  }

  render() {
    let { location, params } = this.props.router
    let disabled_menu = params?.store_name ?? true
    //let current_pathname = /\[/.test(item.pathname) ? pathname.replace('[store_name]', params.store_name) : ''
    let activeKey = this.props.sidebarItems.find(i => {
      if (!i.as) {
        let test_pathname = /\[/.test(i.pathname) ? i.pathname.replace('[store_name]', params.store_name) : ''
        return test_pathname === location.pathname
      } else {
        return location.pathname === i.as
      }
    })?.name
    let { mountedOnMobileMenu, mountedOnHome, showInPage } = this.props
    //let { sidebarVariant, mobileMenuVariant } = this.context
    let sidebarVariant = 'light'
    let mobileMenuVariant = 'light'
    let hightlightMode = mountedOnMobileMenu ? 'underline' : 'bg'
    let variant = mountedOnMobileMenu ? mobileMenuVariant : sidebarVariant
    if (!showInPage) return null
    if (mountedOnHome) {
      return this.props.sidebarItems.map((item, i) => {
        if ('title' in item) {
          return <div key={`title${i}`} className={`w-100 ${styles.home_button_break}`} />
        }
        let navigate_pathname = /\[/.test(item.pathname) ? item.pathname.replace('[store_name]', params.store_name) : ''
        return (
          <WithCapabilitiesWrapper {...item.caps} required_modules={item.required_modules} key={`button-${i}`}>
            <Nav.Link as={Link} to={{ navigate_pathname }}>
              <div className={`btn btn-primary d-flex flex-column ${styles.home_button}`}>
                <div>
                  <i className={item.icon}></i>
                </div>
                <div className="flex-fill d-flex justify-content-center align-items-center">{item.text}</div>
              </div>
            </Nav.Link>
          </WithCapabilitiesWrapper>
        )
      })
    }

    return (
      <>
        <Nav
          as="aside"
          defaultActiveKey="point_of_sale_data"
          activeKey={activeKey}
          className={`flex-fill ${styles.sidebar} variant-${variant} highlight-${hightlightMode} ${
            mountedOnMobileMenu ? '' : 'with-shadow'
          }`}>
          <div className="overflow-auto w-100">
            <div className={`${mountedOnMobileMenu ? '' : 'd-flex flex-row overflow-auto flex-wrap'}`}>
              {this.props.sidebarItems.map((item, i) => {
                let isTitle = typeof item.title !== 'undefined'
                let key = `sidebar-item-${i}`
                if (item?.hideOnMobileMenu === true && mountedOnMobileMenu === true) return
                if (isTitle) {
                  let contrast = `contrast-${mountedOnMobileMenu ? mobileMenuVariant : sidebarVariant}`
                  let textContrast = `text-${contrast}`
                  let titleClasses = mountedOnMobileMenu ? `pt-3 px-2 text-muted ` : `h5 p-2 border-bottom-${contrast}`

                  return (
                    <>
                      <WithCapabilitiesWrapper {...item.caps} required_modules={item.required_modules} key={key}>
                        <div className={`${titleClasses} ${textContrast}`}>
                          {typeof item.icon !== 'undefined' && mountedOnMobileMenu === false && (
                            <i className={`${item.icon} me-1`} />
                          )}
                          {item.title}
                        </div>
                      </WithCapabilitiesWrapper>
                    </>
                  )
                } else {
                  return (
                    <SidebarLink
                      key={key}
                      item={item}
                      caps={item.caps}
                      required_modules={item.required_modules}
                      as={item.as}
                      params={params}
                      mountedOnMobileMenu={mountedOnMobileMenu}
                      notifications={item.notifications}
                      toggleTenantMenu={this.toggleTenantMenu}
                      disabled={disabled_menu}
                    />
                  )
                }
              })}
            </div>
          </div>
        </Nav>
        <Offcanvas show={this.state.show_tenant_list} onHide={this.toggleTenantMenu}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Lista punti vendita</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="d-flex flex-column">
            <TenantsList mountedOnMenu={true} mountedOnMenuPage={false} />
          </Offcanvas.Body>
        </Offcanvas>
      </>
    )
  }
}

const SidebarLink = withCapabilities({})(props => {
  let { item, params, mountedOnMobileMenu, notifications = 0, disabled } = props
  let navigate_pathname = /\[/.test(item.pathname)
    ? item.pathname.replace('[store_name]', params.store_name)
    : item.pathname
  return (
    <Nav.Link
      className={`${item.name === 'tenant_list' ? 'd-flex d-lg-none' : ''}`}
      eventKey={item.name}
      as={Link}
      to={navigate_pathname}
      disabled={item.name !== 'tenant_list' && disabled === true}
      onClick={item.name === 'tenant_list' ? props.toggleTenantMenu : () => {}}>
      <div className={`d-flex align-items-center ${item.className ?? ''} `}>
        <i className={`${mountedOnMobileMenu ? 'ms-2' : 'ms-1 me-1'} ${item.icon}`} />
        {item.text}
        {notifications > 0 && (
          <Badge className="ms-1" bg="danger" style={{ borderRadius: '100%' }}>
            {notifications}
          </Badge>
        )}
      </div>
    </Nav.Link>
  )
})

export default withRouter(HorizontalSidebar)
