import React from 'react'
import { Modal } from 'react-bootstrap'

import Menu from './menu'
import Backdrop from './backdrop'
import RefreshingMask from './refreshing_mask'
import styles from './responsive_table.module.css'

export const TableHeader = React.memo(({ columns, headerPadding, sort_column, sort_direction, onSortChange }) => {
  return (
    <div
      className={`d-flex align-item-center responsive_table_header ${styles.header}`}
      style={{ paddingRight: headerPadding }}>
      {columns.map((c, i) => {
        let { title, style, className, sortable = false, sortingKey, data } = c
        let sKey = (typeof sortingKey === 'string' ? sortingKey : false) || (typeof data === 'string' ? data : false)
        let isSortable = sortable === true && sKey !== false
        let icon = 'fa-fw fas fa-sort'
        if (sKey === sort_column) {
          switch (sort_direction) {
            case 'ASC':
              icon = 'fa-fw fas fa-sort-down'
              break
            case 'DESC':
              icon = 'fa-fw fas fa-sort-up'
              break
            default:
          }
        }
        let onClickProps = isSortable ? { onClick: () => onSortChange(sKey) } : {}

        return (
          <div
            key={`header-${i}`}
            style={style}
            className={`${className} ${isSortable ? 'sortable' : ''} user-select-none`}
            {...onClickProps}>
            {title}
            {isSortable === true && <i className={icon} />}
          </div>
        )
      })}
    </div>
  )
})
TableHeader.displayName = TableHeader

export const TableFooter = React.memo(({ showTotals, total, onRefresh, isRefreshing }) => {
  return (
    <>
      <div className={`d-flex align-items-center py-2 bg-light responsive_table_footer ${styles.footer}`}>
        <div className="flex-fill text-contrast-light">{showTotals && `${'Total records'}: ${total}`}</div>
        <button className="btn btn-primary py-0" onClick={onRefresh}>
          <i className="fas fa-sync-alt fa-fw" />
        </button>
      </div>
      <RefreshingMask isRefreshing={isRefreshing} />
    </>
  )
})
TableFooter.displayName = TableFooter

export class TableMenu extends React.Component {
  menuRef = React.createRef()

  shouldComponentUpdate(nextProps) {
    if (nextProps.useModal !== this.props.useModal) {
      this.menuState = this.menuRef?.current?.state ?? {}
    }
    return true
  }
  componentDidUpdate(prevProps) {
    if (prevProps.useModal !== this.props.useModal && this.menuRef?.current?.setState) {
      this.menuRef.current.setState(this.menuState)
    }
  }

  render() {
    let { showMenu, menuWidth, useModal, menuContent: Content, menuContentProps } = this.props
    return (
      <>
        <style jsx="true" global="true">{`
          :local (.menu-dialog .modal-content) {
            background-color: var(--bs-light);
          }
          @media screen and (min-width: 576px) {
            :local (.menu-dialog .modal-dialog) {
              min-width: 45rem;
            }
          }
        `}</style>
        {useModal === false && (
          <>
            <Backdrop in={showMenu} />
            <Menu in={showMenu} width={menuWidth}>
              <Content {...menuContentProps} ref={this.menuRef} />
            </Menu>
          </>
        )}
        {useModal === true && (
          <Modal show={showMenu} backdrop="static" keyboard={false} centered className="menu-dialog">
            <Modal.Body className="d-flex flex-column" style={{ height: '80vh' }}>
              <Content {...menuContentProps} ref={this.menuRef} />
            </Modal.Body>
          </Modal>
        )}
      </>
    )
  }
}

export const DataRow =
  (columns, rowClasses) =>
  // eslint-disable-next-line react/display-name
  (index, rowData = {}) => {
    let computedRowClasses = typeof rowClasses === 'function' ? rowClasses(rowData) : rowClasses.toString()
    return (
      <div
        className={`d-flex align-items-center ${styles.row} ${computedRowClasses}`}
        style={{ background: index % 2 === 0 ? 'var(--bs-light)' : '' }}>
        {columns.map((col, i) => {
          let { style, className, data: DataRenderer } = col
          return (
            <div key={`col-${i}`} style={style} className={className}>
              {typeof DataRenderer === 'string' && rowData[DataRenderer]}
              {typeof DataRenderer === 'function' && <DataRenderer rowData={rowData} />}
            </div>
          )
        })}
      </div>
    )
  }
DataRow.displayName = DataRow

export const NoRecords = React.memo(({ in: inProps }) => {
  if (inProps) {
    return (
      <div className={`${styles.norecords} ${'d-flex justify-content-center align-items-center'}`}>
        <div className="px-4 py-2 bg-light border">
          <b>No records found</b>
        </div>
      </div>
    )
  } else {
    return null
  }
})
NoRecords.displayName = NoRecords
