import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { error, success } from 'src/components/system_wide/notification'
import axios from 'src/lib/axios'

const initialState = {
  total: 0,
  setup_costs: [],
  show_form: false,
  current_setup_cost: {
    id: null,
    description: '',
    costs: 0,
  },
}

export const getSetupCosts = createAsyncThunk(
  'setup_costs/getSetupCosts',
  async ({ tenant, offset, limit, sortData, filter }, thunkApi) => {
    try {
      let { total: currentTotal } = thunkApi.getState().setupCosts
      if (offset > currentTotal) return

      let { sort_column, sort_direction } = sortData ?? { sort_column: 'id', sort_direction: 'DESC' }
      let response = await axios({
        url: `/api/setup_costs/${tenant}`,
        params: { offset, limit, sort_column, sort_direction, filter },
      })
      let { total, setup_costs } = response.data
      return { total, setup_costs, reset: offset === 0 }
    } catch (err) {
      error('Impossible recuperare spese allestimento')
      return thunkApi.rejectWithValue()
    }
  }
)

export const createSetupCost = createAsyncThunk(
  'setup_costs/createSetupCost',
  async ({ tenant, setup_cost }, thunkApi) => {
    try {
      let { description, costs } = setup_cost
      await axios({
        url: `/api/setup_cost/${tenant}`,
        method: 'post',
        data: { description, costs },
      })
      success('Spesa di allestimento creata con successo')
      thunkApi.dispatch(resetCurrentSetupCost())
      thunkApi.dispatch(toggleShowForm())
    } catch (err) {
      //error(data.message === 'setup_costname_or_email_already_exists_error' ? msg1 : msg2)
      error('Impossibile creare spesa di allestimento')
      return thunkApi.rejectWithValue()
    }
  }
)

export const updateSetupCost = createAsyncThunk(
  'setup_costs/updateSetupCost',
  async ({ tenant, setup_cost }, thunkApi) => {
    try {
      let { id, description, costs } = setup_cost
      await axios({
        url: `/api/setup_cost/${tenant}/${id}`,
        method: 'patch',
        data: { description, costs },
      })
      success('Spesa di allestimento aggiornata con successo')
      thunkApi.dispatch(resetCurrentSetupCost())
      thunkApi.dispatch(toggleShowForm())
    } catch (err) {
      //error(data.message === 'setup_costname_or_email_already_exists_error' ? msg1 : msg2)
      error('Impossibile aggiornare spesa di allestimento')
      return thunkApi.rejectWithValue()
    }
  }
)

export const deleteSetupCost = createAsyncThunk('setup_costs/deleteSetupCost', async ({ tenant, id }, thunkApi) => {
  try {
    await axios({ url: `/api/setup_cost/${tenant}/${id}`, method: 'delete' })
    success('Spesa di allestimento eliminata con successo')
    thunkApi.dispatch(resetCurrentSetupCost())
  } catch (err) {
    error('Impossibile eliminare spesa di allestimento')
    return thunkApi.rejectWithValue()
  }
})

export const setup_costsSlice = createSlice({
  name: 'setup_costs',
  initialState,

  reducers: {
    setCurrentSetupCost: (state, action) => {
      let { id, description, costs } = action.payload
      state.current_setup_cost.id = id
      state.current_setup_cost.description = description
      state.current_setup_cost.costs = costs
    },
    toggleShowForm: state => {
      state.show_form = !state.show_form
    },
    resetCurrentSetupCost: state => {
      state.current_setup_cost = initialState.current_setup_cost
    },
    resetSetupCosts: () => initialState,
  },

  extraReducers: {
    [getSetupCosts.fulfilled]: (state, action) => {
      if (!action?.payload) return
      let { total, setup_costs } = action.payload
      state.total = total
      state.setup_costs = action.payload.reset ? [...setup_costs] : [...state.setup_costs, ...setup_costs]
    },
    [getSetupCosts.rejected]: state => {
      state.total = initialState.total
      state.setup_costs = initialState.setup_costs
    },
  },
})

export const { setCurrentSetupCost, resetCurrentSetupCost, toggleShowForm, resetSetupCosts } = setup_costsSlice.actions
export default setup_costsSlice.reducer
