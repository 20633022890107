import React from 'react'
import { withRouter } from 'src/lib/router/with_router'

class ErrorPage extends React.Component {
  render() {
    let { error } = this.props
    return (
      <div id="error-page">
        <h1>Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
        <p>
          <i>{error?.statusText || error?.message || ''}</i>
        </p>
      </div>
    )
  }
}

export default withRouter(ErrorPage)
