import * as React from 'react'
import { Button, Form, Alert } from 'react-bootstrap'
import { updateFormData, resetMessage, submitForgotPassword } from 'src/store/login'
import styles from 'src/components/system_wide/login_form/login_form.module.css'

class ForgotPasswordVariant extends React.Component {
  handleChange = ({ target }) => {
    let { name, value } = target
    this.props.dispatch(updateFormData({ name, value }))
  }

  resetMessage = () => {
    this.props.dispatch(resetMessage())
  }

  handleSubmitForgotPwd = async () => {
    let { username_or_email } = this.props.loginData
    this.props.dispatch(submitForgotPassword({ username_or_email }))
  }

  componentWillUnmount() {
    this.props.dispatch(updateFormData({ name: 'username_or_email', value: '' }))
  }

  render() {
    let { message_type, message, loading, username_or_email } = this.props.loginData

    return (
      <div className={`card ${styles.content} pt-3`}>
        <div className="card-body">
          <div className="d-flex flex-row gap-2 align-items-center mb-2 text-center"></div>
          <div className="mb-5">
            <h4 className="mb-3 text-center">Recupera password</h4>
          </div>
          <Alert variant={message_type} show={message !== ''} onClose={this.resetMessage}>
            {message}
          </Alert>
          <Form className="mb-2">
            <Form.Group className="mb-3">
              <Form.Label>Username or email</Form.Label>
              <Form.Control
                name="username_or_email"
                autoComplete="off"
                placeholder="username_or_email"
                value={username_or_email}
                onChange={this.handleChange}
              />
              <Form.Text>
                Inserisci lo username o email e ti invieremo le istruzioni per creare una nuova password
              </Form.Text>
            </Form.Group>
            <div className="mt-5">
              <button className="link-button" onClick={this.props.handleForgotPassword}>
                « Torna al login
              </button>
              <Button
                className="float-end"
                style={{ minWidth: '6rem' }}
                color="primary"
                size="md"
                disabled={username_or_email === '' || loading === true}
                onClick={this.handleSubmitForgotPwd}>
                {loading === true && (
                  <>
                    <span className="spinner-border spinner-border-sm"></span> Invia email
                  </>
                )}
                {loading === false && 'Invia'}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    )
  }
}

export default ForgotPasswordVariant
