import React from 'react'
import { connect } from 'react-redux'
import { Modal, Button, Form, Alert } from 'react-bootstrap'
import { updateFormData, tryUpdatePassword } from 'src/store/login'

const mstp = state => {
  let { username: logged_username, is_management_account } = state.userInfo
  let { message, message_type, new_password, confirm_password, is_weak_password, loading } = state.loginData
  return {
    is_management_account,
    logged_username,
    message,
    message_type,
    new_password,
    confirm_password,
    is_weak_password,
    loading,
  }
}

class ResetPasswordModal extends React.Component {
  static defaultProps = {
    username: '',
    show: false,
    onCancel: () => {},
    onConfirm: () => {},
  }

  handleChange = ({ target }) => {
    let { name, value } = target
    this.props.dispatch(updateFormData({ name, value }))
  }

  onConfirm = async () => {
    let { username, logged_username, new_password, confirm_password } = this.props
    let response = await this.props.dispatch(
      tryUpdatePassword({
        new_password,
        confirm_password,
        username: username !== '' ? username : logged_username,
      })
    )
    if (response?.payload?.status === 'success') {
      this.props.onCancel()
    }
  }

  render() {
    let { confirm_password, new_password, is_weak_password, message, message_type } = this.props
    return (
      <Modal backdrop="static" centered show={this.props.show} onHide={this.props.onCancel}>
        <Modal.Header>
          <Modal.Title>Cambia password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!is_weak_password && (
            <Alert variant={message_type} show={message !== ''} transition={false}>
              {message}
            </Alert>
          )}
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Nuova password</Form.Label>
              <Form.Control
                type="password"
                name="new_password"
                autoComplete="new-password"
                id="new_password"
                value={new_password}
                isInvalid={is_weak_password}
                onChange={this.handleChange}
              />
              <Form.Control.Feedback type="invalid">{message}</Form.Control.Feedback>
              <Form.Text>Minimo 8 caratteri, almeno 1 minuscola, 1 maiuscolo e 1 numero</Form.Text>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Conferma password</Form.Label>
              <Form.Control
                type="password"
                name="confirm_password"
                autoComplete="off"
                id="confirm_password"
                value={confirm_password}
                onChange={this.handleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onCancel} variant="secondary">
            Annulla
          </Button>
          <Button onClick={this.onConfirm} disabled={confirm_password !== new_password} variant="primary">
            Conferma
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default connect(mstp)(ResetPasswordModal)
