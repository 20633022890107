import React from 'react'
import { connect } from 'react-redux'
import { Modal, Button, Card, Form } from 'react-bootstrap'
import isEmail from 'validator/lib/isEmail'
import ActionsDropdown from 'src/components/system_wide/actions_dropdown'
import SearchButton from 'src/components/system_wide/search_button'
import ResponsiveTable from 'src/components/system_wide/responsive_table'

import MaintenanceTypeForm from 'src/components/drugstore/maintenance_types/maintenance_type_form'
import {
  setCurrentMaintenanceType,
  toggleShowForm,
  getNotificationEmail,
  createMaintenanceType,
  updateMaintenanceType,
  resetCurrentMaintenanceType,
  deleteMaintenanceType,
  getMaintenanceTypes,
  updateNotificationEmail,
} from 'src/store/maintenance_types'

const mstp = state => {
  let { total, show_form, maintenance_types, current_maintenance_type, emails } = state.maintenanceTypes
  return { total, show_form, maintenance_types, current_maintenance_type, emails }
}

class MaintenanceTypes extends React.Component {
  state = {
    show_modal: false,
    filter: '',
    emails: '',

    error_emails: false,
  }

  tableRef = null
  constructor(props) {
    super(props)
    this.state.emails = props.emails ?? ''
    this.tableRef = React.createRef()
  }

  async componentDidMount() {
    await this.props.dispatch(getNotificationEmail())
  }
  componentDidUpdate(prevProv) {
    if (prevProv.emails !== this.props.emails) {
      this.setState({ emails: this.props.emails })
    }
  }

  actions = [
    {
      icon: 'fa-edit',
      text: 'Modifica',
      onClick: rowData => {
        let { id, name } = rowData
        this.setState({ mode: 'user' })
        this.props.dispatch(
          setCurrentMaintenanceType({
            id,
            name,
          })
        )
        this.props.dispatch(toggleShowForm())
      },
    },
    {
      icon: 'fa-trash-alt',
      text: 'Elimina',
      disabled: () => null,
      onClick: rowData => {
        let { id, name } = rowData
        this.props.dispatch(setCurrentMaintenanceType({ id, name }))
        this.setState({ show_modal: true })
      },
    },
  ]

  actionsRenderer = ({ rowData }) => {
    let actions = this.actions
    return <ActionsDropdown rowData={rowData} actions={actions} />
  }

  columns = [
    {
      title: '',
      className: 'd-lg-block text-center',
      style: { width: '6%', minWidth: '3rem' },
      data: this.actionsRenderer,
    },
    {
      title: 'Tipo manutenzione',
      className: 'd-lg-block flex-fill',
      style: { width: '79%' },
      sortable: false,
      sortingKey: 'enabled',
      data: 'name',
    },
    {
      title: 'Creato/Aggiornato da',
      className: 'd-none d-lg-block text-center',
      style: { width: '15%' },
      data: ({ rowData }) => `${rowData?.created_by ?? '-'}/${rowData?.updated_by ?? '-'}`,
    },
  ]

  onFilterChange = filter => this.setState({ filter })

  getData = async (index = 0, chunkSize, sortData, filter) => {
    await this.props.dispatch(getMaintenanceTypes({ offset: index, limit: chunkSize, sortData, filter }))
  }

  saveNotificationEmail = async () => {
    await this.props.dispatch(updateNotificationEmail({ emails: this.state.emails }))
  }

  saveMaintenanceType = async maintenance_type => {
    maintenance_type.id
      ? await this.props.dispatch(updateMaintenanceType({ maintenance_type }))
      : await this.props.dispatch(createMaintenanceType({ maintenance_type }))
    this.tableRef.current.refreshData()
  }

  onChangeRecipientsEmails = e => {
    let { value } = e.target
    let emails = { ...this.state.emails }
    let error_emails = value !== '' ? value.split(',').some(e => !isEmail(e.trim())) : true
    emails = value.replace(' ', '')
    this.setState({ emails, error_emails })
  }

  onToggleForm = () => {
    if (this.props.show_form === true) {
      this.props.dispatch(resetCurrentMaintenanceType())
    }
    this.setState({ mode: 'maintenance' })
    this.props.dispatch(toggleShowForm())
  }

  onCancel = () => {
    this.props.dispatch(resetCurrentMaintenanceType())
    this.setState({ show_modal: false })
  }

  onConfirmDelete = async () => {
    await this.props.dispatch(deleteMaintenanceType({ id: this.props.current_maintenance_type.id }))
    this.tableRef.current.refreshData()
    this.setState({ show_modal: false })
  }

  componentWillUnmount() {
    this.props.dispatch(resetCurrentMaintenanceType())
  }

  render() {
    let { maintenance_types, current_maintenance_type, show_form, total } = this.props
    let { emails, error_emails } = this.state

    return (
      <div className="container-xxl flex-fill d-flex flex-column">
        <Card className="flex-fill">
          <Card.Body className="d-flex flex-column">
            <Card.Title className="border-bottom">
              <h3>Notifiche/Manutenzione</h3>
            </Card.Title>
            <Card.Text as="div" className="flex-fill d-flex flex-column">
              <div className="container-fluid px-0 mb-2">
                <div className="row">
                  <Form.Group className="mt-3 mb-3 col">
                    <Form.Label>Email di notifica</Form.Label>
                    <span> *</span>
                    <Form.Control
                      name="emails"
                      placeholder="email@email.it, email@email.it, email@email.it"
                      value={emails}
                      onChange={this.onChangeRecipientsEmails}
                      isInvalid={error_emails}
                    />
                    <Form.Control.Feedback type="invalid">indirizzo email non valido</Form.Control.Feedback>
                    <Form.Text className="text-muted">Inserire indirizzi email separati da virgola</Form.Text>
                  </Form.Group>
                  <div className="col align-self-center">
                    <button
                      className="btn btn-primary btn-block mt-2"
                      onClick={this.saveNotificationEmail}
                      disabled={this.props.show_form}>
                      Salva
                    </button>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col d-grid d-md-block">
                    <button
                      className="btn btn-primary btn-block"
                      onClick={this.onToggleForm}
                      disabled={this.props.show_form}>
                      <i className="fas fa-plus fa-fw text-start" />
                      Aggiungi tipo di manutenzione
                    </button>
                  </div>
                  <div className="d-none d-md-block col-md-2 col-lg-4"></div>
                  <div className="mt-2 mt-md-0 col-12 col-md-6 col-lg-4">
                    <SearchButton
                      value={this.state.filter}
                      onChange={this.onFilterChange}
                      disabled={this.props.show_form}
                      className="float-md-end"
                    />
                  </div>
                </div>
              </div>
              <ResponsiveTable
                ref={this.tableRef}
                className="flex-fill"
                data={maintenance_types}
                getData={this.getData}
                totalRecords={total}
                columns={this.columns}
                showMenu={show_form}
                menuWidth="40rem"
                menuContent={MaintenanceTypeForm}
                menuContentProps={{
                  maintenance_type: current_maintenance_type,
                  onSave: this.saveMaintenanceType,
                  closeForm: this.onToggleForm,
                }}
                rowClasses={rowData => (rowData.enabled ? '' : `text-muted`)}
                filter={this.state.filter}
              />

              <Modal backdrop="static" centered show={this.state.show_modal} onHide={this.onCancel}>
                <Modal.Header>
                  <Modal.Title>Elimina tipo di manutenzione</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>
                    Per procedere all'eliminazione del tipo di manutenzione{' '}
                    <b>{this.props.current_maintenance_type?.name}</b>, cliccare sul bottone "Elimina"
                  </p>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={this.onCancel} variant="secondary">
                    Annulla
                  </Button>
                  <Button onClick={this.onConfirmDelete} variant="danger">
                    Elimina
                  </Button>
                </Modal.Footer>
              </Modal>
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    )
  }
}
export default connect(mstp)(MaintenanceTypes)
