import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { success, error } from 'src/components/system_wide/notification'
import axios from 'src/lib/axios'

const initialState = {
  id: null,
  business_name: '',
  description: '',
  tax_code: '',
  pec: '',
  email: '',
  address: '',
  city: '',
  province: '',
  country: '',
  created_at: '',
}

export const getManager = createAsyncThunk('manager/getManager', async (tenant, thunkApi) => {
  try {
    let response = await axios({ url: `/api/manager/${tenant}` })
    return response.data
  } catch (err) {
    error('Impossibile recupeare lista punti vendita')
    return thunkApi.rejectWithValue()
  }
})

export const updateManager = createAsyncThunk(
  'manager/updateManager',
  async ({ tenant, business_name, description, tax_code, pec, address, email }, thunkApi) => {
    try {
      await axios({
        url: `/api/manager/${tenant}`,
        method: 'PATCH',
        data: { business_name, description, tax_code, pec, address, email },
      })
      await thunkApi.dispatch(getManager(tenant))
      success('Punto vendita aggiornato con successo')
    } catch (err) {
      error('Impossibile aggiornare punto vendita')
      return thunkApi.rejectWithValue()
    }
  }
)

export const managerSlice = createSlice({
  name: 'manager',
  initialState,

  reducers: {
    resetManager: () => initialState,
  },
  extraReducers: {
    [getManager.fulfilled]: (state, action) => {
      state.id = action.payload.id ?? null
      state.business_name = action.payload?.business_name ?? ''
      state.description = action.payload?.description ?? ''
      state.tax_code = action.payload?.tax_code ?? ''
      state.pec = action.payload?.pec ?? ''
      state.email = action.payload?.email ?? ''
      state.address = action.payload?.address ?? ''
      state.city = action.payload?.city ?? ''
      state.province = action.payload?.province ?? ''
      state.country = action.payload?.country ?? ''
    },
    [getManager.rejected]: () => {},
  },
})

export const { resetManager } = managerSlice.actions
export default managerSlice.reducer
