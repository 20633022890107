import { createSlice } from '@reduxjs/toolkit'

// Initial state
export const initialState = {
  loading: false,
}

export const loadingSlice = createSlice({
  name: 'loading',
  initialState,

  reducers: {
    startLoading: state => {
      state.loading = true
    },
    stopLoading: state => {
      state.loading = false
    },
  },
})

export const { startLoading, stopLoading } = loadingSlice.actions
export default loadingSlice.reducer
