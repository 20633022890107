import React from 'react'
import { connect } from 'react-redux'
import { Modal, Button, Card } from 'react-bootstrap'

import ActionsDropdown from 'src/components/system_wide/actions_dropdown'
import SearchButton from 'src/components/system_wide/search_button'
import ResponsiveTable from 'src/components/system_wide/responsive_table'
import UserForm from 'src/components/drugstore/users/user_form'
import AssociatedStoresForm from 'src/components/drugstore/users/associated_stores_form'
import ResetPasswordModal from './reset_password_modal'
import {
  setCurrentUser,
  createUser,
  updateUser,
  deleteUser,
  toggleShowForm,
  getUsers,
  resetCurrentUser,
  resetUsers,
  saveAssociatedStores,
} from 'src/store/users'

import { resetTenants, resetCurrentTenant, retrieveTenants } from 'src/store/store_manager'
import { resetAclManager } from 'src/store/acls_manager'
import { resetRoleManager } from 'src/store/roles_manager'
import { getConstants } from 'src/lib/constants'
import { resetLoginData } from 'src/store/login'

const mstp = state => {
  let { total, users, show_form, current_user } = state.users
  let { id: id_user_info, is_management_account } = state.userInfo
  let { tenants: stores } = state.storeManager
  return { total, users, show_form, current_user, stores, is_management_account, id_user_info }
}

class Users extends React.Component {
  state = {
    show_modal: false,
    filter: '',
    mode: '',
    show_reset_password: false,
  }

  tableRef = null
  boCaps = getConstants().bo_caps
  constructor(props) {
    super(props)
    this.tableRef = React.createRef()
  }

  actions = [
    {
      icon: 'fa-edit',
      text: 'Modifica',
      onClick: rowData => {
        let { id, username, first_name, last_name, email, enabled, caps, is_management_account } = rowData
        this.setState({ mode: 'user' })
        this.props.dispatch(
          setCurrentUser({
            id,
            username,
            first_name,
            last_name,
            email,
            enabled,
            caps,
            is_management_account,
          })
        )
        this.props.dispatch(toggleShowForm())
      },
    },
    {
      icon: 'fa-store',
      text: 'Punti vendita',
      onClick: rowData => {
        let { id, username, first_name, last_name, email, enabled, caps, is_management_account, stores } = rowData
        this.setState({ mode: 'stores' })
        this.props.dispatch(
          setCurrentUser({
            id,
            username,
            first_name,
            last_name,
            email,
            enabled,
            caps,
            is_management_account,
            stores,
          })
        )
        this.props.dispatch(toggleShowForm())
      },
    },
    {
      icon: 'fa-key',
      text: 'Reset password',
      onClick: rowData => {
        let { id, username, first_name, last_name, email, enabled, caps } = rowData
        this.props.dispatch(setCurrentUser({ id, username, first_name, last_name, email, enabled, caps }))
        this.setState({ show_reset_password: true })
      },
    },
    {
      icon: 'fa-trash-alt',
      text: 'Elimina',
      disabled: () => this.props.users.length === 1,
      onClick: rowData => {
        let { id, username, first_name, last_name, email, enabled, caps } = rowData
        this.props.dispatch(setCurrentUser({ id, username, first_name, last_name, email, enabled, caps }))
        this.setState({ show_modal: true })
      },
    },
  ]

  actionsRenderer = ({ rowData }) => {
    let actions = this.actions
    if (rowData.is_management_account === true) {
      actions = this.actions.filter(a => a.icon !== 'fa-store')
    }
    return <ActionsDropdown rowData={rowData} actions={actions} />
  }

  capsRenderer = ({ rowData }) => {
    let items = []
    for (const c in this.boCaps) {
      if (rowData.caps[c]) items.push(this.boCaps[c])
    }
    return items.join(', ')
  }

  columns = [
    {
      title: '',
      className: 'd-lg-block text-center',
      style: { width: '6%', minWidth: '3rem' },
      data: this.actionsRenderer,
    },
    {
      title: 'Username',
      className: 'd-lg-block flex-fill',
      style: { width: '12%' },
      sortable: true,
      data: 'username',
    },
    {
      title: 'Email',
      className: 'd-none d-lg-block flex-fill',
      style: { width: '20%' },
      sortable: true,
      data: 'email',
    },
    {
      title: 'Nome',
      className: 'd-none d-lg-block text-center',
      style: { width: '15%' },
      sortable: true,
      data: 'first_name',
    },
    {
      title: 'Cognome',
      className: 'd-none d-lg-block text-center',
      style: { width: '15%' },
      sortable: true,
      data: 'last_name',
    },
    {
      title: 'Amministratore',
      className: 'd-none d-lg-block text-center',
      style: { width: '12%' },
      sortable: false,
      data: ({ rowData }) => (rowData.is_management_account ? 'Si' : 'No'),
    },
    {
      title: 'Bloccato',
      className: 'd-none d-lg-block text-center',
      style: { width: '10%', minWidth: '7rem' },
      sortable: true,
      sortingKey: 'enabled',
      data: ({ rowData }) => (rowData.enabled ? 'No' : 'Si'),
    },
    {
      title: 'Creato/Aggiornato da',
      className: 'd-none d-lg-block text-center',
      style: { width: '15%' },
      data: ({ rowData }) => `${rowData?.created_by ?? '-'}/${rowData?.updated_by ?? '-'}`,
    },
  ]

  onFilterChange = filter => this.setState({ filter })

  getData = async (index = 0, chunkSize, sortData, filter) => {
    await this.props.dispatch(getUsers({ offset: index, limit: chunkSize, sortData, filter }))
    await this.props.dispatch(retrieveTenants())
  }

  saveUser = async user => {
    user.id ? await this.props.dispatch(updateUser({ user })) : await this.props.dispatch(createUser({ user }))
    this.tableRef.current.refreshData()
  }

  saveAssociatedStores = async user => {
    user.stores = Object.keys(user.stores).filter(key => user.stores[key].checked === true)
    await this.props.dispatch(saveAssociatedStores({ user }))
    this.tableRef.current.refreshData()
  }

  onToggleForm = () => {
    if (this.props.show_form === true) {
      this.props.dispatch(resetCurrentUser())
    }
    this.setState({ mode: 'user' })
    this.props.dispatch(toggleShowForm())
  }

  onCancel = () => {
    this.props.dispatch(resetCurrentUser())
    this.props.dispatch(resetLoginData())
    this.setState({ show_modal: false, show_reset_password: false })
  }

  onConfirmDelete = async () => {
    await this.props.dispatch(deleteUser({ id: this.props.current_user.id }))
    this.tableRef.current.refreshData()
    this.setState({ show_modal: false })
  }

  componentDidMount() {
    this.props.dispatch(resetCurrentTenant())
    this.props.dispatch(resetAclManager())
    this.props.dispatch(resetRoleManager())
    this.props.dispatch(resetTenants())
  }

  componentWillUnmount() {
    this.props.dispatch(resetUsers())
  }

  render() {
    let { users, current_user, show_form, total, stores } = this.props
    let menuContent = () => null
    let menuContentProps = {}

    switch (this.state.mode) {
      case 'user':
        menuContent = UserForm
        menuContentProps = {
          is_management_account: this.props.is_management_account,
          user: current_user,
          onSave: this.saveUser,
          closeForm: this.onToggleForm,
        }
        break
      case 'stores':
        menuContent = AssociatedStoresForm
        menuContentProps = {
          user: current_user,
          storesList: stores,
          onSave: this.saveAssociatedStores,
          closeForm: this.onToggleForm,
        }
        break
      default:
    }
    return (
      <div className="container-xxl flex-fill d-flex flex-column">
        <Card className="flex-fill">
          <Card.Body className="d-flex flex-column">
            <Card.Title className="border-bottom">
              <h3>Utenti amministratori</h3>
            </Card.Title>
            <Card.Text as="div" className="flex-fill d-flex flex-column">
              <div className="container-fluid px-0 mb-2">
                <div className="row">
                  <div className="col d-grid d-md-block">
                    <button
                      className="btn btn-primary btn-block"
                      onClick={this.onToggleForm}
                      disabled={this.props.show_form}>
                      <i className="fas fa-plus fa-fw text-start" />
                      Aggiungi utente
                    </button>
                  </div>
                  <div className="d-none d-md-block col-md-2 col-lg-4"></div>
                  <div className="mt-2 mt-md-0 col-12 col-md-6 col-lg-4">
                    <SearchButton
                      value={this.state.filter}
                      onChange={this.onFilterChange}
                      disabled={this.props.show_form}
                      className="float-md-end"
                    />
                  </div>
                </div>
              </div>
              <ResponsiveTable
                ref={this.tableRef}
                className="flex-fill"
                data={users}
                getData={this.getData}
                totalRecords={total}
                columns={this.columns}
                showMenu={show_form}
                menuWidth="40rem"
                menuContent={menuContent}
                menuContentProps={menuContentProps}
                rowClasses={rowData => (rowData.enabled ? '' : `text-muted`)}
                filter={this.state.filter}
              />

              <Modal backdrop="static" centered show={this.state.show_modal} onHide={this.onCancel}>
                <Modal.Header>
                  <Modal.Title>Elimina utente amministratore</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>
                    Per procedere all'eliminazione dell'utente <b>{this.props.current_user?.username}</b>, cliccare sul
                    bottone "Elimina"
                  </p>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={this.onCancel} variant="secondary">
                    Annulla
                  </Button>
                  <Button onClick={this.onConfirmDelete} variant="danger">
                    Elimina
                  </Button>
                </Modal.Footer>
              </Modal>
            </Card.Text>
          </Card.Body>
        </Card>
        {this.state.show_reset_password && (
          <ResetPasswordModal
            show={this.state.show_reset_password}
            username={current_user.username}
            onCancel={this.onCancel}
          />
        )}
      </div>
    )
  }
}
export default connect(mstp)(Users)
