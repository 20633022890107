import React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { withRouter } from 'src/lib/router/with_router'
import { Card } from 'react-bootstrap'
import { Transition } from 'react-transition-group'
import { Outlet } from 'react-router-dom'
import Sidebar from 'src/components/drugstore/tenant_management/sidebar'
import Header from 'src/components/drugstore/tenant_management/header'
import TenantsList from 'src/components/drugstore/tenants_list'

const duration = 300
const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
}
const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
}

const mstp = state => {
  let { name } = state.storeManager.current_tenant
  return {
    name,
  }
}

class AppLayout extends React.PureComponent {
  static defaultProps = {
    containerSize: 'fluid',
    pageTitle: '',
    showSidebar: true,
  }

  state = { ready: true }

  nodeRef = React.createRef()

  // async componentDidUpdate(prevProps) {

  //   if (prevProps.tenant !== this.props.tenant) {
  //   }
  // }

  render() {
    let { pageTitle, showSidebar } = this.props

    return (
      <>
        <style jsx="true">{`
          .global-sidebar {
            width: 22.5rem;
            min-width: 22.5rem;
          }
          body {
            background-image: url(/17545.jpg);
            background-size: contain;
          }
        `}</style>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <div className={`container-fluid flex-fill d-flex flex-column`}>
          <Card className="flex-fill with-shadow" style={{ borderRadius: 'var(--bs-border-radius)', border: 'none' }}>
            <Card.Body className="d-flex flex-column">
              <Card.Title as="div" className="p-0">
                <Header />
              </Card.Title>
              <Card.Text as="div" className="d-flex flex-column flex-fill">
                <div className="container-fluid mx-0 px-0 d-flex flex-fill">
                  <div className="p-0 d-none d-lg-flex global-sidebar">
                    <TenantsList mountedOnMenu={true} mountedOnMenuPage={true} />
                  </div>
                  <div className="d-flex flex-column flex-fill">
                    {showSidebar === true && (
                      <div className="p-0 d-flex mb-3 ms-lg-3">
                        <Sidebar />
                      </div>
                    )}
                    <Transition in={this.state.ready} timeout={duration} nodeRef={this.nodeRef}>
                      {state => (
                        <div
                          ref={this.nodeRef}
                          className="p-0 flex-fill d-flex flex-column"
                          style={{
                            ...defaultStyle,
                            ...transitionStyles[state],
                          }}>
                          {this.state.ready && <Outlet {...this.props} />}
                        </div>
                      )}
                    </Transition>
                  </div>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      </>
    )
  }
}

export default connect(mstp)(withRouter(AppLayout))
