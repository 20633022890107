import React from 'react'
import { connect } from 'react-redux'
import withJWTSession from 'src/enhancers/jwt_session'
import Sales from 'src/components/drugstore/tenant_management/sales'
import withCapabilities from 'src/enhancers/with_capabilities'

const mstp = state => ({ userInfo: state.userInfo })

class SalePage extends React.Component {
  render() {
    return <Sales />
  }
}
export default withJWTSession({ invalid: '/drugstore/login' })(
  connect(mstp)(
    withCapabilities({
      some: ['bo_show_sales'],
    })(SalePage)
  )
)
