import * as React from 'react'
import { Button, Form, Alert } from 'react-bootstrap'
import { updateFormData, tryUpdatePasswordUsername } from 'src/store/login'
import styles from 'src/components/system_wide/login_form/login_form.module.css'

class UpdatePasswordVariant extends React.Component {
  handleChange = ({ target }) => {
    let { name, value } = target
    this.props.dispatch(updateFormData({ name, value }))
  }

  isUpdateFormValid = () => {
    let { new_password, confirm_password } = this.props.loginData
    return new_password !== '' && confirm_password !== '' && new_password === confirm_password
  }

  handleSubmitUpdatePwd = async () => {
    let { new_password, confirm_password } = this.props.loginData
    await this.props.dispatch(
      tryUpdatePasswordUsername({
        new_password,
        confirm_password,
        username: this.props.username,
      })
    )
  }

  render() {
    let { message, message_type, new_password, confirm_password, is_weak_password, loading } = this.props.loginData

    return (
      <div className={`card ${styles.content} pt-3`}>
        <div className="card-body">
          <div className="d-flex flex-row gap-2 align-items-center mb-2 text-center"></div>
          <div className="mb-5">
            <h4 className="mb-3 text-center">{this.props.title}</h4>
          </div>
          {!is_weak_password && (
            <Alert variant={message_type} show={message !== ''} transition={false}>
              {message}
            </Alert>
          )}
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Nuova password</Form.Label>
              <Form.Control
                type="password"
                name="new_password"
                autoComplete="new-password"
                id="new_password"
                value={new_password}
                isInvalid={is_weak_password}
                onChange={this.handleChange}
              />
              <Form.Control.Feedback type="invalid">{message}</Form.Control.Feedback>
              <Form.Text>Minimo 8 caratteri, almeno 1 minuscola, 1 maiuscolo e 1 numero</Form.Text>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Conferma password</Form.Label>
              <Form.Control
                type="password"
                name="confirm_password"
                autoComplete="off"
                id="confirm_password"
                value={confirm_password}
                onChange={this.handleChange}
              />
            </Form.Group>
            <div className="mt-5">
              <Button
                style={{ minWidth: '6rem' }}
                color="primary"
                size="md"
                name="update_pwd"
                disabled={!this.isUpdateFormValid() || loading === true}
                onClick={this.handleSubmitUpdatePwd}>
                {loading === true && (
                  <>
                    <span className="spinner-border spinner-border-sm"></span> Aggiorna
                  </>
                )}
                {loading === false && 'Aggiorna'}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    )
  }
}

export default UpdatePasswordVariant
