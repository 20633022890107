import React from 'react'
import { Button } from 'react-bootstrap'

import axios from 'src/lib/axios'

export const downloadFile = async ({
  url,
  method = 'get',
  params = {},
  filename = 'export',
  use_server_headers = false,
}) => {
  let response = await axios(url, {
    method,
    [method.toLowerCase() === 'get' ? 'params' : 'data']: params,
    responseType: 'arraybuffer',
  })

  let type = 'application/octect-stream'
  if (response.headers['content-type']) {
    type = response.headers['content-type']
  }
  let fname = filename
  if (use_server_headers && response.headers['content-disposition']) {
    fname = response.headers['content-disposition'].replace(/\w+;\s*filename=["]?([^"]*)["]?/, '$1')
  }

  let reader = new FileReader()
  let blob = new Blob([response.data], { type })
  reader.onload = function () {
    let element = document.createElement('a')
    if (!document.body) throw new Error('No document body!')
    document.body.appendChild(element)
    element.setAttribute('href', reader.result)
    element.setAttribute('download', fname)
    element.style.display = ''
    element.click()

    document.body.removeChild(element)
  }
  reader.readAsDataURL(blob)
}

class FileDownloaderButton extends React.Component {
  static defaultProps = {
    variant: 'primary',
    disabled: false,
    method: 'get',
    onStartDownload: () => {},
    onDownloadCompleted: () => {},
    onError: () => {},
    downloadUrl: '',
    downloadParams: {},
    fileName: 'data',
    label: 'Download',
    useServerHeaders: false,
  }

  state = {
    is_pending: false,
  }

  startDownload = async () => {
    this.props.onStartDownload()
    try {
      if (this.state.is_pending) return
      this.setState({ is_pending: true })

      let { downloadUrl, method, downloadParams, fileName, useServerHeaders } = this.props
      await downloadFile({
        url: downloadUrl,
        method,
        params: downloadParams,
        filename: fileName,
        use_server_headers: useServerHeaders,
      })

      this.setState({ is_pending: false })
      await this.props.onDownloadCompleted()
    } catch (err) {
      this.setState({ is_pending: false })
      this.props.onError()
      console.log('Errore di download', err)
    }
  }

  render() {
    /* eslint-disable no-unused-vars */
    let {
      downloadUrl,
      fileName,
      downloadParams,
      onStartDownload,
      onDownloadCompleted,
      onError,
      useServerHeaders,
      disabled,
      ...props
    } = this.props
    /* eslint-enable no-unused-vars */

    return (
      <Button {...props} onClick={this.startDownload} disabled={this.state.is_pending || disabled}>
        {this.state.is_pending === false && <i className="fas fa-download fa-fw text-start" />}
        {this.state.is_pending && (
          <>
            <span className="spinner-border spinner-border-sm"></span>{' '}
          </>
        )}
        {this.props.label}
      </Button>
    )
  }
}

export default FileDownloaderButton
