import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { error, success } from 'src/components/system_wide/notification'
import axios from 'src/lib/axios'

const initialState = {
  total: 0,
  maintenance_types: [],
  show_form: false,
  current_maintenance_type: {
    id: null,
    name: '',
  },
  emails: '',
}

export const getAllMaintenanceTypes = createAsyncThunk('maintenanceType/getAllMaintenanceTypes', async thunkApi => {
  try {
    let response = await axios({ url: `/api/maintenance_types/all` })
    return response.data
  } catch (err) {
    error('Impossibile recuperare lista dei tipi di manutenzione')
    return thunkApi.rejectWithValue()
  }
})

export const getMaintenanceTypes = createAsyncThunk(
  'maintenanceType/getMaintenanceTypes',
  async ({ offset, limit, sortData, filter }, thunkApi) => {
    try {
      let { total: currentTotal } = thunkApi.getState().maintenanceTypes
      if (offset > currentTotal) return

      let { sort_column, sort_direction } = sortData
      let response = await axios({
        url: `/api/maintenance_types`,
        params: { offset, limit, sort_column, sort_direction, filter },
      })
      let { total, maintenance_types } = response.data
      return { total, maintenance_types, reset: offset === 0 }
    } catch (err) {
      error('Impossibile recuperare lista dei tipi di manutenzione')
      return thunkApi.rejectWithValue()
    }
  }
)

export const createMaintenanceType = createAsyncThunk(
  'maintenanceTypes/createMaintenanceType',
  async ({ maintenance_type }, thunkApi) => {
    try {
      let { name } = maintenance_type
      await axios({
        url: `/api/maintenance_type`,
        method: 'post',
        data: { name },
      })
      success('Tipo di manutenzione creato con successo')
      thunkApi.dispatch(resetCurrentMaintenanceType())
      thunkApi.dispatch(toggleShowForm())
    } catch (err) {
      error('Impossibile creare il tipo di manutenzione')
      return thunkApi.rejectWithValue()
    }
  }
)

export const updateMaintenanceType = createAsyncThunk(
  'maintenanceTypes/updateMaintenanceType',
  async ({ maintenance_type }, thunkApi) => {
    try {
      let { id, name } = maintenance_type
      await axios({
        url: `/api/maintenance_type/${id}`,
        method: 'patch',
        data: { name },
      })
      success('Tipo di manutenzione aggiornato con successo')
      thunkApi.dispatch(resetCurrentMaintenanceType())
      thunkApi.dispatch(toggleShowForm())
    } catch (err) {
      error('Impossibile aggiornare il tipo di manutenzione')
      return thunkApi.rejectWithValue()
    }
  }
)

export const deleteMaintenanceType = createAsyncThunk(
  'maintenanceTypes/deleteMaintenanceType',
  async ({ id }, thunkApi) => {
    try {
      await axios({ url: `/api/maintenance_type/${id}`, method: 'delete' })
      success('Tipo di manutenzione eliminato con successo')
      thunkApi.dispatch(resetCurrentMaintenanceType())
    } catch (err) {
      error('Impossibile eliminare il tipo di manutenzione')
      return thunkApi.rejectWithValue()
    }
  }
)

export const getNotificationEmail = createAsyncThunk('maintenanceTypes/getNotificationEmail', async (_, thunkApi) => {
  try {
    let response = await axios({
      url: `/api/notifications/`,
      method: 'get',
    })
    return response?.data?.notifications ?? ''
  } catch (err) {
    error('Impossibile recuperare email di notifica')
    return thunkApi.rejectWithValue()
  }
})

export const updateNotificationEmail = createAsyncThunk(
  'maintenanceTypes/updateNotificationEmail',
  async ({ emails }, thunkApi) => {
    try {
      await axios({
        url: `/api/notification`,
        method: 'patch',
        data: { emails },
      })
      success('Email di notifica salvate con successo')
    } catch (err) {
      error('Impossibile salvare le email di notifica')
      return thunkApi.rejectWithValue()
    }
  }
)

export const maintenanceTypesSlice = createSlice({
  name: 'maintenanceTypes',
  initialState,

  reducers: {
    setCurrentMaintenanceType: (state, action) => {
      let { id, name } = action.payload
      state.current_maintenance_type.id = id
      state.current_maintenance_type.name = name
    },
    toggleShowForm: state => {
      state.show_form = !state.show_form
    },
    resetCurrentMaintenanceType: state => {
      state.current_maintenance_type = initialState.current_maintenance_type
    },
    resetMaintenanceTypes: () => initialState,
  },

  extraReducers: {
    [getAllMaintenanceTypes.fulfilled]: (state, action) => {
      if (!action?.payload) return
      state.maintenance_types = [...action.payload.maintenance_types]
    },
    [getMaintenanceTypes.fulfilled]: (state, action) => {
      if (!action?.payload) return
      let { total, maintenance_types } = action.payload
      state.total = total
      state.maintenance_types = action.payload.reset
        ? [...maintenance_types]
        : [...state.maintenance_types, ...maintenance_types]
    },
    [getMaintenanceTypes.rejected]: state => {
      state.total = initialState.total
      state.maintenance_types = initialState.maintenance_types
    },
    [getNotificationEmail.fulfilled]: (state, action) => {
      if (!action?.payload) return
      state.emails = action.payload.emails
    },
  },
})

export const { setCurrentMaintenanceType, resetCurrentMaintenanceType, toggleShowForm, resetMaintenanceTypes } =
  maintenanceTypesSlice.actions
export default maintenanceTypesSlice.reducer
