import React from 'react'
import { Button, Form } from 'react-bootstrap'
import isEmail from 'validator/lib/isEmail'
import hash from 'object-hash'

class ManagerEmployeeForm extends React.Component {
  state = {
    id: null,
    name: '',
    surname: '',
    description: '',
    email: '',
    phone_1: '',
    phone_2: '',
    phone_3: '',

    employee_hash: '',
  }

  initialHash = ''

  constructor(props) {
    super(props)

    if (props.employee.id) {
      let { id, name, surname, description, email, phone_1, phone_2, phone_3 } = props.employee
      this.state.id = id ?? null
      this.state.name = name ?? ''
      this.state.surname = surname ?? ''
      this.state.description = description ?? ''
      this.state.email = email ?? ''
      this.state.phone_1 = phone_1 ?? ''
      this.state.phone_2 = phone_2 ?? ''
      this.state.phone_3 = phone_3 ?? ''
    }

    // eslint-disable-next-line no-unused-vars
    let { employee_hash, ...rest } = this.state
    this.initialHash = hash(rest)
    this.state.employee_hash = this.initialHash
  }

  updateHash = () => {
    // eslint-disable-next-line no-unused-vars
    let { employee_hash, ...rest } = this.state
    this.setState({ employee_hash: hash(rest) })
  }

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value }, () => this.updateHash())
  }

  handleConfirm = () => {
    // eslint-disable-next-line no-unused-vars
    let { employee_hash, ...rest } = this.state
    this.props.onSave(rest)
  }

  onChangeNumericInput = (value, rawValue, { name }) => {
    this.setState({ [name]: value }, () => this.updateHash())
  }

  isEmailInvalid = prop => {
    let current_value = ''
    current_value = this.state?.[prop] ?? ''
    return current_value !== '' && !isEmail(current_value)
  }

  isFormValid = () => {
    let { name, surname } = this.state
    return name !== '' || surname !== ''
  }

  render() {
    let title = this.state.id ? 'Modifica contatto' : 'Crea contatto'
    let { name, surname, description, email, phone_1, phone_2, phone_3 } = this.state
    let is_email_invalid = this.isEmailInvalid('email')
    return (
      <>
        <h4 className="border-bottom text-primary">{title}</h4>
        <div className="flex-fill position-relative overflow-auto">
          <div className="w-100 ps-1 pe-4 position-absolute">
            <Form.Group className="mb-3 col-12 col-md-6">
              <Form.Label>Nome</Form.Label>
              <Form.Control name="name" placeholder="Nome" value={name} onChange={this.handleChange} />
            </Form.Group>
            <Form.Group className="mb-3 col-12 col-md-6">
              <Form.Label>Cognome</Form.Label>
              <Form.Control name="surname" placeholder="Cognome" value={surname} onChange={this.handleChange} />
            </Form.Group>
            <Form.Group className="mt-3 mb-3">
              <Form.Label>Descrizione</Form.Label>
              <Form.Control
                name="description"
                as="textarea"
                rows={2}
                value={description}
                onChange={this.handleChange}
              />
            </Form.Group>
            <Form.Group className="mt-3 mb-3 col-12 col-md-6">
              <Form.Label>Email</Form.Label>
              <Form.Control
                name="email"
                placeholder="Email"
                value={email}
                onChange={this.handleChange}
                isInvalid={is_email_invalid}
              />
              <Form.Control.Feedback type="invalid">Indirizzo e-mail non valida</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3 col-12 col-md-6">
              <Form.Label>Telefono(1)</Form.Label>
              <Form.Control name="phone_1" placeholder="" value={phone_1} onChange={this.handleChange} />
            </Form.Group>
            <Form.Group className="mb-3 col-12 col-md-6">
              <Form.Label>Telefono(2)</Form.Label>
              <Form.Control name="phone_2" placeholder="" value={phone_2} onChange={this.handleChange} />
            </Form.Group>
            <Form.Group className="mb-3 col-12 col-md-6">
              <Form.Label>Telefono(3)</Form.Label>
              <Form.Control name="phone_3" placeholder="" value={phone_3} onChange={this.handleChange} />
            </Form.Group>
          </div>
        </div>
        <div className="border-top pt-2">
          <Button
            className="float-end"
            variant="primary"
            disabled={!this.isFormValid() || this.initialHash === this.state.employee_hash}
            onClick={this.handleConfirm}>
            Conferma
          </Button>
          <Button className="float-end me-2" variant="secondary" onClick={this.props.closeForm}>
            Annulla
          </Button>
        </div>
      </>
    )
  }
}
export default ManagerEmployeeForm
