import React from 'react'
import { Card } from 'react-bootstrap'
import { Transition } from 'react-transition-group'
import Sidebar from 'src/components/drugstore/tenant_management/sidebar'
import Header from 'src/components/drugstore/tenant_management/header'
import TenantsList from 'src/components/drugstore/tenants_list'
import withJWTSession from 'src/enhancers/jwt_session'

class IndexPage extends React.Component {
  render() {
    return (
      <div className={`container-fluid flex-fill d-flex flex-column`}>
        <Card className="flex-fill with-shadow" style={{ borderRadius: 'var(--bs-border-radius)', border: 'none' }}>
          <Card.Body className="d-flex flex-column">
            <Card.Title as="div" className="p-0">
              <Header />
            </Card.Title>
            <Card.Text as="div" className="d-flex flex-column flex-fill">
              <div className="container-fluid mx-0 px-0 d-flex flex-fill">
                <div className="p-0 d-none d-lg-flex global-sidebar">
                  <TenantsList mountedOnMenu={true} mountedOnMenuPage={true} />
                </div>
                <div className="d-flex flex-column flex-fill">
                  <div className="p-0 d-flex mb-3 ms-lg-3">
                    <Sidebar />
                  </div>

                  <div>Seleziona un punto vendita</div>
                </div>
              </div>
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    )
  }
}
export default withJWTSession({ invalid: '/drugstore/login' })(IndexPage)
