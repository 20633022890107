import React from 'react'
import { connect } from 'react-redux'

import withJWTSession from 'src/enhancers/jwt_session'
import RemoveTenant from 'src/components/drugstore/tenant_management/remove_tenant'
import withCapabilities from 'src/enhancers/with_capabilities'

const mstp = state => ({ userInfo: state.userInfo })

class RemoveTenantPage extends React.Component {
  render() {
    return <RemoveTenant />
  }
}
export default withJWTSession({ invalid: '/drugstore/login' })(
  connect(mstp)(
    withCapabilities({
      some: ['bo_delete_tenant'],
    })(RemoveTenantPage)
  )
)
