import React from 'react'
import { Button, Form } from 'react-bootstrap'
import StyledSelect from 'src/components/system_wide/styled_select'

import NumericInput from 'react-numeric-input'
import hash from 'object-hash'

class MaintenanceForm extends React.Component {
  static defaultProps = {
    maintenanceTypes: [],
    onSave: () => {},
    closeForm: () => {},
  }

  state = {
    id: null,
    description: '',
    description_pending: '',
    description_closed: '',
    costs: 0,
    status: '',
    maintenance_type_id: '',

    maintenance_type_options: [],
    maintenance_hash: '',
  }

  initialHash = ''

  constructor(props) {
    super(props)
    if (props.maintenance.id) {
      let { id, description, description_pending, description_closed, status, costs, maintenance_type_id } =
        props.maintenance
      this.state.id = id
      this.state.status = status
      this.state.description = description
      this.state.description_pending = description_pending
      this.state.description_closed = description_closed
      this.state.maintenance_type_id = maintenance_type_id
      this.state.costs = costs
    } else {
      this.state.status = 'opened'
    }
    this.state.maintenance_type_options = props.maintenanceTypes.map(t => ({ label: t.name, value: t.id }))

    // eslint-disable-next-line no-unused-vars
    let { maintenance_hash, ...rest } = this.state
    this.initialHash = hash(rest)
    this.state.maintenance_hash = this.initialHash
  }

  updateHash = () => {
    // eslint-disable-next-line no-unused-vars
    let { maintenance_hash, ...rest } = this.state
    this.setState({ maintenance_hash: hash(rest) })
  }

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value }, () => this.updateHash())
  }

  onChangeNumericInput = (value, rawValue, { name }) => {
    this.setState({ [name]: value }, () => this.updateHash())
  }

  handleSelectChange = (option, { name }) => {
    this.setState({ [name]: option ? option.value : null }, () => this.updateHash())
  }

  handleConfirm = () => {
    // eslint-disable-next-line no-unused-vars
    let { maintenance_hash, ...rest } = this.state
    this.props.onSave(rest)
  }

  isFormValid = () => {
    let { description, maintenance_type_id, status } = this.state
    return status !== '' && description !== '' && maintenance_type_id !== ''
  }

  render() {
    let title = this.state.id ? 'Modifica manutenzione' : 'Crea manutenzione'
    let {
      status,
      description,
      description_pending,
      description_closed,
      costs,
      maintenance_type_id,
      maintenance_type_options,
    } = this.state
    let status_options = [
      { label: 'Aperto', value: 'opened' },
      { label: 'Lavorazione', value: 'pending' },
      { label: 'Chiuso', value: 'closed' },
    ]
    return (
      <>
        <h4 className="border-bottom text-primary">{title}</h4>
        <div className="flex-fill position-relative overflow-auto">
          <div className="w-100 ps-1 pe-4 position-absolute">
            <Form.Group className="mb-3">
              <Form.Label>
                Tipologia
                <span> *</span>
              </Form.Label>
              <StyledSelect
                name="maintenance_type_id"
                isClearable
                placeholder="Tipologia intevento"
                value={maintenance_type_options.find(o => o.value === maintenance_type_id)}
                onChange={this.handleSelectChange}
                options={maintenance_type_options}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                Stato
                <span> *</span>
              </Form.Label>
              <StyledSelect
                name="status"
                isClearable
                placeholder="Tipologia intevento"
                value={status_options.find(o => o.value === status)}
                onChange={this.handleSelectChange}
                options={status_options}
              />
            </Form.Group>
            <Form.Group className="mt-3 mb-3">
              <Form.Label>Descrizione apertura ticket</Form.Label>
              <span> *</span>
              <Form.Control
                name="description"
                as="textarea"
                rows={2}
                value={description}
                onChange={this.handleChange}
              />
            </Form.Group>
            <Form.Group className="mt-3 mb-3">
              <Form.Label>Descrizione stato avanzamento</Form.Label>
              <Form.Control
                name="description_pending"
                as="textarea"
                rows={2}
                value={description_pending}
                onChange={this.handleChange}
              />
            </Form.Group>
            <Form.Group className="mt-3 mb-3">
              <Form.Label>Descrizione chiusura</Form.Label>
              <Form.Control
                name="description_closed"
                as="textarea"
                rows={2}
                value={description_closed}
                onChange={this.handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Costo</Form.Label>
              <div style={{ width: '10rem' }}>
                <NumericInput
                  className="form-control form-control-sm"
                  precision={2}
                  min={0}
                  step={0.5}
                  snap
                  inputMode="decimal"
                  strict
                  name="costs"
                  value={costs}
                  onChange={this.onChangeNumericInput}
                />
              </div>
            </Form.Group>
          </div>
        </div>
        <div className="border-top pt-2">
          <Button
            className="float-end"
            variant="primary"
            disabled={!this.isFormValid() || this.initialHash === this.state.maintenance_hash}
            onClick={this.handleConfirm}>
            Conferma
          </Button>
          <Button className="float-end me-2" variant="secondary" onClick={this.props.closeForm}>
            Annulla
          </Button>
        </div>
      </>
    )
  }
}
export default MaintenanceForm
