import React from 'react'
import { connect } from 'react-redux'
import { Modal, Button, Alert } from 'react-bootstrap'
import { format, parseISO } from 'date-fns'
import ActionsDropdown from 'src/components/system_wide/actions_dropdown'
import SearchButton from 'src/components/system_wide/search_button'
import ResponsiveTable from 'src/components/system_wide/responsive_table'
import DocumentsForm from 'src/components/system_wide/documents_form'
import DeadlineForm from 'src/components/drugstore/tenant_management/deadlines/deadline_form'
import DeadlineProcessed from 'src/components/drugstore/tenant_management/deadlines/deadline_processed'
import {
  setCurrentDeadline,
  createDeadline,
  updateDeadline,
  deleteDeadline,
  toggleShowForm,
  getDeadlines,
  resetCurrentDeadline,
  resetDeadlines,
} from 'src/store/deadlines'
import it from 'date-fns/locale/it'

const mstp = state => {
  let { name: tenant_name } = state.storeManager.current_tenant
  let { documents, total: total_documents } = state.documents
  let { total, deadlines, show_form, current_deadline, deadlines_processed, processed_total } = state.deadlines
  return {
    tenant_name,
    total,
    deadlines,
    show_form,
    current_deadline,
    deadlines_processed,
    processed_total,
    documents,
    total_documents,
  }
}

class Deadlines extends React.Component {
  state = {
    show_modal: false,
    filter: '',
    mode: 'edit',
  }

  tableRef = null
  constructor(props) {
    super(props)
    this.tableRef = React.createRef()
  }

  actions = [
    {
      icon: 'fa-edit',
      text: 'Modifica',
      onClick: rowData => {
        this.setState({ mode: 'edit' })
        let { id, description, recipients, expiry_date, recurring, recurring_type } = rowData
        this.props.dispatch(setCurrentDeadline({ id, description, recipients, expiry_date, recurring, recurring_type }))
        this.props.dispatch(toggleShowForm())
      },
    },
    {
      icon: 'fa-eye',
      text: 'Processati',
      onClick: rowData => {
        this.setState({ mode: 'processed' })
        let { id, description, recipients, expiry_date, recurring, recurring_type } = rowData
        this.props.dispatch(setCurrentDeadline({ id, description, recipients, expiry_date, recurring, recurring_type }))
        this.props.dispatch(toggleShowForm())
      },
    },
    {
      icon: 'fa-folder-open',
      text: 'Documenti',
      onClick: rowData => {
        let { id, description, recipients, expiry_date, recurring, recurring_type } = rowData
        this.props.dispatch(setCurrentDeadline({ id, description, recipients, expiry_date, recurring, recurring_type }))
        this.setState({ mode: 'document' })
        this.props.dispatch(toggleShowForm())
      },
    },
    {
      icon: 'fa-trash-alt',
      text: 'Elimina',
      onClick: rowData => {
        let { id, description, recipients, expiry_date, recurring, recurring_type } = rowData
        this.props.dispatch(setCurrentDeadline({ id, description, recipients, expiry_date, recurring, recurring_type }))
        this.setState({ show_modal: true })
      },
    },
  ]

  actionsRenderer = ({ rowData }) => {
    return <ActionsDropdown rowData={rowData} actions={this.actions} />
  }

  columns = [
    {
      title: '',
      className: 'd-lg-block text-center',
      style: { width: '6%', minWidth: '3rem' },
      data: this.actionsRenderer,
    },
    {
      title: 'Descrizione',
      className: 'd-lg-block flex-fill',
      style: { width: '12%' },
      sortable: true,
      data: 'description',
    },
    {
      title: 'Destinatari',
      className: 'd-none d-lg-block flex-fill',
      style: { width: '20%' },
      sortable: true,
      data: ({ rowData: { recipients } }) => {
        return <div className="text-truncate">{recipients}</div>
      },
    },
    {
      title: 'Scadenza',
      className: 'd-none d-lg-block',
      style: { width: '15%' },
      sortable: true,
      data: ({ rowData: { expiry_date, recurring, recurring_type } }) => {
        let formate = 'dd-MM-yyyy'
        if (recurring === true && recurring_type === 'monthly') {
          formate = 'dd MMMM yyyy'
        } else if (recurring === true && recurring_type === 'yearly') {
          formate = 'dd MMMM'
        }
        let date = expiry_date ? format(parseISO(expiry_date), formate, { locale: it }) : ''
        return date
      },
    },
    {
      title: 'Ricorrente',
      className: 'd-none d-lg-block',
      style: { width: '15%' },
      sortable: true,
      data: ({ rowData: { recurring, recurring_type } }) => {
        return (
          <div>
            {recurring === true && <div>{`${recurring_type === 'monthly' ? 'Mensile' : 'Annuale'}`}</div>}
            {recurring === false && 'No'}
          </div>
        )
      },
    },
    {
      title: 'Creato/Aggiornato da',
      className: 'd-none d-lg-block text-center',
      style: { width: '12%' },
      data: ({ rowData }) => `${rowData?.created_by ?? '-'}/${rowData?.updated_by ?? '-'}`,
    },
  ]

  onFilterChange = filter => this.setState({ filter })

  getData = async (index = 0, chunkSize, sortData, filter) => {
    await this.props.dispatch(
      getDeadlines({ tenant: this.props.tenant_name, offset: index, limit: chunkSize, sortData, filter })
    )
  }

  saveDeadline = async deadline => {
    deadline.id
      ? await this.props.dispatch(updateDeadline({ tenant: this.props.tenant_name, deadline }))
      : await this.props.dispatch(createDeadline({ tenant: this.props.tenant_name, deadline }))
    this.tableRef.current.refreshData()
  }

  onToggleForm = () => {
    if (this.props.show_form === true) {
      this.props.dispatch(resetCurrentDeadline())
    }
    this.props.dispatch(toggleShowForm())
  }

  onCancel = () => {
    this.props.dispatch(resetCurrentDeadline())
    this.setState({ show_modal: false })
  }

  onConfirmDelete = async () => {
    await this.props.dispatch(deleteDeadline({ tenant: this.props.tenant_name, id: this.props.current_deadline.id }))
    this.tableRef.current.refreshData()
    this.setState({ show_modal: false })
  }

  componentDidMount() {}

  async componentDidUpdate(prevProps) {
    if (prevProps.tenant_name !== this.props.tenant_name) {
      if (this.props.tenant_name) {
        this.props.dispatch(resetDeadlines())
        await this.getData()
      }
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetDeadlines())
  }

  render() {
    let { mode } = this.state
    let { tenant_name, current_deadline, deadlines_processed, processed_total, documents, total_documents } = this.props
    let menuContent = null
    let menuContentProps = {}
    switch (mode) {
      case 'document':
        menuContent = DocumentsForm
        menuContentProps = {
          tenant: tenant_name,
          documents: documents,
          total: total_documents,
          type: 'deadline',
          type_id: current_deadline.id,
          closeForm: this.onToggleForm,
          dispatch: this.props.dispatch,
        }
        break
      case 'processed':
        menuContent = DeadlineProcessed
        menuContentProps = {
          tenant: tenant_name,
          deadline: current_deadline,
          deadlinesProcessed: deadlines_processed,
          processedTotal: processed_total,
          closeForm: this.onToggleForm,
          dispatch: this.props.dispatch,
        }
        break
      case 'edit':
      default:
        menuContent = DeadlineForm
        menuContentProps = {
          deadline: this.props.current_deadline,
          onSave: this.saveDeadline,
          closeForm: this.onToggleForm,
        }
        break
    }

    if (!tenant_name) {
      return (
        <div className="container-fluid flex-fill d-flex flex-column px-0 ps-lg-3">
          <Alert variant="info">Seleziona punto vendita</Alert>
        </div>
      )
    }

    return (
      <div className="container-fluid flex-fill d-flex flex-column px-0 ps-lg-3">
        <div className="d-flex flex-column flex-fill">
          <div className="container-fluid px-0 mb-2">
            <div className="d-flex flex-wrap gap-2">
              <div className="flex-fill flex-sm-grow-0 flex-sm-shrink-0">
                <button
                  className="btn btn-primary btn-block w-100"
                  onClick={this.onToggleForm}
                  disabled={this.props.show_form}>
                  <i className="fas fa-plus fa-fw text-start" />
                  Aggiungi scadenza
                </button>
              </div>
              <div className="flex-fill">
                <SearchButton
                  value={this.state.filter}
                  onChange={this.onFilterChange}
                  disabled={this.props.show_form}
                  className="float-md-end"
                />
              </div>
            </div>
          </div>

          <ResponsiveTable
            ref={this.tableRef}
            className="flex-fill"
            data={this.props.deadlines}
            getData={this.getData}
            totalRecords={this.props.total}
            columns={this.columns}
            showMenu={this.props.show_form}
            menuWidth="40rem"
            menuContent={menuContent}
            menuContentProps={menuContentProps}
            rowClasses={rowData => (rowData.enabled ? '' : `text-muted`)}
            filter={this.state.filter}
          />
        </div>

        <Modal backdrop="static" centered show={this.state.show_modal} onHide={this.onCancel}>
          <Modal.Header>
            <Modal.Title>Elimina scadenza</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Per procedere all'eliminazione della scadenza, cliccare sul bottone "Elimina"</p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.onCancel} variant="secondary">
              Annulla
            </Button>
            <Button onClick={this.onConfirmDelete} variant="danger">
              Elimina
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}
export default connect(mstp)(Deadlines)
