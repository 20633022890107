import React from 'react'
import { Nav, Dropdown } from 'react-bootstrap'
import { connect } from 'react-redux'
import { withRouter } from 'src/lib/router/with_router'

import { BaseHeader, HeaderContent, MobileMenuContent, MobileMenuItem } from 'src/components/system_wide/header'
import ResetPasswordModal from 'src/components/drugstore/users/reset_password_modal'
import { logout, resetLoginData } from 'src/store/login'
import { resetCurrentTenant, resetTenants } from 'src/store/store_manager'
import withCapabilities from 'src/enhancers/with_capabilities'
import { Link } from 'react-router-dom'
import 'src/scss/navbar.css'

const mstp = state => {
  return {
    userInfo: state.userInfo,
    current_tenant: state.storeManager.current_tenant.description,
  }
}

class Header extends React.PureComponent {
  state = {
    show_reset_password: false,
  }

  onTogglePasswordModal = () => {
    this.props.dispatch(resetLoginData())
    this.setState({ show_reset_password: !this.state.show_reset_password })
  }

  handlerLogout = async () => {
    await this.props.dispatch(resetTenants())
    await this.props.dispatch(resetCurrentTenant())
    await this.props.dispatch(logout())
    this.props.router.navigate(`/drugstore/login`, { replace: true })
  }

  render() {
    let { username, is_management_account } = this.props.userInfo
    let { router } = this.props
    let activeKey = router.location.pathname
      .replace('/drugstore', '')
      .replace(/\/\[store_name\].*/, '/point_of_sale_data')

    if (!activeKey) activeKey = '/'

    return (
      <>
        <BaseHeader brandUrl={'/drugstore'} mobileMenuTitle={'ACG s.r.l - Stores management'} headerSize="fluid">
          <HeaderContent>
            {username === '' && (
              <Nav.Link as={Link} to="/drugstore/login" active={activeKey === '/login'} style={{ color: 'white' }}>
                <i className="fas fa-sign-out-alt" />
                Login
              </Nav.Link>
            )}
            {username !== '' && (
              <>
                {is_management_account && <DashboardLink active={activeKey === '/dashboard'} isMobile={false} />}
                <Nav.Link as={Link} to="/drugstore" active={activeKey === '/'} style={{ color: 'white' }}>
                  <i className="fas fa-list" />
                  Punti vendita
                </Nav.Link>
                {is_management_account && <ManageUsersLink active={activeKey === '/users'} isMobile={false} />}
                {is_management_account && (
                  <ManageMaintenanceTypesLink active={activeKey === '/maintenance-types'} isMobile={false} />
                )}
                <UserMenuDropdown
                  user={this.props.userInfo}
                  logout={this.handlerLogout}
                  onShowResetPassword={this.onTogglePasswordModal}
                />
              </>
            )}
          </HeaderContent>

          <MobileMenuContent>
            {username === '' && (
              <MobileMenuItem href="/drugstore/login" active={(activeKey = '/login')}>
                <i className="fas fa-sign-out-alt" />
                Login
              </MobileMenuItem>
            )}
            {username !== '' && (
              <>
                <MobileMenuItem href="/drugstore" active={activeKey === '/'}>
                  <i className="fas fa-list" />
                  Punti vendita
                </MobileMenuItem>
                {is_management_account && <ManageUsersLink active={activeKey === '/users'} isMobile={true} />}
                <MobileMenuItem href="/drugstore" onClick={this.onTogglePasswordModal}>
                  <i className="fas fa-key" />
                  Cambia password
                </MobileMenuItem>
                <div className="mt-4" />
                <MobileMenuItem href="/store/logout" onClick={this.handlerLogout}>
                  <i className="fas fa-sign-out-alt" />
                  Logout
                </MobileMenuItem>
              </>
            )}
          </MobileMenuContent>
        </BaseHeader>
        {this.state.show_reset_password && (
          <ResetPasswordModal
            show={this.state.show_reset_password}
            username={username}
            onCancel={this.onTogglePasswordModal}
          />
        )}
      </>
    )
  }
}

const ManageUsersLink = withCapabilities({ has: ['bo_manage_users'] })(props => {
  return props.isMobile === false ? (
    <Nav.Link as={Link} to="/drugstore/users" active={props.active} style={{ color: 'white' }}>
      <i className="fas fa-users" />
      Utenti
    </Nav.Link>
  ) : (
    <MobileMenuItem href="/drugstore/users" active={props.active}>
      <i className="fas fa-users" />
      Utenti
    </MobileMenuItem>
  )
})

const DashboardLink = withCapabilities({ has: [] })(props => {
  return props.isMobile === false ? (
    <Nav.Link as={Link} to="/drugstore/dashboard" active={props.active} style={{ color: 'white' }}>
      <i className="fas fa-tachometer-alt" />
      Dashboard
    </Nav.Link>
  ) : (
    <MobileMenuItem href="/drugstore/dashboard" active={props.active}>
      <i className="fas fa-tachometer-alt" />
      Dashboard
    </MobileMenuItem>
  )
})

const ManageMaintenanceTypesLink = withCapabilities({ has: [] })(props => {
  return props.isMobile === false ? (
    <Nav.Link as={Link} to="/drugstore/maintenance_types" active={props.active} style={{ color: 'white' }}>
      <i className="fas fa-users" />
      Manutenzione/Notifiche
    </Nav.Link>
  ) : (
    <MobileMenuItem href="/drugstore/maintenance_types" active={props.active}>
      <i className="fas fa-users" />
      Manutenzione/Notifiche
    </MobileMenuItem>
  )
})

const ManageRolesLink = withCapabilities({ has: ['bo_manage_users'] })(props => {
  return props.isMobile === false ? (
    <Nav.Link as={Link} to="/drugstore/roles" active={props.active}>
      <i className="fas fa-user-tag" />
      Ruoli
    </Nav.Link>
  ) : (
    <MobileMenuItem href="/drugstore/roles" active={props.active}>
      <i className="fas fa-user-tag" />
      Ruoli
    </MobileMenuItem>
  )
})

const ManageAccessLevelsLink = withCapabilities({ has: ['bo_manage_users'] })(props => {
  return props.isMobile === false ? (
    <Nav.Link as={Link} to="/drugstore/access_levels" active={props.active}>
      <i className="fas fa-key" />
      Livelli di accesso
    </Nav.Link>
  ) : (
    <MobileMenuItem href="/drugstore/access_levels" active={props.active}>
      <i className="fas fa-key" />
      Livelli di accesso
    </MobileMenuItem>
  )
})

const UserMenuDropdown = props => {
  let { user, logout } = props
  return (
    <>
      <Dropdown id="user-dropdown-home" className="ms-3">
        <Dropdown.Toggle>
          <i className={`fas fa-user text-white`} />
        </Dropdown.Toggle>
        <Dropdown.Menu id="user-dropdown-menu">
          <Dropdown.Item>
            Utente{' '}
            <b>
              {user?.first_name} {user?.last_name}
            </b>
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={props.onShowResetPassword}>
            <i className={`fas fa-key pe-2`} />
            Cambia password
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={logout}>
            <i className={`fas fa-right-from-bracket pe-2`} />
            Esci
          </Dropdown.Item>
          <Dropdown.Divider />
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}

export default connect(mstp)(withRouter(Header))
