import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { t_caps } from 'src/lib/constants'
import { error, success } from 'src/components/system_wide/notification'
import axios from 'src/lib/axios'

const initialState = {
  total: 0,
  acls: [],
  show_form: false,
  current_acl: {
    id: null,
    name: '',
    caps: t_caps,
  },
}

export const getAcls = createAsyncThunk(
  'aclsManager/getAcls',
  async ({ tenant, offset, limit, sortData, filter }, thunkApi) => {
    try {
      let { total: currentTotal } = thunkApi.getState().aclsManager
      if (offset > currentTotal) return

      let { sort_column, sort_direction } = sortData
      let response = await axios({
        url: `/api/acls`,
        params: { offset, limit, sort_column, sort_direction, filter },
      })
      let { total, acls } = response.data
      return { total, acls, reset: offset === 0 }
    } catch (err) {
      error('Impossibile recuperare livelli di accesso')
      return thunkApi.rejectWithValue()
    }
  }
)

export const createAcl = createAsyncThunk('aclsManager/createAcl', async ({ tenant, acl }, thunkApi) => {
  try {
    let { name, caps } = acl
    await axios({ url: `/api/acl`, method: 'post', data: { name, caps } })
    success('Livello di accesso creato con successo')
    thunkApi.dispatch(resetCurrentAcl())
    thunkApi.dispatch(toggleShowForm())
  } catch (err) {
    error('Impossibile creare livello di accesso')
    return thunkApi.rejectWithValue()
  }
})

export const updateAcl = createAsyncThunk('aclsManager/updateAcl', async ({ acl }, thunkApi) => {
  try {
    let { id, name, caps } = acl
    await axios({
      url: `/api/acl/${id}`,
      method: 'patch',
      data: { name, caps },
    })
    success('Livello di accesso aggiornato successo')
    thunkApi.dispatch(resetCurrentAcl())
    thunkApi.dispatch(toggleShowForm())
  } catch (err) {
    error('Impossibile aggiornare livello di accesso')
    return thunkApi.rejectWithValue()
  }
})

export const deleteAcl = createAsyncThunk('aclsManager/deleteAcl', async ({ tenant, id }, thunkApi) => {
  try {
    await axios({ url: `/api/acl/${id}`, method: 'delete' })
    success('Livello di accesso eliminato con successo')
    thunkApi.dispatch(resetCurrentAcl())
  } catch (err) {
    error('Impossibile eliminare livello di accesso')
    return thunkApi.rejectWithValue()
  }
})

export const aclsManagerSlice = createSlice({
  name: 'aclsManager',
  initialState,

  reducers: {
    setCurrentAcl: (state, action) => {
      let { id, name, caps } = action.payload
      state.current_acl.id = id
      state.current_acl.name = name
      state.current_acl.caps = caps
    },
    toggleShowForm: state => {
      state.show_form = !state.show_form
    },
    resetCurrentAcl: state => {
      state.current_acl = initialState.current_acl
    },
    resetAclManager: () => initialState,
  },

  extraReducers: {
    [getAcls.fulfilled]: (state, action) => {
      if (!action?.payload) return
      let { total, acls } = action.payload
      state.total = total
      state.acls = action.payload.reset ? [...acls] : [...state.acls, ...acls]
    },
    [getAcls.rejected]: state => {
      state.total = initialState.total
      state.acls = initialState.acls
    },
  },
})

export const { setCurrentAcl, resetCurrentAcl, toggleShowForm, resetAclManager } = aclsManagerSlice.actions
export default aclsManagerSlice.reducer
