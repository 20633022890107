import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { error, success } from 'src/components/system_wide/notification'
import axios from 'src/lib/axios'

const initialState = {
  total: 0,
  deadlines: [],
  show_form: false,
  processed_deadlines: [],
  processed_total: 0,
  current_deadline: {
    id: null,
    description: '',
    recipients: '',
    expiry_date: '',
    recurring: false,
    recurring_type: '',
  },
}

export const getDeadlines = createAsyncThunk(
  'deadlines/getDeadlines',
  async ({ tenant, offset, limit, sortData, filter }, thunkApi) => {
    try {
      let { total: currentTotal } = thunkApi.getState().deadlines
      if (offset > currentTotal) return

      let { sort_column, sort_direction } = sortData ?? { sort_column: 'id', sort_direction: 'DESC' }
      let response = await axios({
        url: `/api/deadlines/${tenant}`,
        params: { offset, limit, sort_column, sort_direction, filter },
      })
      let { total, deadlines } = response.data
      return { total, deadlines, reset: offset === 0 }
    } catch (err) {
      error('Impossibile recuperare le scadenze')
      return thunkApi.rejectWithValue()
    }
  }
)

export const getDeadlinesProcessed = createAsyncThunk(
  'deadlines/getDeadlinesProcessed',
  async ({ tenant, id, offset, limit, sort_column, sort_direction, filter }, thunkApi) => {
    try {
      let response = await axios({
        url: `/api/deadlines/${tenant}/processed/${id}`,
        params: { offset, limit, sort_column, sort_direction, filter },
      })
      let { total, deadlines_processed } = response.data
      return { total, deadlines_processed, reset: offset === 0 }
    } catch (err) {
      error('Impossibile recuperare le scadenze processate')
      return thunkApi.rejectWithValue()
    }
  }
)

export const createDeadline = createAsyncThunk('deadlines/createDeadline', async ({ tenant, deadline }, thunkApi) => {
  try {
    let { description, recipients, expiry_date, recurring, recurring_type } = deadline
    await axios({
      url: `/api/deadline/${tenant}`,
      method: 'post',
      data: { description, recipients, expiry_date, recurring, recurring_type },
    })
    success('Scadenza creata con successo')
    thunkApi.dispatch(resetCurrentDeadline())
    thunkApi.dispatch(toggleShowForm())
  } catch (err) {
    error('Impossibile creare nuova scadenza')
    return thunkApi.rejectWithValue()
  }
})

export const updateDeadline = createAsyncThunk('deadlines/updateDeadline', async ({ tenant, deadline }, thunkApi) => {
  try {
    let { id, description, recipients, expiry_date, recurring, recurring_type } = deadline
    await axios({
      url: `/api/deadline/${tenant}/${id}`,
      method: 'patch',
      data: { description, recipients, expiry_date, recurring, recurring_type },
    })
    success('Scadenza aggiornata con successo')
    thunkApi.dispatch(resetCurrentDeadline())
    thunkApi.dispatch(toggleShowForm())
  } catch (err) {
    error('Impossibile aggiornare scadenza')
    return thunkApi.rejectWithValue()
  }
})

export const deleteDeadline = createAsyncThunk('deadlines/deleteDeadline', async ({ tenant, id }, thunkApi) => {
  try {
    await axios({ url: `/api/deadline/${tenant}/${id}`, method: 'delete' })
    success('Scadenza eliminata con successo')
    thunkApi.dispatch(resetCurrentDeadline())
  } catch (err) {
    error('Impossibile eliminare scadenza')
    return thunkApi.rejectWithValue()
  }
})

export const deadlinesSlice = createSlice({
  name: 'deadlines',
  initialState,

  reducers: {
    setCurrentDeadline: (state, action) => {
      let { id, description, recipients, expiry_date, recurring, recurring_type } = action.payload
      state.current_deadline.id = id
      state.current_deadline.description = description
      state.current_deadline.recipients = recipients
      state.current_deadline.expiry_date = expiry_date
      state.current_deadline.recurring = recurring
      state.current_deadline.recurring_type = recurring_type
    },
    toggleShowForm: state => {
      state.show_form = !state.show_form
    },
    resetCurrentDeadline: state => {
      state.current_deadline = initialState.current_deadline
      state.deadlines_processed = []
      state.processed_total = 0
    },
    resetDeadlines: () => initialState,
  },

  extraReducers: {
    [getDeadlines.fulfilled]: (state, action) => {
      if (!action?.payload) return
      let { total, deadlines } = action.payload
      state.total = total
      state.deadlines = action.payload.reset ? [...deadlines] : [...state.deadlines, ...deadlines]
    },
    [getDeadlines.rejected]: state => {
      state.total = initialState.total
      state.deadlines = initialState.deadlines
    },
    [getDeadlinesProcessed.fulfilled]: (state, action) => {
      let { total, deadlines_processed } = action.payload
      state.processed_total = total
      state.deadlines_processed = action.payload.reset
        ? [...deadlines_processed]
        : [...state.deadlines, ...deadlines_processed]
    },
  },
})

export const { setCurrentDeadline, resetCurrentDeadline, toggleShowForm, resetDeadlines } = deadlinesSlice.actions
export default deadlinesSlice.reducer
