import React from 'react'
import { connect } from 'react-redux'

const mstp = state => state.loading

class AppLoading extends React.PureComponent {
  state = { ready: true }

  render() {
    let { loading } = this.props
    if (loading === false) return null
    return (
      <>
        <style jsx="true">{`
          .overlay {
            height: 100%;
            width: 100%;
            position: fixed;
            z-index: 9999;

            background-color: rgb(0, 0, 0);
            background-color: rgba(0, 0, 0, 0.1);
            overflow-x: hidden;
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            transition: all 0.3s ease;
          }
        `}</style>
        <div className="d-flex flex-fill align-items-center overlay">
          <div className="flex-fill text-muted text-black text-center fs-4">Loading...</div>
        </div>
      </>
    )
  }
}

export default connect(mstp)(AppLoading)
