import React from 'react'
import { Button, Form, Col } from 'react-bootstrap'
import { format, parseISO, isValid } from 'date-fns'
import NumericInput from 'react-numeric-input'
import hash from 'object-hash'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

class SaleForm extends React.Component {
  state = {
    id: null,
    description: '',
    sales: 0,
    period: null,

    sale_hash: '',
  }

  initialHash = ''

  constructor(props) {
    super(props)

    if (props.sale.id) {
      let { id, description, sales, period } = props.sale
      this.state.id = id
      this.state.description = description
      this.state.sales = sales
      this.state.period = period
    }

    // eslint-disable-next-line no-unused-vars
    let { sale_hash, ...rest } = this.state
    this.initialHash = hash(rest)
    this.state.sale_hash = this.initialHash
  }

  updateHash = () => {
    // eslint-disable-next-line no-unused-vars
    let { sale_hash, ...rest } = this.state
    this.setState({ sale_hash: hash(rest) })
  }

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value }, () => this.updateHash())
  }

  onChangeNumericInput = (value, rawValue, { name }) => {
    this.setState({ [name]: value }, () => this.updateHash())
  }

  handleDateChange = name => date => {
    this.setState({ [name]: date !== null && isValid(date) ? format(date, 'yyyy-MM-dd') : null }, () =>
      this.updateHash()
    )
  }

  handleConfirm = () => {
    // eslint-disable-next-line no-unused-vars
    let { sale_hash, ...rest } = this.state
    this.props.onSave(rest)
  }

  isFormValid = () => {
    let { description } = this.state
    return description !== ''
  }

  render() {
    let title = this.state.id ? 'Modifica fatturato' : 'Crea fatturato'
    let { description, sales, period } = this.state
    let parsedPeriod = period ? parseISO(period) : new Date()

    return (
      <>
        <h4 className="border-bottom text-primary">{title}</h4>
        <div className="flex-fill position-relative overflow-auto">
          <div className="w-100 ps-1 pe-4 position-absolute">
            <Form.Group className="mt-3 mb-3">
              <Form.Label>Descrizione</Form.Label>
              <span> *</span>
              <Form.Control
                name="description"
                as="textarea"
                rows={3}
                value={description}
                onChange={this.handleChange}
              />
            </Form.Group>
            <Form.Group as={Col} className="mb-3 col-12 col-md-6">
              <Form.Label>Fatturato</Form.Label>
              <div style={{ width: '10rem' }}>
                <NumericInput
                  className="form-control form-control-sm"
                  precision={2}
                  min={0}
                  step={0.5}
                  snap
                  inputMode="decimal"
                  strict
                  name="sales"
                  value={sales}
                  onChange={this.onChangeNumericInput}
                />
              </div>
            </Form.Group>

            <Form.Group as={Col} className="mb-3 mb-md-0 col-12 col-md-4">
              <Form.Label>Periodo</Form.Label>
              <div className="form-control p-0">
                <DatePicker
                  style={{ lineHeight: 1.7 }}
                  className="form-control"
                  selected={parsedPeriod}
                  placeholderText="Periodo"
                  dateFormat="dd-MM-yyyy"
                  locale={this.locale}
                  onChange={this.handleDateChange('period')}
                  isClearable
                  showYearDropdown
                  yearDropdownItemNumber={90}
                  scrollableYearDropdown
                />
              </div>
            </Form.Group>
          </div>
        </div>
        <div className="border-top pt-2">
          <Button
            className="float-end"
            variant="primary"
            disabled={!this.isFormValid() || this.initialHash === this.state.sale_hash}
            onClick={this.handleConfirm}>
            Conferma
          </Button>
          <Button className="float-end me-2" variant="secondary" onClick={this.props.closeForm}>
            Annulla
          </Button>
        </div>
      </>
    )
  }
}
export default SaleForm
