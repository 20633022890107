import React from 'react'
import { Helmet } from 'react-helmet-async'
import withJWTSession from 'src/enhancers/jwt_session'
import MaintenanceTypes from 'src/components/drugstore/maintenance_types'

class MaintenanceTypesPage extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Notifiche/Manutenzione</title>
          <meta name="description" content="NL Admin Panel" />
          <link rel="icon" href="/favicon.ico" />
        </Helmet>
        <MaintenanceTypes />
      </>
    )
  }
}
export default withJWTSession({ invalid: '/drugstore/login' })(MaintenanceTypesPage)
