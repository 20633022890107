import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { error, success } from 'src/components/system_wide/notification'
import axios from 'src/lib/axios'

const initialState = {
  total: 0,
  sales: [],
  show_form: false,
  current_sale: {
    id: null,
    description: '',
    sales: 0,
    period: null,
  },
}

export const getSales = createAsyncThunk(
  'sales/getSales',
  async ({ tenant, offset, limit, sortData, filter }, thunkApi) => {
    try {
      let { total: currentTotal } = thunkApi.getState().sales
      if (offset > currentTotal) return

      let { sort_column, sort_direction } = sortData ?? { sort_column: 'id', sort_direction: 'DESC' }
      let response = await axios({
        url: `/api/sales/${tenant}`,
        params: { offset, limit, sort_column, sort_direction, filter },
      })
      let { total, sales } = response.data
      return { total, sales, reset: offset === 0 }
    } catch (err) {
      error('Impossibile recuperare fatturato')
      return thunkApi.rejectWithValue()
    }
  }
)

export const createSale = createAsyncThunk('sales/createSale', async ({ tenant, sale }, thunkApi) => {
  try {
    let { description, sales, period } = sale
    await axios({
      url: `/api/sale/${tenant}`,
      method: 'post',
      data: { description, sales, period },
    })
    success('Fatturato creato con successo')
    thunkApi.dispatch(resetCurrentSale())
    thunkApi.dispatch(toggleShowForm())
  } catch (err) {
    console.log(err)
    error('Impossibile creare fatturato')
    return thunkApi.rejectWithValue()
  }
})

export const updateSale = createAsyncThunk('sales/updateSale', async ({ tenant, sale }, thunkApi) => {
  try {
    let { id, description, sales, period } = sale
    await axios({
      url: `/api/sale/${tenant}/${id}`,
      method: 'patch',
      data: { description, sales, period },
    })
    success('Fatturato aggiornato con successo')
    thunkApi.dispatch(resetCurrentSale())
    thunkApi.dispatch(toggleShowForm())
  } catch (err) {
    console.log(err)
    error('Impossibile aggiornare fatturato')
    return thunkApi.rejectWithValue()
  }
})

export const deleteSale = createAsyncThunk('sales/deleteSale', async ({ tenant, id }, thunkApi) => {
  try {
    await axios({ url: `/api/sale/${tenant}/${id}`, method: 'delete' })
    success('Fatturato eliminato con successo')
    thunkApi.dispatch(resetCurrentSale())
  } catch (err) {
    error('Impossible eliminare fatturato')
    return thunkApi.rejectWithValue()
  }
})

export const salesSlice = createSlice({
  name: 'sales',
  initialState,

  reducers: {
    setCurrentSale: (state, action) => {
      let { id, description, sales, period } = action.payload
      state.current_sale.id = id
      state.current_sale.description = description
      state.current_sale.sales = sales
      state.current_sale.period = period
    },
    toggleShowForm: state => {
      state.show_form = !state.show_form
    },
    resetCurrentSale: state => {
      state.current_sale = initialState.current_sale
    },
    resetSales: () => initialState,
  },

  extraReducers: {
    [getSales.fulfilled]: (state, action) => {
      if (!action?.payload) return
      let { total, sales } = action.payload
      state.total = total
      state.sales = action.payload.reset ? [...sales] : [...state.sales, ...sales]
    },
    [getSales.rejected]: state => {
      state.total = initialState.total
      state.sales = initialState.sales
    },
  },
})

export const { setCurrentSale, resetCurrentSale, toggleShowForm, resetSales } = salesSlice.actions
export default salesSlice.reducer
