import React from 'react'
import memoizeOne from 'memoize-one'
import { Virtuoso } from 'react-virtuoso'

import {
  TableHeader,
  TableFooter,
  DataRow,
  TableMenu,
  NoRecords,
} from 'src/components/system_wide/responsive_table/components'
import styles from 'src/components/system_wide/responsive_table/responsive_table.module.css'

class ResponsiveTable extends React.Component {
  static defaultProps = {
    style: {},
    className: '',
    data: [],
    getData: () => {},
    chunkSize: 50,
    showMenu: false,
    menuWidth: '100%',
    menuContent: () => null,
    menuContentProps: {},
    columns: [],
    filter: null,
    rowClasses: '',
    hideStatusBar: false,
  }

  state = {
    useWindowScroll: false,
    completed: false,
    refreshing: false,
    lastChunkSize: 0,
    headerPadding: 0,
    sort_column: '',
    sort_direction: '',
  }

  mediaQueryList = null
  resizeObserver = null
  virtuosoRef = null

  constructor(props) {
    super(props)
    this.virtuosoRef = React.createRef()
  }

  onMediaQueryMatchChange = query => {
    this.setState({ useWindowScroll: query.matches })
    this.resizeObserver.disconnect()
    this.resizeObserver.observe(document.querySelector(`.${styles.scroll_area} > div > div`))
  }

  onResizeScrollArea = () => {
    let tableWidth = document.querySelector(`.${styles.responsive_table}`)?.clientWidth
    let rowWidth = document.querySelector(`.${styles.row}:last-child`)?.clientWidth || tableWidth
    if (tableWidth - rowWidth !== this.state.headerPadding) {
      this.setState({ headerPadding: tableWidth - rowWidth })
    }
  }

  async componentDidMount() {
    this.mediaQueryList = window.matchMedia('screen and (max-width: 992px), screen and (max-height: 700px)')
    this.mediaQueryList.addListener(this.onMediaQueryMatchChange)
    this.setState({ useWindowScroll: this.mediaQueryList.matches })

    this.resizeObserver = new ResizeObserver(this.onResizeScrollArea)
    this.resizeObserver.observe(document.querySelector(`.${styles.scroll_area} > div > div`))
    await this.loadMore()
  }

  componentWillUnmount() {
    this.mediaQueryList.removeListener(this.onMediaQueryMatchChange)
    this.resizeObserver.disconnect()
  }

  async componentDidUpdate(prevProps) {
    let { length: oldLength } = prevProps.data
    let { length: newLength } = this.props.data
    if (newLength !== oldLength) {
      this.setState({ lastChunkSize: newLength - oldLength })
    }
    if (prevProps.filter !== this.props.filter) {
      let { sort_column, sort_direction } = this.state
      this.setState({ refreshing: true })
      await this.props.getData(0, this.props.chunkSize, { sort_column, sort_direction }, this.props.filter)
      this.virtuosoRef.current.scrollToIndex(0)
      this.setState({ refreshing: false })
    }
  }

  loadMore = memoizeOne(async () => {
    let { data = [], chunkSize } = this.props
    let start = data.length
    let { sort_column, sort_direction } = this.state

    if (start !== 0 && start >= this.props.totalRecords) {
      this.setState({ completed: true })
      return
    }

    if (this.state.lastChunkSize === 0 || this.state.lastChunkSize === chunkSize) {
      this.setState({ completed: false })
    }
    await this.props.getData(start, chunkSize, { sort_column, sort_direction }, this.props.filter)
    if (this.state.completed === false) this.setState({ completed: true })
  })

  refreshData = async () => {
    let { sort_column, sort_direction } = this.state
    let { data = [], chunkSize } = this.props
    let limit = Math.ceil(data.length / chunkSize) * chunkSize
    this.setState({ refreshing: true })
    await this.props.getData(0, limit || chunkSize, { sort_column, sort_direction }, this.props.filter)
    this.setState({ refreshing: false })
  }

  onSortChange = async sortKey => {
    let { sort_column, sort_direction } = this.state
    let new_sorting_configs = {}
    if (sortKey !== sort_column) {
      new_sorting_configs = { sort_column: sortKey, sort_direction: 'ASC' }
    } else {
      if (sort_direction === 'ASC') {
        new_sorting_configs = { sort_direction: 'DESC' }
      } else {
        new_sorting_configs = { sort_column: '', sort_direction: '' }
      }
    }
    this.setState(new_sorting_configs, async () => {
      await this.refreshData()
    })
  }

  render() {
    const { completed, useWindowScroll, headerPadding, refreshing, sort_column, sort_direction } = this.state
    const { data, totalRecords, style, className, columns, rowClasses, hideStatusBar } = this.props
    const { menuWidth, showMenu, menuContent, menuContentProps } = this.props

    const showTotals = typeof totalRecords !== 'undefined'

    return (
      <div
        style={{ ...style }}
        className={`${className} d-flex flex-column with-shadow ${styles.responsive_table} ${
          hideStatusBar ? styles.no_status : ''
        }`}>
        <TableHeader {...{ columns, headerPadding, sort_column, sort_direction, onSortChange: this.onSortChange }} />
        <Virtuoso
          ref={this.virtuosoRef}
          className={`${styles.scroll_area} flex-fill with-border overflow-auto`}
          style={{ minHeight: '20rem' }}
          data={data}
          endReached={this.loadMore}
          useWindowScroll={useWindowScroll}
          itemContent={DataRow(columns, rowClasses)}
          components={{
            Footer: () => {
              return completed ? null : (
                <div className={styles.last_row} style={{ position: data.length ? 'relative' : 'absolute' }}>
                  Loading...
                </div>
              )
            },
          }}
        />
        {hideStatusBar !== true && (
          <TableFooter
            showTotals={showTotals}
            total={totalRecords || 0}
            onRefresh={this.refreshData}
            isRefreshing={refreshing}
          />
        )}
        <NoRecords in={!refreshing && data.length === 0} />
        <TableMenu useModal={useWindowScroll} {...{ showMenu, menuWidth, menuContent, menuContentProps }} />
      </div>
    )
  }
}

export default ResponsiveTable
