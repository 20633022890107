import React from 'react'
import withJWTSession from 'src/enhancers/jwt_session'

import PointOfSaleData from 'src/components/drugstore/tenant_management/point_of_sale_data'

class PointOfSaleDataPage extends React.Component {
  render() {
    return <PointOfSaleData />
  }
}
export default withJWTSession({ invalid: '/drugstore/login' })(PointOfSaleDataPage)
