import React from 'react'
import { CSSTransition } from 'react-transition-group'

const Dropdown = ({ in: inProp, duration = 300 }) => {
  const nodeRef = React.useRef(null)

  return (
    <>
      <style jsx="true">
        {`
          .content-overlay {
            position: absolute;
            background: #000000;
            z-index: 900;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            border-top-left-radius: var(--bs-border-radius);
            border-top-right-radius: var(--bs-border-radius);
            opacity: 0.5;
          }
          :local (.dropdown-enter) {
            opacity: 0;
          }
          :local (.dropdown-enter-active) {
            opacity: 0.5;
            transition: opacity ${duration}ms ease-in-out;
          }
          :local (.dropdown-exit-active) {
            opacity: 0;
            transition: opacity ${duration}ms ease-in-out;
          }
        `}
      </style>
      <CSSTransition nodeRef={nodeRef} in={inProp} timeout={duration} classNames="dropdown" unmountOnExit>
        <div ref={nodeRef} className="content-overlay"></div>
      </CSSTransition>
    </>
  )
}

export default Dropdown
